/* ==========================================================
  nav-global
========================================================== */
.nav-global {
  position: fixed;
  display: block;
  width: 100%;
  height: 0;
  top: 60px;
  left: 0;
  cursor: default;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  backface-visibility: hidden;
  z-index: 3;
  overflow-y: scroll;

  &.is-open {
    opacity: 1;
    height: auto;
    visibility: visible;
    transition: all 0.5s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &-list {
    width: 100%;
    overflow: hidden;

  }

  &-menu {
    &.showsub {
      .nav-global-link {
        color: $white;
        background-color: $lightBlue;

        &::before {
          border-width: 5px 5px 0 5px;
          border-color: $white transparent transparent transparent;
        }
      }
    }
  }

  &-sub-menu {
    display: none;

    .nav-global-link {
      font-size: 1.6rem;
      padding: 10px 20px 12px 55px;

      &::before {
        left: 35px;
      }
    }
  }

  &-link {
    width: 100%;
    display: block;
    position: relative;
    font-size: 1.8rem;
    color: #d2d9e1;
    background: $mainBlue;
    border-top: 1px solid $lightBlue;
    padding: 18px 20px 15px 38px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #d2d9e1;
      transform: translateY(-50%);
    }

    &-sub {
      @extend .nav-global-link;
    }
  }

  &-bg {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: #000;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: "alpha(opacity=0)";
    -moz-opacity: 0;
    -khtml-opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    visibility: hidden;
    overflow: hidden;

    &.show {
      opacity: 0.5;
      filter: alpha(opacity=50);
      -ms-filter: "alpha(opacity=50)";
      -moz-opacity: 0.5;
      -khtml-opacity: 0.5;
      visibility: visible;
      overflow: visible;
    }
  }
  &-icon {
    display: flex;
    background-color: #F6F3E2;
    padding: 15px 10px;
	text-align: center;  
    &-item {
      width: 50%;
      display: block;
      padding: 0 7px;
    }
    &-link {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      background-color: #E2DCC0;
      .icon {
        font-size: 5rem;
        color: #877166;
      }
		p {
		color: $seven-color;
		padding-left: 1em;
		}
    }
  }
}
