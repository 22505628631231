@charset 'UTF-8';

// font color
// ----------
@each $name, $color in $color-pattern {
  .fc-#{$name} {
    color: $color;
  }
}

// font size
// ---------
@each $name, $size in $font-size {
  .#{$name} {
    font-size: $size !important;
  }
}
