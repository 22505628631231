@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ==========================================================
  fonts
========================================================== */
/* ==========================================================
  html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
}

/* ==========================================================
  reseting
========================================================== */
html {
  height: 100%;
}

@media (max-width: 812px) {
  html {
    font-size: 62.5%;
  }
}

@media (min-width: 813px) {
  html {
    font-size: 78.125%;
  }
}

html.is-fixed {
  position: fixed;
  left: 0;
  width: 100%;
}

body {
  color: #555;
  font-family: "YakuHanJPs", -apple-system BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, arial, sans-serif;
  font-size: 1.3rem;
  line-height: 1.5;
  text-size-adjust: 100%;
}

@media (max-width: 812px) {
  body {
    font-size: 1.4rem;
  }
}

input:not([type='checkbox']):not([type='radio']), textarea, select, button {
  font-family: "YakuHanJPs", -apple-system BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, arial, sans-serif;
  outline: none;
  appearance: none;
}

body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

textarea {
  resize: vertical;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  appearance: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: 500;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: 700;
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

/* ==========================================================
  base Link
========================================================== */
a, .is-opacity {
  color: #555;
  text-decoration: underline;
}

@media (min-width: 813px) {
  a, .is-opacity {
    transition: opacity 0.3s;
  }
  a:hover, .is-opacity:hover {
    opacity: 0.7;
    text-decoration: none;
  }
}

/* ==========================================================
  blackground
========================================================== */
/* bg-color
---------------------------------------------------------- */
.c-bg-brown-01 {
  background-color: #F3EBE7;
}

.c-bg-brown-02 {
  background-color: #CEB0A3;
}

.c-bg-brown-03 {
  background-color: #EDDAD0;
}

/* ==========================================================
  card
========================================================== */
/* c-box-cmn
---------------------------------------------------------- */
.c-box-cmn {
  padding: 2em;
  background-color: #fff;
  border-radius: 12px;
}

/* ==========================================================
  breadcrumb
========================================================== */
.breadcrumb {
  max-width: 1240px;
  padding: 20px 20px 0;
  margin: 0 auto 0;
  background: #f6f3e2;
}

@media (max-width: 812px) {
  .breadcrumb {
    padding: 20px 20px 0;
    margin: 0 auto;
  }
}

.breadcrumb a {
  text-decoration: none;
}

.breadcrumb > ul {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb > ul > li {
  position: relative;
  font-size: 1.1rem;
  padding-right: 20px;
}

.breadcrumb > ul > li:before {
  content: '>';
  position: absolute;
  right: 5px;
}

.breadcrumb > ul > li:last-child:before {
  content: none;
}

/* ==========================================================
  burron
========================================================== */
/* c-btn-pagetop
---------------------------------------------------------- */
.c-btn-pagetop {
  display: none;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 50%;
  bottom: 50px;
  margin-right: -600px;
  z-index: 99;
}

@media (max-width: 812px) {
  .c-btn-pagetop {
    width: 32px;
    height: 32px;
  }
}

.c-btn-pagetop.stop {
  top: -90px;
  right: 50%;
  margin-right: -600px;
  position: absolute;
}

@media (max-width: 812px) {
  .c-btn-pagetop {
    right: 15px;
    margin-right: 0;
  }
  .c-btn-pagetop.stop {
    top: -90px;
    right: 15px;
    margin-right: 0;
    position: absolute;
  }
}

@media (max-width: 812px) and (max-width: 812px) {
  .c-btn-pagetop.stop {
    top: -50px;
  }
}

.c-btn-pagetop .link {
  display: inline-flex;
  text-decoration: none;
}

.c-btn-pagetop .fas {
  font-size: 6rem;
  color: #214268;
}

@media (max-width: 812px) {
  .c-btn-pagetop .fas {
    font-size: 3.2rem;
  }
}

/* ==========================================================
  card
========================================================== */
/* c-card-01
---------------------------------------------------------- */
.c-card-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  height: 160px;
  transition: background-color 0.3s, color 0.3s;
}

.c-card-01:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 30px;
  /*線の上下位置*/
  display: inline-block;
  width: 1px;
  /*線の長さ*/
  height: 100px;
  /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /*位置調整*/
  background-color: #dee2e6;
  /*線の色*/
  border-radius: 1px;
  /*線の丸み*/
}

@media (max-width: 812px) {
  .c-card-01 {
    height: 167px;
    border-radius: 12px;
  }
  .c-card-01:before {
    width: 0;
    height: 0;
  }
}

.c-card-01.white {
  background-color: #fff;
}

.c-card-01 .card-in {
  text-align: center;
}

@media (max-width: 812px) {
  .c-card-01 .card-in {
    margin-top: 8px;
  }
}

.c-card-01 .card-in .icon {
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 3rem;
  color: #877166;
}

.c-card-01 .card-in .text-01 {
  width: 100%;
  font-size: 1rem;
  color: #877166;
  margin-bottom: 1.4em;
}

@media (max-width: 812px) {
  .c-card-01 .card-in .text-01 {
    font-size: 1rem;
  }
}

.c-card-01 .card-in .text-02 {
  font-size: 1.4rem;
}

@media (max-width: 812px) {
  .c-card-01 .card-in .text-02 {
    font-size: 1.6rem;
  }
}

@media (max-width: 812px) {
  .c-card-01.small {
    height: 110px;
  }
}

@media (max-width: 812px) {
  .c-card-01.small .card-in .icon {
    font-size: 3rem;
  }
}

/*TOP コンテンツリンク 外来のご案内 左側の線*/
.c-list-cmn-01 li:first-child .c-card-01:after {
  content: '';
  position: absolute;
  left: 0;
  top: 30px;
  /*線の上下位置*/
  display: inline-block;
  width: 1px;
  /*線の長さ*/
  height: 100px;
  /*線の太さ*/
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  /*位置調整*/
  background-color: #dee2e6;
  /*線の色*/
  border-radius: 1px;
  /*線の丸み*/
}

@media (max-width: 812px) {
  .c-list-cmn-01 li:first-child .c-card-01:after {
    width: 0;
    height: 0;
  }
}

a:hover .c-card-01 {
  background-color: #2C598C;
}

a:hover .c-card-01 .card-in .icon,
a:hover .c-card-01 .card-in .text-01,
a:hover .c-card-01 .card-in .text-02 {
  color: #fff;
}

/* c-card-02
---------------------------------------------------------- */
.c-card-02 {
  background-color: #E2DCC0;
  border-radius: 12px;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  transition: background-color 0.3s, color 0.3s;
}

@media (max-width: 812px) {
  .c-card-02 {
    justify-content: center;
    height: 100px;
  }
}

.c-card-02 .icon {
  font-size: 5rem;
  color: #877166;
  margin-left: 29px;
}

@media (max-width: 812px) {
  .c-card-02 .icon {
    font-size: 4rem;
    margin-left: 0;
  }
}

.c-card-02 .card-in {
  text-align: left;
  margin-left: 30px;
}

@media (max-width: 812px) {
  .c-card-02 .card-in {
    text-align: center;
    margin-left: 0;
    width: 100%;
  }
}

.c-card-02 .card-in .text-01 {
  width: 100%;
  font-size: 1.2rem;
  color: #877166;
}

@media (max-width: 812px) {
  .c-card-02 .card-in .text-01 {
    font-size: 1rem;
  }
}

.c-card-02 .card-in .text-02 {
  font-size: 2.2rem;
}

@media (max-width: 812px) {
  .c-card-02 .card-in .text-02 {
    font-size: 1.2rem;
  }
}

.wrap-card-link:hover {
  opacity: 1;
}

.wrap-card-link:hover .c-card-02 {
  background-color: #2C598C;
}

.wrap-card-link:hover .c-card-02 .icon {
  color: #fff;
}

.wrap-card-link:hover .c-card-02 .card-in .text-01 {
  color: #fff;
}

.wrap-card-link:hover .c-card-02 .card-in .text-02 {
  color: #fff;
}

.card-cmn-content {
  display: flex;
  width: 100%;
  justify-content: start;
}

@media (max-width: 812px) {
  .card-cmn-content {
    display: block;
  }
}

.card-cmn-content .title {
  width: 42%;
}

@media (max-width: 812px) {
  .card-cmn-content .title {
    width: 100%;
  }
}

.card-cmn-content .title .ttl {
  margin: 11px 0 0 19px;
  font-size: 2.4rem;
  font-weight: normal;
}

@media (max-width: 812px) {
  .card-cmn-content .title .ttl {
    margin: 0;
    margin-bottom: 10px;
    font-size: 2.8rem;
  }
}

.card-cmn-content .title .ttl .text-color-01 {
  color: #8C563A;
  font-size: 2rem;
  margin-right: 10px;
}

.card-cmn-content .title .dr-wrap {
  display: flex;
  align-items: flex-end;
  font-size: 1.5em;
}

.card-cmn-content .title .dr-wrap .dr-position {
  margin-right: 1em;
  color: #6c757d;
  font-size: .8em;
}

.card-cmn-content .title .dr-wrap .dr-name .dr-kana {
  display: block;
  font-size: 0.625em;
}

@media (max-width: 812px) {
  .card-cmn-content .title .dr-wrap {
    font-size: 1.5em;
  }
}

.card-cmn-content .detail {
  width: 58%;
  font-size: 1.3rem;
  overflow: hidden;
  margin-top: 1.5em;
  line-height: 1.4;
}

@media (max-width: 812px) {
  .card-cmn-content .detail {
    width: 100%;
    margin-top: 2em;
  }
}

.card-cmn-content .detail dt {
  width: 180px;
  float: left;
  clear: left;
  font-weight: normal;
  margin-top: 0;
}

@media (max-width: 812px) {
  .card-cmn-content .detail dt {
    width: 100%;
    float: none;
    font-size: 1.4rem;
  }
}

.card-cmn-content .detail dd {
  margin-bottom: 30px;
  float: left;
  width: calc(100% - 180px);
}

@media (max-width: 812px) {
  .card-cmn-content .detail dd {
    padding-left: 20px;
    float: none;
    font-size: 1.4rem;
    width: 100%;
  }
}

/* c-card-news
---------------------------------------------------------- */
.c-card-news {
  position: relative;
  display: block;
  padding: 19px 45px 19px 40px;
  text-decoration: none;
}

@media (max-width: 812px) {
  .c-card-news {
    padding: 10px 40px 9px 25px;
  }
}

.c-card-news:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: '\f054';
  font-size: 1.9rem;
  color: #214268;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 812px) {
  .c-card-news:before {
    font-size: 1.1rem;
    right: 17px;
  }
}

.c-card-news.unlink {
  pointer-events: none;
}

.c-card-news.unlink:before {
  content: none;
}

.c-card-news .box-date {
  display: flex;
  align-items: center;
}

.c-card-news .date {
  color: #214268;
}

.c-card-news .title {
  margin-top: 7px;
  letter-spacing: -0.05rem;
}

@media (max-width: 812px) {
  .c-card-news .title {
    margin-top: 0;
    letter-spacing: 0;
  }
}

.c-card-news .title i {
  font-size: 2.4rem;
  margin-left: 8px;
}

@media (max-width: 812px) {
  .c-card-news .title i {
    font-size: 1.4rem;
  }
}

.c-card-news .title .fa-file-pdf {
  color: #C62B2B;
  font-weight: normal;
}

.c-card-news * + .c-tag-01 {
  margin-left: 12px;
}

@media (max-width: 812px) {
  .c-card-news * + .c-tag-01 {
    margin-left: 5px;
  }
}

/* ==========================================================
  contents
========================================================== */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (max-width: 812px) {
  .flex-sp-row {
    flex-direction: row !important;
  }
  .flex-sp-column {
    flex-direction: column !important;
  }
  .flex-sp-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sp-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sp-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sp-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sp-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sp-fill {
    flex: 1 1 auto !important;
  }
  .flex-sp-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sp-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sp-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sp-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sp-start {
    justify-content: flex-start !important;
  }
  .justify-content-sp-end {
    justify-content: flex-end !important;
  }
  .justify-content-sp-center {
    justify-content: center !important;
  }
  .justify-content-sp-between {
    justify-content: space-between !important;
  }
  .justify-content-sp-around {
    justify-content: space-around !important;
  }
  .align-items-sp-start {
    align-items: flex-start !important;
  }
  .align-items-sp-end {
    align-items: flex-end !important;
  }
  .align-items-sp-center {
    align-items: center !important;
  }
  .align-items-sp-baseline {
    align-items: baseline !important;
  }
  .align-items-sp-stretch {
    align-items: stretch !important;
  }
  .align-content-sp-start {
    align-content: flex-start !important;
  }
  .align-content-sp-end {
    align-content: flex-end !important;
  }
  .align-content-sp-center {
    align-content: center !important;
  }
  .align-content-sp-between {
    align-content: space-between !important;
  }
  .align-content-sp-around {
    align-content: space-around !important;
  }
  .align-content-sp-stretch {
    align-content: stretch !important;
  }
  .align-self-sp-auto {
    align-self: auto !important;
  }
  .align-self-sp-start {
    align-self: flex-start !important;
  }
  .align-self-sp-end {
    align-self: flex-end !important;
  }
  .align-self-sp-center {
    align-self: center !important;
  }
  .align-self-sp-baseline {
    align-self: baseline !important;
  }
  .align-self-sp-stretch {
    align-self: stretch !important;
  }
  .flex-grow-sp-auto {
    flex: 0 0 auto !important;
  }
}

/* ==========================================================
  footer
========================================================== */
.footer {
  position: relative;
  min-width: 1260px;
  padding: 0 30px 20px;
  background-color: #214268;
}

@media (max-width: 812px) {
  .footer {
    min-width: 0;
    padding: 0 0 20px;
  }
}

.footer-in {
  width: 1200px;
  margin: 0 auto;
}

@media (max-width: 812px) {
  .footer-in {
    width: auto;
  }
}

.footer-container {
  display: flex;
}

@media (max-width: 812px) {
  .footer-container {
    flex-wrap: wrap;
  }
}

.footer-company-info {
  flex: 0 0 auto;
  width: 410px;
  padding-top: 35px;
  text-align: left;
}

@media (max-width: 812px) {
  .footer-company-info {
    order: 2;
    width: 100%;
    padding: 35px 15px 0;
    text-align: center;
  }
}

.footer-logo {
  display: block;
  opacity: 1 !important;
}

.footer-logo > img {
  display: block;
  width: 320px;
}

@media (max-width: 812px) {
  .footer-logo > img {
    width: 240px;
    height: auto;
    margin: 0 auto;
  }
}

.footer-address {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #fff;
}

@media (max-width: 812px) {
  .footer-address {
    margin-top: 18px;
    font-size: 1.2rem;
  }
}

.footer-address .box-contact {
  margin: 1.6em auto 0;
  padding-top: 5px;
  position: relative;
}

.footer-address .box-contact ul {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 160px;
}

.footer-address .box-contact ul li {
  display: inline-block;
}

.footer-address .box-contact ul li:first-child {
  margin-right: 12px;
}

@media (max-width: 812px) {
  .footer-address .box-contact {
    width: 300px;
    margin-bottom: 2em;
    padding-top: 12px;
  }
  .footer-address .box-contact-txt {
    width: 130px;
  }
  .footer-address .box-contact ul {
    width: 180px;
    left: 120px;
  }
}

.footer-address .txt-contact {
  display: block;
  margin-top: 0.2em;
  color: #fff;
}

.footer-address .txt-contact .icon {
  margin-right: 6px;
}

.footer-address .txt-contact a {
  color: #fff;
}

@media (max-width: 812px) {
  .footer-address .txt-contact {
    font-size: 1.2rem;
  }
}

.footer-utilities {
  flex: 1;
}

@media (min-width: 813px) {
  .footer-utilities {
    text-align: right;
  }
}

@media (max-width: 812px) {
  .footer-utilities {
    order: 1;
    width: 100%;
  }
}

.footer-link {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 812px) {
  .footer-link {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media (min-width: 813px) {
  .footer-link > li {
    margin-left: 30px;
  }
  .footer-link > li:first-child {
    margin-left: 0;
  }
}

@media (max-width: 812px) {
  .footer-link > li {
    width: 50%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-size: 1rem;
  }
  .footer-link > li:nth-child(2n - 1) {
    border-right: 1px solid #fff;
  }
  .footer-link > li:last-child {
    width: 100%;
    border-top: none;
    border-right: none;
    text-align: center;
  }
}

.footer-link .btn-link {
  display: block;
  color: #fff;
  font-size: 1.2rem;
}

@media (max-width: 812px) {
  .footer-link .btn-link {
    padding: 10px 20px;
    font-size: 1.2rem;
  }
}

.footer-link .btn-link .txt-link {
  position: relative;
  display: inline-block;
  padding-left: 18px;
}

@media (max-width: 812px) {
  .footer-link .btn-link .txt-link {
    padding-left: 15px;
  }
}

.footer-link .btn-link .txt-link::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5.5px 0 5.5px 9px;
  border-color: transparent transparent transparent #fff;
  transform: translateY(-50%);
  content: '';
}

@media (max-width: 812px) {
  .footer-link .btn-link .txt-link::before {
    border-width: 4.5px 0 4.5px 8px;
  }
}

.footer-banner {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media (max-width: 812px) {
  .footer-banner {
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 9px 0;
  }
}

@media (min-width: 813px) {
  .footer-banner > li {
    margin-left: 30px;
  }
  .footer-banner > li:first-child {
    margin-left: 0;
  }
}

@media (max-width: 812px) {
  .footer-banner > li {
    width: 33.33%;
    padding: 0 6px;
  }
}

.footer-banner > li .btn-link {
  display: block;
}

.footer-banner > li img {
  display: block;
  width: 240px;
}

@media (max-width: 812px) {
  .footer-banner > li img {
    width: 100%;
  }
}

.footer-copyright {
  margin-top: 64px;
  font-size: 1.2rem;
  color: #fff;
  line-height: 1.2;
  text-align: center;
}

@media (max-width: 812px) {
  .footer-copyright {
    margin-top: 30px;
    font-size: 0.8rem;
  }
}

/* c-grid
---------------------------------------------------------- */
/*
  Exam
  <div class="row pd-32 pd-sp-0 mb-35">
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
  </div>
  **-pd-32 -> .row { margin-left: -32px; margin-right: -32px;}
          -> .row [class^="col"] { padding-left: 32px; padding-right: 32px;}
*/
.c-grid {
  /* c-grid col-i
---------------------------------------------------------- */
}

.c-grid .col {
  width: 100%;
  padding: 0 15px;
  margin-top: 25px;
}

@media (max-width: 812px) {
  .c-grid .col {
    padding: 0 7.5px;
    margin-top: 20px;
  }
}

.c-grid .col:last-child {
  margin-bottom: 0;
}

.c-grid .row {
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 812px) {
  .c-grid .row {
    margin-top: -20px;
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
}

.c-grid .row:last-child {
  margin-bottom: 0;
}

.c-grid .row.-pd-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.c-grid .row.-pd-0 > [class^="col"] {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.c-grid .row.-mb-0 {
  margin-top: 0px !important;
}

.c-grid .row.-mb-0 > [class^="col"] {
  margin-top: 0px !important;
}

.c-grid .row.-pd-1 {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.c-grid .row.-pd-1 > [class^="col"] {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.c-grid .row.-mb-1 {
  margin-top: -1px !important;
}

.c-grid .row.-mb-1 > [class^="col"] {
  margin-top: 1px !important;
}

.c-grid .row.-pd-2 {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.c-grid .row.-pd-2 > [class^="col"] {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.c-grid .row.-mb-2 {
  margin-top: -2px !important;
}

.c-grid .row.-mb-2 > [class^="col"] {
  margin-top: 2px !important;
}

.c-grid .row.-pd-3 {
  margin-left: -3px !important;
  margin-right: -3px !important;
}

.c-grid .row.-pd-3 > [class^="col"] {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.c-grid .row.-mb-3 {
  margin-top: -3px !important;
}

.c-grid .row.-mb-3 > [class^="col"] {
  margin-top: 3px !important;
}

.c-grid .row.-pd-4 {
  margin-left: -4px !important;
  margin-right: -4px !important;
}

.c-grid .row.-pd-4 > [class^="col"] {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.c-grid .row.-mb-4 {
  margin-top: -4px !important;
}

.c-grid .row.-mb-4 > [class^="col"] {
  margin-top: 4px !important;
}

.c-grid .row.-pd-5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.c-grid .row.-pd-5 > [class^="col"] {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.c-grid .row.-mb-5 {
  margin-top: -5px !important;
}

.c-grid .row.-mb-5 > [class^="col"] {
  margin-top: 5px !important;
}

.c-grid .row.-pd-6 {
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.c-grid .row.-pd-6 > [class^="col"] {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.c-grid .row.-mb-6 {
  margin-top: -6px !important;
}

.c-grid .row.-mb-6 > [class^="col"] {
  margin-top: 6px !important;
}

.c-grid .row.-pd-7 {
  margin-left: -7px !important;
  margin-right: -7px !important;
}

.c-grid .row.-pd-7 > [class^="col"] {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.c-grid .row.-mb-7 {
  margin-top: -7px !important;
}

.c-grid .row.-mb-7 > [class^="col"] {
  margin-top: 7px !important;
}

.c-grid .row.-pd-8 {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.c-grid .row.-pd-8 > [class^="col"] {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.c-grid .row.-mb-8 {
  margin-top: -8px !important;
}

.c-grid .row.-mb-8 > [class^="col"] {
  margin-top: 8px !important;
}

.c-grid .row.-pd-9 {
  margin-left: -9px !important;
  margin-right: -9px !important;
}

.c-grid .row.-pd-9 > [class^="col"] {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.c-grid .row.-mb-9 {
  margin-top: -9px !important;
}

.c-grid .row.-mb-9 > [class^="col"] {
  margin-top: 9px !important;
}

.c-grid .row.-pd-10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.c-grid .row.-pd-10 > [class^="col"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.c-grid .row.-mb-10 {
  margin-top: -10px !important;
}

.c-grid .row.-mb-10 > [class^="col"] {
  margin-top: 10px !important;
}

.c-grid .row.-pd-11 {
  margin-left: -11px !important;
  margin-right: -11px !important;
}

.c-grid .row.-pd-11 > [class^="col"] {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.c-grid .row.-mb-11 {
  margin-top: -11px !important;
}

.c-grid .row.-mb-11 > [class^="col"] {
  margin-top: 11px !important;
}

.c-grid .row.-pd-12 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.c-grid .row.-pd-12 > [class^="col"] {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.c-grid .row.-mb-12 {
  margin-top: -12px !important;
}

.c-grid .row.-mb-12 > [class^="col"] {
  margin-top: 12px !important;
}

.c-grid .row.-pd-13 {
  margin-left: -13px !important;
  margin-right: -13px !important;
}

.c-grid .row.-pd-13 > [class^="col"] {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.c-grid .row.-mb-13 {
  margin-top: -13px !important;
}

.c-grid .row.-mb-13 > [class^="col"] {
  margin-top: 13px !important;
}

.c-grid .row.-pd-14 {
  margin-left: -14px !important;
  margin-right: -14px !important;
}

.c-grid .row.-pd-14 > [class^="col"] {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.c-grid .row.-mb-14 {
  margin-top: -14px !important;
}

.c-grid .row.-mb-14 > [class^="col"] {
  margin-top: 14px !important;
}

.c-grid .row.-pd-15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.c-grid .row.-pd-15 > [class^="col"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.c-grid .row.-mb-15 {
  margin-top: -15px !important;
}

.c-grid .row.-mb-15 > [class^="col"] {
  margin-top: 15px !important;
}

.c-grid .row.-pd-16 {
  margin-left: -16px !important;
  margin-right: -16px !important;
}

.c-grid .row.-pd-16 > [class^="col"] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.c-grid .row.-mb-16 {
  margin-top: -16px !important;
}

.c-grid .row.-mb-16 > [class^="col"] {
  margin-top: 16px !important;
}

.c-grid .row.-pd-17 {
  margin-left: -17px !important;
  margin-right: -17px !important;
}

.c-grid .row.-pd-17 > [class^="col"] {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.c-grid .row.-mb-17 {
  margin-top: -17px !important;
}

.c-grid .row.-mb-17 > [class^="col"] {
  margin-top: 17px !important;
}

.c-grid .row.-pd-18 {
  margin-left: -18px !important;
  margin-right: -18px !important;
}

.c-grid .row.-pd-18 > [class^="col"] {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.c-grid .row.-mb-18 {
  margin-top: -18px !important;
}

.c-grid .row.-mb-18 > [class^="col"] {
  margin-top: 18px !important;
}

.c-grid .row.-pd-19 {
  margin-left: -19px !important;
  margin-right: -19px !important;
}

.c-grid .row.-pd-19 > [class^="col"] {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.c-grid .row.-mb-19 {
  margin-top: -19px !important;
}

.c-grid .row.-mb-19 > [class^="col"] {
  margin-top: 19px !important;
}

.c-grid .row.-pd-20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.c-grid .row.-pd-20 > [class^="col"] {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.c-grid .row.-mb-20 {
  margin-top: -20px !important;
}

.c-grid .row.-mb-20 > [class^="col"] {
  margin-top: 20px !important;
}

.c-grid .row.-pd-21 {
  margin-left: -21px !important;
  margin-right: -21px !important;
}

.c-grid .row.-pd-21 > [class^="col"] {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.c-grid .row.-mb-21 {
  margin-top: -21px !important;
}

.c-grid .row.-mb-21 > [class^="col"] {
  margin-top: 21px !important;
}

.c-grid .row.-pd-22 {
  margin-left: -22px !important;
  margin-right: -22px !important;
}

.c-grid .row.-pd-22 > [class^="col"] {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.c-grid .row.-mb-22 {
  margin-top: -22px !important;
}

.c-grid .row.-mb-22 > [class^="col"] {
  margin-top: 22px !important;
}

.c-grid .row.-pd-23 {
  margin-left: -23px !important;
  margin-right: -23px !important;
}

.c-grid .row.-pd-23 > [class^="col"] {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.c-grid .row.-mb-23 {
  margin-top: -23px !important;
}

.c-grid .row.-mb-23 > [class^="col"] {
  margin-top: 23px !important;
}

.c-grid .row.-pd-24 {
  margin-left: -24px !important;
  margin-right: -24px !important;
}

.c-grid .row.-pd-24 > [class^="col"] {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.c-grid .row.-mb-24 {
  margin-top: -24px !important;
}

.c-grid .row.-mb-24 > [class^="col"] {
  margin-top: 24px !important;
}

.c-grid .row.-pd-25 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.c-grid .row.-pd-25 > [class^="col"] {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.c-grid .row.-mb-25 {
  margin-top: -25px !important;
}

.c-grid .row.-mb-25 > [class^="col"] {
  margin-top: 25px !important;
}

.c-grid .row.-pd-26 {
  margin-left: -26px !important;
  margin-right: -26px !important;
}

.c-grid .row.-pd-26 > [class^="col"] {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.c-grid .row.-mb-26 {
  margin-top: -26px !important;
}

.c-grid .row.-mb-26 > [class^="col"] {
  margin-top: 26px !important;
}

.c-grid .row.-pd-27 {
  margin-left: -27px !important;
  margin-right: -27px !important;
}

.c-grid .row.-pd-27 > [class^="col"] {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.c-grid .row.-mb-27 {
  margin-top: -27px !important;
}

.c-grid .row.-mb-27 > [class^="col"] {
  margin-top: 27px !important;
}

.c-grid .row.-pd-28 {
  margin-left: -28px !important;
  margin-right: -28px !important;
}

.c-grid .row.-pd-28 > [class^="col"] {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.c-grid .row.-mb-28 {
  margin-top: -28px !important;
}

.c-grid .row.-mb-28 > [class^="col"] {
  margin-top: 28px !important;
}

.c-grid .row.-pd-29 {
  margin-left: -29px !important;
  margin-right: -29px !important;
}

.c-grid .row.-pd-29 > [class^="col"] {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.c-grid .row.-mb-29 {
  margin-top: -29px !important;
}

.c-grid .row.-mb-29 > [class^="col"] {
  margin-top: 29px !important;
}

.c-grid .row.-pd-30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.c-grid .row.-pd-30 > [class^="col"] {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.c-grid .row.-mb-30 {
  margin-top: -30px !important;
}

.c-grid .row.-mb-30 > [class^="col"] {
  margin-top: 30px !important;
}

.c-grid .row.-pd-31 {
  margin-left: -31px !important;
  margin-right: -31px !important;
}

.c-grid .row.-pd-31 > [class^="col"] {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.c-grid .row.-mb-31 {
  margin-top: -31px !important;
}

.c-grid .row.-mb-31 > [class^="col"] {
  margin-top: 31px !important;
}

.c-grid .row.-pd-32 {
  margin-left: -32px !important;
  margin-right: -32px !important;
}

.c-grid .row.-pd-32 > [class^="col"] {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.c-grid .row.-mb-32 {
  margin-top: -32px !important;
}

.c-grid .row.-mb-32 > [class^="col"] {
  margin-top: 32px !important;
}

.c-grid .row.-pd-33 {
  margin-left: -33px !important;
  margin-right: -33px !important;
}

.c-grid .row.-pd-33 > [class^="col"] {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.c-grid .row.-mb-33 {
  margin-top: -33px !important;
}

.c-grid .row.-mb-33 > [class^="col"] {
  margin-top: 33px !important;
}

.c-grid .row.-pd-34 {
  margin-left: -34px !important;
  margin-right: -34px !important;
}

.c-grid .row.-pd-34 > [class^="col"] {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.c-grid .row.-mb-34 {
  margin-top: -34px !important;
}

.c-grid .row.-mb-34 > [class^="col"] {
  margin-top: 34px !important;
}

.c-grid .row.-pd-35 {
  margin-left: -35px !important;
  margin-right: -35px !important;
}

.c-grid .row.-pd-35 > [class^="col"] {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.c-grid .row.-mb-35 {
  margin-top: -35px !important;
}

.c-grid .row.-mb-35 > [class^="col"] {
  margin-top: 35px !important;
}

.c-grid .row.-pd-36 {
  margin-left: -36px !important;
  margin-right: -36px !important;
}

.c-grid .row.-pd-36 > [class^="col"] {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.c-grid .row.-mb-36 {
  margin-top: -36px !important;
}

.c-grid .row.-mb-36 > [class^="col"] {
  margin-top: 36px !important;
}

.c-grid .row.-pd-37 {
  margin-left: -37px !important;
  margin-right: -37px !important;
}

.c-grid .row.-pd-37 > [class^="col"] {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.c-grid .row.-mb-37 {
  margin-top: -37px !important;
}

.c-grid .row.-mb-37 > [class^="col"] {
  margin-top: 37px !important;
}

.c-grid .row.-pd-38 {
  margin-left: -38px !important;
  margin-right: -38px !important;
}

.c-grid .row.-pd-38 > [class^="col"] {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.c-grid .row.-mb-38 {
  margin-top: -38px !important;
}

.c-grid .row.-mb-38 > [class^="col"] {
  margin-top: 38px !important;
}

.c-grid .row.-pd-39 {
  margin-left: -39px !important;
  margin-right: -39px !important;
}

.c-grid .row.-pd-39 > [class^="col"] {
  padding-left: 39px !important;
  padding-right: 39px !important;
}

.c-grid .row.-mb-39 {
  margin-top: -39px !important;
}

.c-grid .row.-mb-39 > [class^="col"] {
  margin-top: 39px !important;
}

.c-grid .row.-pd-40 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.c-grid .row.-pd-40 > [class^="col"] {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.c-grid .row.-mb-40 {
  margin-top: -40px !important;
}

.c-grid .row.-mb-40 > [class^="col"] {
  margin-top: 40px !important;
}

.c-grid .row.-pd-41 {
  margin-left: -41px !important;
  margin-right: -41px !important;
}

.c-grid .row.-pd-41 > [class^="col"] {
  padding-left: 41px !important;
  padding-right: 41px !important;
}

.c-grid .row.-mb-41 {
  margin-top: -41px !important;
}

.c-grid .row.-mb-41 > [class^="col"] {
  margin-top: 41px !important;
}

.c-grid .row.-pd-42 {
  margin-left: -42px !important;
  margin-right: -42px !important;
}

.c-grid .row.-pd-42 > [class^="col"] {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.c-grid .row.-mb-42 {
  margin-top: -42px !important;
}

.c-grid .row.-mb-42 > [class^="col"] {
  margin-top: 42px !important;
}

.c-grid .row.-pd-43 {
  margin-left: -43px !important;
  margin-right: -43px !important;
}

.c-grid .row.-pd-43 > [class^="col"] {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

.c-grid .row.-mb-43 {
  margin-top: -43px !important;
}

.c-grid .row.-mb-43 > [class^="col"] {
  margin-top: 43px !important;
}

.c-grid .row.-pd-44 {
  margin-left: -44px !important;
  margin-right: -44px !important;
}

.c-grid .row.-pd-44 > [class^="col"] {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.c-grid .row.-mb-44 {
  margin-top: -44px !important;
}

.c-grid .row.-mb-44 > [class^="col"] {
  margin-top: 44px !important;
}

.c-grid .row.-pd-45 {
  margin-left: -45px !important;
  margin-right: -45px !important;
}

.c-grid .row.-pd-45 > [class^="col"] {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.c-grid .row.-mb-45 {
  margin-top: -45px !important;
}

.c-grid .row.-mb-45 > [class^="col"] {
  margin-top: 45px !important;
}

.c-grid .row.-pd-46 {
  margin-left: -46px !important;
  margin-right: -46px !important;
}

.c-grid .row.-pd-46 > [class^="col"] {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.c-grid .row.-mb-46 {
  margin-top: -46px !important;
}

.c-grid .row.-mb-46 > [class^="col"] {
  margin-top: 46px !important;
}

.c-grid .row.-pd-47 {
  margin-left: -47px !important;
  margin-right: -47px !important;
}

.c-grid .row.-pd-47 > [class^="col"] {
  padding-left: 47px !important;
  padding-right: 47px !important;
}

.c-grid .row.-mb-47 {
  margin-top: -47px !important;
}

.c-grid .row.-mb-47 > [class^="col"] {
  margin-top: 47px !important;
}

.c-grid .row.-pd-48 {
  margin-left: -48px !important;
  margin-right: -48px !important;
}

.c-grid .row.-pd-48 > [class^="col"] {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.c-grid .row.-mb-48 {
  margin-top: -48px !important;
}

.c-grid .row.-mb-48 > [class^="col"] {
  margin-top: 48px !important;
}

.c-grid .row.-pd-49 {
  margin-left: -49px !important;
  margin-right: -49px !important;
}

.c-grid .row.-pd-49 > [class^="col"] {
  padding-left: 49px !important;
  padding-right: 49px !important;
}

.c-grid .row.-mb-49 {
  margin-top: -49px !important;
}

.c-grid .row.-mb-49 > [class^="col"] {
  margin-top: 49px !important;
}

.c-grid .row.-pd-50 {
  margin-left: -50px !important;
  margin-right: -50px !important;
}

.c-grid .row.-pd-50 > [class^="col"] {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.c-grid .row.-mb-50 {
  margin-top: -50px !important;
}

.c-grid .row.-mb-50 > [class^="col"] {
  margin-top: 50px !important;
}

.c-grid .row.-pd-51 {
  margin-left: -51px !important;
  margin-right: -51px !important;
}

.c-grid .row.-pd-51 > [class^="col"] {
  padding-left: 51px !important;
  padding-right: 51px !important;
}

.c-grid .row.-mb-51 {
  margin-top: -51px !important;
}

.c-grid .row.-mb-51 > [class^="col"] {
  margin-top: 51px !important;
}

.c-grid .row.-pd-52 {
  margin-left: -52px !important;
  margin-right: -52px !important;
}

.c-grid .row.-pd-52 > [class^="col"] {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.c-grid .row.-mb-52 {
  margin-top: -52px !important;
}

.c-grid .row.-mb-52 > [class^="col"] {
  margin-top: 52px !important;
}

.c-grid .row.-pd-53 {
  margin-left: -53px !important;
  margin-right: -53px !important;
}

.c-grid .row.-pd-53 > [class^="col"] {
  padding-left: 53px !important;
  padding-right: 53px !important;
}

.c-grid .row.-mb-53 {
  margin-top: -53px !important;
}

.c-grid .row.-mb-53 > [class^="col"] {
  margin-top: 53px !important;
}

.c-grid .row.-pd-54 {
  margin-left: -54px !important;
  margin-right: -54px !important;
}

.c-grid .row.-pd-54 > [class^="col"] {
  padding-left: 54px !important;
  padding-right: 54px !important;
}

.c-grid .row.-mb-54 {
  margin-top: -54px !important;
}

.c-grid .row.-mb-54 > [class^="col"] {
  margin-top: 54px !important;
}

.c-grid .row.-pd-55 {
  margin-left: -55px !important;
  margin-right: -55px !important;
}

.c-grid .row.-pd-55 > [class^="col"] {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.c-grid .row.-mb-55 {
  margin-top: -55px !important;
}

.c-grid .row.-mb-55 > [class^="col"] {
  margin-top: 55px !important;
}

.c-grid .row.-pd-56 {
  margin-left: -56px !important;
  margin-right: -56px !important;
}

.c-grid .row.-pd-56 > [class^="col"] {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.c-grid .row.-mb-56 {
  margin-top: -56px !important;
}

.c-grid .row.-mb-56 > [class^="col"] {
  margin-top: 56px !important;
}

.c-grid .row.-pd-57 {
  margin-left: -57px !important;
  margin-right: -57px !important;
}

.c-grid .row.-pd-57 > [class^="col"] {
  padding-left: 57px !important;
  padding-right: 57px !important;
}

.c-grid .row.-mb-57 {
  margin-top: -57px !important;
}

.c-grid .row.-mb-57 > [class^="col"] {
  margin-top: 57px !important;
}

.c-grid .row.-pd-58 {
  margin-left: -58px !important;
  margin-right: -58px !important;
}

.c-grid .row.-pd-58 > [class^="col"] {
  padding-left: 58px !important;
  padding-right: 58px !important;
}

.c-grid .row.-mb-58 {
  margin-top: -58px !important;
}

.c-grid .row.-mb-58 > [class^="col"] {
  margin-top: 58px !important;
}

.c-grid .row.-pd-59 {
  margin-left: -59px !important;
  margin-right: -59px !important;
}

.c-grid .row.-pd-59 > [class^="col"] {
  padding-left: 59px !important;
  padding-right: 59px !important;
}

.c-grid .row.-mb-59 {
  margin-top: -59px !important;
}

.c-grid .row.-mb-59 > [class^="col"] {
  margin-top: 59px !important;
}

.c-grid .row.-pd-60 {
  margin-left: -60px !important;
  margin-right: -60px !important;
}

.c-grid .row.-pd-60 > [class^="col"] {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.c-grid .row.-mb-60 {
  margin-top: -60px !important;
}

.c-grid .row.-mb-60 > [class^="col"] {
  margin-top: 60px !important;
}

@media (min-width: 813px) {
  .c-grid .row.-pd-pc-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .c-grid .row.-pd-pc-0 > [class^="col"] {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
  }
  .c-grid .row.-mb-pc-0 {
    margin-top: 0px !important;
  }
  .c-grid .row.-mb-pc-0 > [class^="col"] {
    margin-top: 0px !important;
  }
  .c-grid .row.-pd-pc-1 {
    margin-left: -1px !important;
    margin-right: -1px !important;
    margin-top: -2px !important;
  }
  .c-grid .row.-pd-pc-1 > [class^="col"] {
    padding-left: 1px !important;
    padding-right: 1px !important;
    margin-top: 2px !important;
  }
  .c-grid .row.-mb-pc-1 {
    margin-top: -1px !important;
  }
  .c-grid .row.-mb-pc-1 > [class^="col"] {
    margin-top: 1px !important;
  }
  .c-grid .row.-pd-pc-2 {
    margin-left: -2px !important;
    margin-right: -2px !important;
    margin-top: -4px !important;
  }
  .c-grid .row.-pd-pc-2 > [class^="col"] {
    padding-left: 2px !important;
    padding-right: 2px !important;
    margin-top: 4px !important;
  }
  .c-grid .row.-mb-pc-2 {
    margin-top: -2px !important;
  }
  .c-grid .row.-mb-pc-2 > [class^="col"] {
    margin-top: 2px !important;
  }
  .c-grid .row.-pd-pc-3 {
    margin-left: -3px !important;
    margin-right: -3px !important;
    margin-top: -6px !important;
  }
  .c-grid .row.-pd-pc-3 > [class^="col"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
    margin-top: 6px !important;
  }
  .c-grid .row.-mb-pc-3 {
    margin-top: -3px !important;
  }
  .c-grid .row.-mb-pc-3 > [class^="col"] {
    margin-top: 3px !important;
  }
  .c-grid .row.-pd-pc-4 {
    margin-left: -4px !important;
    margin-right: -4px !important;
    margin-top: -8px !important;
  }
  .c-grid .row.-pd-pc-4 > [class^="col"] {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 8px !important;
  }
  .c-grid .row.-mb-pc-4 {
    margin-top: -4px !important;
  }
  .c-grid .row.-mb-pc-4 > [class^="col"] {
    margin-top: 4px !important;
  }
  .c-grid .row.-pd-pc-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: -10px !important;
  }
  .c-grid .row.-pd-pc-5 > [class^="col"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: 10px !important;
  }
  .c-grid .row.-mb-pc-5 {
    margin-top: -5px !important;
  }
  .c-grid .row.-mb-pc-5 > [class^="col"] {
    margin-top: 5px !important;
  }
  .c-grid .row.-pd-pc-6 {
    margin-left: -6px !important;
    margin-right: -6px !important;
    margin-top: -12px !important;
  }
  .c-grid .row.-pd-pc-6 > [class^="col"] {
    padding-left: 6px !important;
    padding-right: 6px !important;
    margin-top: 12px !important;
  }
  .c-grid .row.-mb-pc-6 {
    margin-top: -6px !important;
  }
  .c-grid .row.-mb-pc-6 > [class^="col"] {
    margin-top: 6px !important;
  }
  .c-grid .row.-pd-pc-7 {
    margin-left: -7px !important;
    margin-right: -7px !important;
    margin-top: -14px !important;
  }
  .c-grid .row.-pd-pc-7 > [class^="col"] {
    padding-left: 7px !important;
    padding-right: 7px !important;
    margin-top: 14px !important;
  }
  .c-grid .row.-mb-pc-7 {
    margin-top: -7px !important;
  }
  .c-grid .row.-mb-pc-7 > [class^="col"] {
    margin-top: 7px !important;
  }
  .c-grid .row.-pd-pc-8 {
    margin-left: -8px !important;
    margin-right: -8px !important;
    margin-top: -16px !important;
  }
  .c-grid .row.-pd-pc-8 > [class^="col"] {
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-top: 16px !important;
  }
  .c-grid .row.-mb-pc-8 {
    margin-top: -8px !important;
  }
  .c-grid .row.-mb-pc-8 > [class^="col"] {
    margin-top: 8px !important;
  }
  .c-grid .row.-pd-pc-9 {
    margin-left: -9px !important;
    margin-right: -9px !important;
    margin-top: -18px !important;
  }
  .c-grid .row.-pd-pc-9 > [class^="col"] {
    padding-left: 9px !important;
    padding-right: 9px !important;
    margin-top: 18px !important;
  }
  .c-grid .row.-mb-pc-9 {
    margin-top: -9px !important;
  }
  .c-grid .row.-mb-pc-9 > [class^="col"] {
    margin-top: 9px !important;
  }
  .c-grid .row.-pd-pc-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
    margin-top: -20px !important;
  }
  .c-grid .row.-pd-pc-10 > [class^="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 20px !important;
  }
  .c-grid .row.-mb-pc-10 {
    margin-top: -10px !important;
  }
  .c-grid .row.-mb-pc-10 > [class^="col"] {
    margin-top: 10px !important;
  }
  .c-grid .row.-pd-pc-11 {
    margin-left: -11px !important;
    margin-right: -11px !important;
    margin-top: -22px !important;
  }
  .c-grid .row.-pd-pc-11 > [class^="col"] {
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-top: 22px !important;
  }
  .c-grid .row.-mb-pc-11 {
    margin-top: -11px !important;
  }
  .c-grid .row.-mb-pc-11 > [class^="col"] {
    margin-top: 11px !important;
  }
  .c-grid .row.-pd-pc-12 {
    margin-left: -12px !important;
    margin-right: -12px !important;
    margin-top: -24px !important;
  }
  .c-grid .row.-pd-pc-12 > [class^="col"] {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-top: 24px !important;
  }
  .c-grid .row.-mb-pc-12 {
    margin-top: -12px !important;
  }
  .c-grid .row.-mb-pc-12 > [class^="col"] {
    margin-top: 12px !important;
  }
  .c-grid .row.-pd-pc-13 {
    margin-left: -13px !important;
    margin-right: -13px !important;
    margin-top: -26px !important;
  }
  .c-grid .row.-pd-pc-13 > [class^="col"] {
    padding-left: 13px !important;
    padding-right: 13px !important;
    margin-top: 26px !important;
  }
  .c-grid .row.-mb-pc-13 {
    margin-top: -13px !important;
  }
  .c-grid .row.-mb-pc-13 > [class^="col"] {
    margin-top: 13px !important;
  }
  .c-grid .row.-pd-pc-14 {
    margin-left: -14px !important;
    margin-right: -14px !important;
    margin-top: -28px !important;
  }
  .c-grid .row.-pd-pc-14 > [class^="col"] {
    padding-left: 14px !important;
    padding-right: 14px !important;
    margin-top: 28px !important;
  }
  .c-grid .row.-mb-pc-14 {
    margin-top: -14px !important;
  }
  .c-grid .row.-mb-pc-14 > [class^="col"] {
    margin-top: 14px !important;
  }
  .c-grid .row.-pd-pc-15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
    margin-top: -30px !important;
  }
  .c-grid .row.-pd-pc-15 > [class^="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 30px !important;
  }
  .c-grid .row.-mb-pc-15 {
    margin-top: -15px !important;
  }
  .c-grid .row.-mb-pc-15 > [class^="col"] {
    margin-top: 15px !important;
  }
  .c-grid .row.-pd-pc-16 {
    margin-left: -16px !important;
    margin-right: -16px !important;
    margin-top: -32px !important;
  }
  .c-grid .row.-pd-pc-16 > [class^="col"] {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-top: 32px !important;
  }
  .c-grid .row.-mb-pc-16 {
    margin-top: -16px !important;
  }
  .c-grid .row.-mb-pc-16 > [class^="col"] {
    margin-top: 16px !important;
  }
  .c-grid .row.-pd-pc-17 {
    margin-left: -17px !important;
    margin-right: -17px !important;
    margin-top: -34px !important;
  }
  .c-grid .row.-pd-pc-17 > [class^="col"] {
    padding-left: 17px !important;
    padding-right: 17px !important;
    margin-top: 34px !important;
  }
  .c-grid .row.-mb-pc-17 {
    margin-top: -17px !important;
  }
  .c-grid .row.-mb-pc-17 > [class^="col"] {
    margin-top: 17px !important;
  }
  .c-grid .row.-pd-pc-18 {
    margin-left: -18px !important;
    margin-right: -18px !important;
    margin-top: -36px !important;
  }
  .c-grid .row.-pd-pc-18 > [class^="col"] {
    padding-left: 18px !important;
    padding-right: 18px !important;
    margin-top: 36px !important;
  }
  .c-grid .row.-mb-pc-18 {
    margin-top: -18px !important;
  }
  .c-grid .row.-mb-pc-18 > [class^="col"] {
    margin-top: 18px !important;
  }
  .c-grid .row.-pd-pc-19 {
    margin-left: -19px !important;
    margin-right: -19px !important;
    margin-top: -38px !important;
  }
  .c-grid .row.-pd-pc-19 > [class^="col"] {
    padding-left: 19px !important;
    padding-right: 19px !important;
    margin-top: 38px !important;
  }
  .c-grid .row.-mb-pc-19 {
    margin-top: -19px !important;
  }
  .c-grid .row.-mb-pc-19 > [class^="col"] {
    margin-top: 19px !important;
  }
  .c-grid .row.-pd-pc-20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
    margin-top: -40px !important;
  }
  .c-grid .row.-pd-pc-20 > [class^="col"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 40px !important;
  }
  .c-grid .row.-mb-pc-20 {
    margin-top: -20px !important;
  }
  .c-grid .row.-mb-pc-20 > [class^="col"] {
    margin-top: 20px !important;
  }
  .c-grid .row.-pd-pc-21 {
    margin-left: -21px !important;
    margin-right: -21px !important;
    margin-top: -42px !important;
  }
  .c-grid .row.-pd-pc-21 > [class^="col"] {
    padding-left: 21px !important;
    padding-right: 21px !important;
    margin-top: 42px !important;
  }
  .c-grid .row.-mb-pc-21 {
    margin-top: -21px !important;
  }
  .c-grid .row.-mb-pc-21 > [class^="col"] {
    margin-top: 21px !important;
  }
  .c-grid .row.-pd-pc-22 {
    margin-left: -22px !important;
    margin-right: -22px !important;
    margin-top: -44px !important;
  }
  .c-grid .row.-pd-pc-22 > [class^="col"] {
    padding-left: 22px !important;
    padding-right: 22px !important;
    margin-top: 44px !important;
  }
  .c-grid .row.-mb-pc-22 {
    margin-top: -22px !important;
  }
  .c-grid .row.-mb-pc-22 > [class^="col"] {
    margin-top: 22px !important;
  }
  .c-grid .row.-pd-pc-23 {
    margin-left: -23px !important;
    margin-right: -23px !important;
    margin-top: -46px !important;
  }
  .c-grid .row.-pd-pc-23 > [class^="col"] {
    padding-left: 23px !important;
    padding-right: 23px !important;
    margin-top: 46px !important;
  }
  .c-grid .row.-mb-pc-23 {
    margin-top: -23px !important;
  }
  .c-grid .row.-mb-pc-23 > [class^="col"] {
    margin-top: 23px !important;
  }
  .c-grid .row.-pd-pc-24 {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: -48px !important;
  }
  .c-grid .row.-pd-pc-24 > [class^="col"] {
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin-top: 48px !important;
  }
  .c-grid .row.-mb-pc-24 {
    margin-top: -24px !important;
  }
  .c-grid .row.-mb-pc-24 > [class^="col"] {
    margin-top: 24px !important;
  }
  .c-grid .row.-pd-pc-25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
    margin-top: -50px !important;
  }
  .c-grid .row.-pd-pc-25 > [class^="col"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-top: 50px !important;
  }
  .c-grid .row.-mb-pc-25 {
    margin-top: -25px !important;
  }
  .c-grid .row.-mb-pc-25 > [class^="col"] {
    margin-top: 25px !important;
  }
  .c-grid .row.-pd-pc-26 {
    margin-left: -26px !important;
    margin-right: -26px !important;
    margin-top: -52px !important;
  }
  .c-grid .row.-pd-pc-26 > [class^="col"] {
    padding-left: 26px !important;
    padding-right: 26px !important;
    margin-top: 52px !important;
  }
  .c-grid .row.-mb-pc-26 {
    margin-top: -26px !important;
  }
  .c-grid .row.-mb-pc-26 > [class^="col"] {
    margin-top: 26px !important;
  }
  .c-grid .row.-pd-pc-27 {
    margin-left: -27px !important;
    margin-right: -27px !important;
    margin-top: -54px !important;
  }
  .c-grid .row.-pd-pc-27 > [class^="col"] {
    padding-left: 27px !important;
    padding-right: 27px !important;
    margin-top: 54px !important;
  }
  .c-grid .row.-mb-pc-27 {
    margin-top: -27px !important;
  }
  .c-grid .row.-mb-pc-27 > [class^="col"] {
    margin-top: 27px !important;
  }
  .c-grid .row.-pd-pc-28 {
    margin-left: -28px !important;
    margin-right: -28px !important;
    margin-top: -56px !important;
  }
  .c-grid .row.-pd-pc-28 > [class^="col"] {
    padding-left: 28px !important;
    padding-right: 28px !important;
    margin-top: 56px !important;
  }
  .c-grid .row.-mb-pc-28 {
    margin-top: -28px !important;
  }
  .c-grid .row.-mb-pc-28 > [class^="col"] {
    margin-top: 28px !important;
  }
  .c-grid .row.-pd-pc-29 {
    margin-left: -29px !important;
    margin-right: -29px !important;
    margin-top: -58px !important;
  }
  .c-grid .row.-pd-pc-29 > [class^="col"] {
    padding-left: 29px !important;
    padding-right: 29px !important;
    margin-top: 58px !important;
  }
  .c-grid .row.-mb-pc-29 {
    margin-top: -29px !important;
  }
  .c-grid .row.-mb-pc-29 > [class^="col"] {
    margin-top: 29px !important;
  }
  .c-grid .row.-pd-pc-30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
    margin-top: -60px !important;
  }
  .c-grid .row.-pd-pc-30 > [class^="col"] {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 60px !important;
  }
  .c-grid .row.-mb-pc-30 {
    margin-top: -30px !important;
  }
  .c-grid .row.-mb-pc-30 > [class^="col"] {
    margin-top: 30px !important;
  }
  .c-grid .row.-pd-pc-31 {
    margin-left: -31px !important;
    margin-right: -31px !important;
    margin-top: -62px !important;
  }
  .c-grid .row.-pd-pc-31 > [class^="col"] {
    padding-left: 31px !important;
    padding-right: 31px !important;
    margin-top: 62px !important;
  }
  .c-grid .row.-mb-pc-31 {
    margin-top: -31px !important;
  }
  .c-grid .row.-mb-pc-31 > [class^="col"] {
    margin-top: 31px !important;
  }
  .c-grid .row.-pd-pc-32 {
    margin-left: -32px !important;
    margin-right: -32px !important;
    margin-top: -64px !important;
  }
  .c-grid .row.-pd-pc-32 > [class^="col"] {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-top: 64px !important;
  }
  .c-grid .row.-mb-pc-32 {
    margin-top: -32px !important;
  }
  .c-grid .row.-mb-pc-32 > [class^="col"] {
    margin-top: 32px !important;
  }
  .c-grid .row.-pd-pc-33 {
    margin-left: -33px !important;
    margin-right: -33px !important;
    margin-top: -66px !important;
  }
  .c-grid .row.-pd-pc-33 > [class^="col"] {
    padding-left: 33px !important;
    padding-right: 33px !important;
    margin-top: 66px !important;
  }
  .c-grid .row.-mb-pc-33 {
    margin-top: -33px !important;
  }
  .c-grid .row.-mb-pc-33 > [class^="col"] {
    margin-top: 33px !important;
  }
  .c-grid .row.-pd-pc-34 {
    margin-left: -34px !important;
    margin-right: -34px !important;
    margin-top: -68px !important;
  }
  .c-grid .row.-pd-pc-34 > [class^="col"] {
    padding-left: 34px !important;
    padding-right: 34px !important;
    margin-top: 68px !important;
  }
  .c-grid .row.-mb-pc-34 {
    margin-top: -34px !important;
  }
  .c-grid .row.-mb-pc-34 > [class^="col"] {
    margin-top: 34px !important;
  }
  .c-grid .row.-pd-pc-35 {
    margin-left: -35px !important;
    margin-right: -35px !important;
    margin-top: -70px !important;
  }
  .c-grid .row.-pd-pc-35 > [class^="col"] {
    padding-left: 35px !important;
    padding-right: 35px !important;
    margin-top: 70px !important;
  }
  .c-grid .row.-mb-pc-35 {
    margin-top: -35px !important;
  }
  .c-grid .row.-mb-pc-35 > [class^="col"] {
    margin-top: 35px !important;
  }
  .c-grid .row.-pd-pc-36 {
    margin-left: -36px !important;
    margin-right: -36px !important;
    margin-top: -72px !important;
  }
  .c-grid .row.-pd-pc-36 > [class^="col"] {
    padding-left: 36px !important;
    padding-right: 36px !important;
    margin-top: 72px !important;
  }
  .c-grid .row.-mb-pc-36 {
    margin-top: -36px !important;
  }
  .c-grid .row.-mb-pc-36 > [class^="col"] {
    margin-top: 36px !important;
  }
  .c-grid .row.-pd-pc-37 {
    margin-left: -37px !important;
    margin-right: -37px !important;
    margin-top: -74px !important;
  }
  .c-grid .row.-pd-pc-37 > [class^="col"] {
    padding-left: 37px !important;
    padding-right: 37px !important;
    margin-top: 74px !important;
  }
  .c-grid .row.-mb-pc-37 {
    margin-top: -37px !important;
  }
  .c-grid .row.-mb-pc-37 > [class^="col"] {
    margin-top: 37px !important;
  }
  .c-grid .row.-pd-pc-38 {
    margin-left: -38px !important;
    margin-right: -38px !important;
    margin-top: -76px !important;
  }
  .c-grid .row.-pd-pc-38 > [class^="col"] {
    padding-left: 38px !important;
    padding-right: 38px !important;
    margin-top: 76px !important;
  }
  .c-grid .row.-mb-pc-38 {
    margin-top: -38px !important;
  }
  .c-grid .row.-mb-pc-38 > [class^="col"] {
    margin-top: 38px !important;
  }
  .c-grid .row.-pd-pc-39 {
    margin-left: -39px !important;
    margin-right: -39px !important;
    margin-top: -78px !important;
  }
  .c-grid .row.-pd-pc-39 > [class^="col"] {
    padding-left: 39px !important;
    padding-right: 39px !important;
    margin-top: 78px !important;
  }
  .c-grid .row.-mb-pc-39 {
    margin-top: -39px !important;
  }
  .c-grid .row.-mb-pc-39 > [class^="col"] {
    margin-top: 39px !important;
  }
  .c-grid .row.-pd-pc-40 {
    margin-left: -40px !important;
    margin-right: -40px !important;
    margin-top: -80px !important;
  }
  .c-grid .row.-pd-pc-40 > [class^="col"] {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 80px !important;
  }
  .c-grid .row.-mb-pc-40 {
    margin-top: -40px !important;
  }
  .c-grid .row.-mb-pc-40 > [class^="col"] {
    margin-top: 40px !important;
  }
  .c-grid .row.-pd-pc-41 {
    margin-left: -41px !important;
    margin-right: -41px !important;
    margin-top: -82px !important;
  }
  .c-grid .row.-pd-pc-41 > [class^="col"] {
    padding-left: 41px !important;
    padding-right: 41px !important;
    margin-top: 82px !important;
  }
  .c-grid .row.-mb-pc-41 {
    margin-top: -41px !important;
  }
  .c-grid .row.-mb-pc-41 > [class^="col"] {
    margin-top: 41px !important;
  }
  .c-grid .row.-pd-pc-42 {
    margin-left: -42px !important;
    margin-right: -42px !important;
    margin-top: -84px !important;
  }
  .c-grid .row.-pd-pc-42 > [class^="col"] {
    padding-left: 42px !important;
    padding-right: 42px !important;
    margin-top: 84px !important;
  }
  .c-grid .row.-mb-pc-42 {
    margin-top: -42px !important;
  }
  .c-grid .row.-mb-pc-42 > [class^="col"] {
    margin-top: 42px !important;
  }
  .c-grid .row.-pd-pc-43 {
    margin-left: -43px !important;
    margin-right: -43px !important;
    margin-top: -86px !important;
  }
  .c-grid .row.-pd-pc-43 > [class^="col"] {
    padding-left: 43px !important;
    padding-right: 43px !important;
    margin-top: 86px !important;
  }
  .c-grid .row.-mb-pc-43 {
    margin-top: -43px !important;
  }
  .c-grid .row.-mb-pc-43 > [class^="col"] {
    margin-top: 43px !important;
  }
  .c-grid .row.-pd-pc-44 {
    margin-left: -44px !important;
    margin-right: -44px !important;
    margin-top: -88px !important;
  }
  .c-grid .row.-pd-pc-44 > [class^="col"] {
    padding-left: 44px !important;
    padding-right: 44px !important;
    margin-top: 88px !important;
  }
  .c-grid .row.-mb-pc-44 {
    margin-top: -44px !important;
  }
  .c-grid .row.-mb-pc-44 > [class^="col"] {
    margin-top: 44px !important;
  }
  .c-grid .row.-pd-pc-45 {
    margin-left: -45px !important;
    margin-right: -45px !important;
    margin-top: -90px !important;
  }
  .c-grid .row.-pd-pc-45 > [class^="col"] {
    padding-left: 45px !important;
    padding-right: 45px !important;
    margin-top: 90px !important;
  }
  .c-grid .row.-mb-pc-45 {
    margin-top: -45px !important;
  }
  .c-grid .row.-mb-pc-45 > [class^="col"] {
    margin-top: 45px !important;
  }
  .c-grid .row.-pd-pc-46 {
    margin-left: -46px !important;
    margin-right: -46px !important;
    margin-top: -92px !important;
  }
  .c-grid .row.-pd-pc-46 > [class^="col"] {
    padding-left: 46px !important;
    padding-right: 46px !important;
    margin-top: 92px !important;
  }
  .c-grid .row.-mb-pc-46 {
    margin-top: -46px !important;
  }
  .c-grid .row.-mb-pc-46 > [class^="col"] {
    margin-top: 46px !important;
  }
  .c-grid .row.-pd-pc-47 {
    margin-left: -47px !important;
    margin-right: -47px !important;
    margin-top: -94px !important;
  }
  .c-grid .row.-pd-pc-47 > [class^="col"] {
    padding-left: 47px !important;
    padding-right: 47px !important;
    margin-top: 94px !important;
  }
  .c-grid .row.-mb-pc-47 {
    margin-top: -47px !important;
  }
  .c-grid .row.-mb-pc-47 > [class^="col"] {
    margin-top: 47px !important;
  }
  .c-grid .row.-pd-pc-48 {
    margin-left: -48px !important;
    margin-right: -48px !important;
    margin-top: -96px !important;
  }
  .c-grid .row.-pd-pc-48 > [class^="col"] {
    padding-left: 48px !important;
    padding-right: 48px !important;
    margin-top: 96px !important;
  }
  .c-grid .row.-mb-pc-48 {
    margin-top: -48px !important;
  }
  .c-grid .row.-mb-pc-48 > [class^="col"] {
    margin-top: 48px !important;
  }
  .c-grid .row.-pd-pc-49 {
    margin-left: -49px !important;
    margin-right: -49px !important;
    margin-top: -98px !important;
  }
  .c-grid .row.-pd-pc-49 > [class^="col"] {
    padding-left: 49px !important;
    padding-right: 49px !important;
    margin-top: 98px !important;
  }
  .c-grid .row.-mb-pc-49 {
    margin-top: -49px !important;
  }
  .c-grid .row.-mb-pc-49 > [class^="col"] {
    margin-top: 49px !important;
  }
  .c-grid .row.-pd-pc-50 {
    margin-left: -50px !important;
    margin-right: -50px !important;
    margin-top: -100px !important;
  }
  .c-grid .row.-pd-pc-50 > [class^="col"] {
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-top: 100px !important;
  }
  .c-grid .row.-mb-pc-50 {
    margin-top: -50px !important;
  }
  .c-grid .row.-mb-pc-50 > [class^="col"] {
    margin-top: 50px !important;
  }
  .c-grid .row.-pd-pc-51 {
    margin-left: -51px !important;
    margin-right: -51px !important;
    margin-top: -102px !important;
  }
  .c-grid .row.-pd-pc-51 > [class^="col"] {
    padding-left: 51px !important;
    padding-right: 51px !important;
    margin-top: 102px !important;
  }
  .c-grid .row.-mb-pc-51 {
    margin-top: -51px !important;
  }
  .c-grid .row.-mb-pc-51 > [class^="col"] {
    margin-top: 51px !important;
  }
  .c-grid .row.-pd-pc-52 {
    margin-left: -52px !important;
    margin-right: -52px !important;
    margin-top: -104px !important;
  }
  .c-grid .row.-pd-pc-52 > [class^="col"] {
    padding-left: 52px !important;
    padding-right: 52px !important;
    margin-top: 104px !important;
  }
  .c-grid .row.-mb-pc-52 {
    margin-top: -52px !important;
  }
  .c-grid .row.-mb-pc-52 > [class^="col"] {
    margin-top: 52px !important;
  }
  .c-grid .row.-pd-pc-53 {
    margin-left: -53px !important;
    margin-right: -53px !important;
    margin-top: -106px !important;
  }
  .c-grid .row.-pd-pc-53 > [class^="col"] {
    padding-left: 53px !important;
    padding-right: 53px !important;
    margin-top: 106px !important;
  }
  .c-grid .row.-mb-pc-53 {
    margin-top: -53px !important;
  }
  .c-grid .row.-mb-pc-53 > [class^="col"] {
    margin-top: 53px !important;
  }
  .c-grid .row.-pd-pc-54 {
    margin-left: -54px !important;
    margin-right: -54px !important;
    margin-top: -108px !important;
  }
  .c-grid .row.-pd-pc-54 > [class^="col"] {
    padding-left: 54px !important;
    padding-right: 54px !important;
    margin-top: 108px !important;
  }
  .c-grid .row.-mb-pc-54 {
    margin-top: -54px !important;
  }
  .c-grid .row.-mb-pc-54 > [class^="col"] {
    margin-top: 54px !important;
  }
  .c-grid .row.-pd-pc-55 {
    margin-left: -55px !important;
    margin-right: -55px !important;
    margin-top: -110px !important;
  }
  .c-grid .row.-pd-pc-55 > [class^="col"] {
    padding-left: 55px !important;
    padding-right: 55px !important;
    margin-top: 110px !important;
  }
  .c-grid .row.-mb-pc-55 {
    margin-top: -55px !important;
  }
  .c-grid .row.-mb-pc-55 > [class^="col"] {
    margin-top: 55px !important;
  }
  .c-grid .row.-pd-pc-56 {
    margin-left: -56px !important;
    margin-right: -56px !important;
    margin-top: -112px !important;
  }
  .c-grid .row.-pd-pc-56 > [class^="col"] {
    padding-left: 56px !important;
    padding-right: 56px !important;
    margin-top: 112px !important;
  }
  .c-grid .row.-mb-pc-56 {
    margin-top: -56px !important;
  }
  .c-grid .row.-mb-pc-56 > [class^="col"] {
    margin-top: 56px !important;
  }
  .c-grid .row.-pd-pc-57 {
    margin-left: -57px !important;
    margin-right: -57px !important;
    margin-top: -114px !important;
  }
  .c-grid .row.-pd-pc-57 > [class^="col"] {
    padding-left: 57px !important;
    padding-right: 57px !important;
    margin-top: 114px !important;
  }
  .c-grid .row.-mb-pc-57 {
    margin-top: -57px !important;
  }
  .c-grid .row.-mb-pc-57 > [class^="col"] {
    margin-top: 57px !important;
  }
  .c-grid .row.-pd-pc-58 {
    margin-left: -58px !important;
    margin-right: -58px !important;
    margin-top: -116px !important;
  }
  .c-grid .row.-pd-pc-58 > [class^="col"] {
    padding-left: 58px !important;
    padding-right: 58px !important;
    margin-top: 116px !important;
  }
  .c-grid .row.-mb-pc-58 {
    margin-top: -58px !important;
  }
  .c-grid .row.-mb-pc-58 > [class^="col"] {
    margin-top: 58px !important;
  }
  .c-grid .row.-pd-pc-59 {
    margin-left: -59px !important;
    margin-right: -59px !important;
    margin-top: -118px !important;
  }
  .c-grid .row.-pd-pc-59 > [class^="col"] {
    padding-left: 59px !important;
    padding-right: 59px !important;
    margin-top: 118px !important;
  }
  .c-grid .row.-mb-pc-59 {
    margin-top: -59px !important;
  }
  .c-grid .row.-mb-pc-59 > [class^="col"] {
    margin-top: 59px !important;
  }
  .c-grid .row.-pd-pc-60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
    margin-top: -120px !important;
  }
  .c-grid .row.-pd-pc-60 > [class^="col"] {
    padding-left: 60px !important;
    padding-right: 60px !important;
    margin-top: 120px !important;
  }
  .c-grid .row.-mb-pc-60 {
    margin-top: -60px !important;
  }
  .c-grid .row.-mb-pc-60 > [class^="col"] {
    margin-top: 60px !important;
  }
}

@media (max-width: 812px) {
  .c-grid .row.-pd-sp-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
  }
  .c-grid .row.-pd-sp-0 > [class^="col"] {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 0px !important;
  }
  .c-grid .row.-mb-sp-0 {
    margin-top: 0px !important;
  }
  .c-grid .row.-mb-sp-0 > [class^="col"] {
    margin-top: 0px !important;
  }
  .c-grid .row.-pd-sp-1 {
    margin-left: -1px !important;
    margin-right: -1px !important;
    margin-top: -2px !important;
  }
  .c-grid .row.-pd-sp-1 > [class^="col"] {
    padding-left: 1px !important;
    padding-right: 1px !important;
    margin-top: 2px !important;
  }
  .c-grid .row.-mb-sp-1 {
    margin-top: -1px !important;
  }
  .c-grid .row.-mb-sp-1 > [class^="col"] {
    margin-top: 1px !important;
  }
  .c-grid .row.-pd-sp-2 {
    margin-left: -2px !important;
    margin-right: -2px !important;
    margin-top: -4px !important;
  }
  .c-grid .row.-pd-sp-2 > [class^="col"] {
    padding-left: 2px !important;
    padding-right: 2px !important;
    margin-top: 4px !important;
  }
  .c-grid .row.-mb-sp-2 {
    margin-top: -2px !important;
  }
  .c-grid .row.-mb-sp-2 > [class^="col"] {
    margin-top: 2px !important;
  }
  .c-grid .row.-pd-sp-3 {
    margin-left: -3px !important;
    margin-right: -3px !important;
    margin-top: -6px !important;
  }
  .c-grid .row.-pd-sp-3 > [class^="col"] {
    padding-left: 3px !important;
    padding-right: 3px !important;
    margin-top: 6px !important;
  }
  .c-grid .row.-mb-sp-3 {
    margin-top: -3px !important;
  }
  .c-grid .row.-mb-sp-3 > [class^="col"] {
    margin-top: 3px !important;
  }
  .c-grid .row.-pd-sp-4 {
    margin-left: -4px !important;
    margin-right: -4px !important;
    margin-top: -8px !important;
  }
  .c-grid .row.-pd-sp-4 > [class^="col"] {
    padding-left: 4px !important;
    padding-right: 4px !important;
    margin-top: 8px !important;
  }
  .c-grid .row.-mb-sp-4 {
    margin-top: -4px !important;
  }
  .c-grid .row.-mb-sp-4 > [class^="col"] {
    margin-top: 4px !important;
  }
  .c-grid .row.-pd-sp-5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
    margin-top: -10px !important;
  }
  .c-grid .row.-pd-sp-5 > [class^="col"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: 10px !important;
  }
  .c-grid .row.-mb-sp-5 {
    margin-top: -5px !important;
  }
  .c-grid .row.-mb-sp-5 > [class^="col"] {
    margin-top: 5px !important;
  }
  .c-grid .row.-pd-sp-6 {
    margin-left: -6px !important;
    margin-right: -6px !important;
    margin-top: -12px !important;
  }
  .c-grid .row.-pd-sp-6 > [class^="col"] {
    padding-left: 6px !important;
    padding-right: 6px !important;
    margin-top: 12px !important;
  }
  .c-grid .row.-mb-sp-6 {
    margin-top: -6px !important;
  }
  .c-grid .row.-mb-sp-6 > [class^="col"] {
    margin-top: 6px !important;
  }
  .c-grid .row.-pd-sp-7 {
    margin-left: -7px !important;
    margin-right: -7px !important;
    margin-top: -14px !important;
  }
  .c-grid .row.-pd-sp-7 > [class^="col"] {
    padding-left: 7px !important;
    padding-right: 7px !important;
    margin-top: 14px !important;
  }
  .c-grid .row.-mb-sp-7 {
    margin-top: -7px !important;
  }
  .c-grid .row.-mb-sp-7 > [class^="col"] {
    margin-top: 7px !important;
  }
  .c-grid .row.-pd-sp-8 {
    margin-left: -8px !important;
    margin-right: -8px !important;
    margin-top: -16px !important;
  }
  .c-grid .row.-pd-sp-8 > [class^="col"] {
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-top: 16px !important;
  }
  .c-grid .row.-mb-sp-8 {
    margin-top: -8px !important;
  }
  .c-grid .row.-mb-sp-8 > [class^="col"] {
    margin-top: 8px !important;
  }
  .c-grid .row.-pd-sp-9 {
    margin-left: -9px !important;
    margin-right: -9px !important;
    margin-top: -18px !important;
  }
  .c-grid .row.-pd-sp-9 > [class^="col"] {
    padding-left: 9px !important;
    padding-right: 9px !important;
    margin-top: 18px !important;
  }
  .c-grid .row.-mb-sp-9 {
    margin-top: -9px !important;
  }
  .c-grid .row.-mb-sp-9 > [class^="col"] {
    margin-top: 9px !important;
  }
  .c-grid .row.-pd-sp-10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
    margin-top: -20px !important;
  }
  .c-grid .row.-pd-sp-10 > [class^="col"] {
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-top: 20px !important;
  }
  .c-grid .row.-mb-sp-10 {
    margin-top: -10px !important;
  }
  .c-grid .row.-mb-sp-10 > [class^="col"] {
    margin-top: 10px !important;
  }
  .c-grid .row.-pd-sp-11 {
    margin-left: -11px !important;
    margin-right: -11px !important;
    margin-top: -22px !important;
  }
  .c-grid .row.-pd-sp-11 > [class^="col"] {
    padding-left: 11px !important;
    padding-right: 11px !important;
    margin-top: 22px !important;
  }
  .c-grid .row.-mb-sp-11 {
    margin-top: -11px !important;
  }
  .c-grid .row.-mb-sp-11 > [class^="col"] {
    margin-top: 11px !important;
  }
  .c-grid .row.-pd-sp-12 {
    margin-left: -12px !important;
    margin-right: -12px !important;
    margin-top: -24px !important;
  }
  .c-grid .row.-pd-sp-12 > [class^="col"] {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-top: 24px !important;
  }
  .c-grid .row.-mb-sp-12 {
    margin-top: -12px !important;
  }
  .c-grid .row.-mb-sp-12 > [class^="col"] {
    margin-top: 12px !important;
  }
  .c-grid .row.-pd-sp-13 {
    margin-left: -13px !important;
    margin-right: -13px !important;
    margin-top: -26px !important;
  }
  .c-grid .row.-pd-sp-13 > [class^="col"] {
    padding-left: 13px !important;
    padding-right: 13px !important;
    margin-top: 26px !important;
  }
  .c-grid .row.-mb-sp-13 {
    margin-top: -13px !important;
  }
  .c-grid .row.-mb-sp-13 > [class^="col"] {
    margin-top: 13px !important;
  }
  .c-grid .row.-pd-sp-14 {
    margin-left: -14px !important;
    margin-right: -14px !important;
    margin-top: -28px !important;
  }
  .c-grid .row.-pd-sp-14 > [class^="col"] {
    padding-left: 14px !important;
    padding-right: 14px !important;
    margin-top: 28px !important;
  }
  .c-grid .row.-mb-sp-14 {
    margin-top: -14px !important;
  }
  .c-grid .row.-mb-sp-14 > [class^="col"] {
    margin-top: 14px !important;
  }
  .c-grid .row.-pd-sp-15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
    margin-top: -30px !important;
  }
  .c-grid .row.-pd-sp-15 > [class^="col"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin-top: 30px !important;
  }
  .c-grid .row.-mb-sp-15 {
    margin-top: -15px !important;
  }
  .c-grid .row.-mb-sp-15 > [class^="col"] {
    margin-top: 15px !important;
  }
  .c-grid .row.-pd-sp-16 {
    margin-left: -16px !important;
    margin-right: -16px !important;
    margin-top: -32px !important;
  }
  .c-grid .row.-pd-sp-16 > [class^="col"] {
    padding-left: 16px !important;
    padding-right: 16px !important;
    margin-top: 32px !important;
  }
  .c-grid .row.-mb-sp-16 {
    margin-top: -16px !important;
  }
  .c-grid .row.-mb-sp-16 > [class^="col"] {
    margin-top: 16px !important;
  }
  .c-grid .row.-pd-sp-17 {
    margin-left: -17px !important;
    margin-right: -17px !important;
    margin-top: -34px !important;
  }
  .c-grid .row.-pd-sp-17 > [class^="col"] {
    padding-left: 17px !important;
    padding-right: 17px !important;
    margin-top: 34px !important;
  }
  .c-grid .row.-mb-sp-17 {
    margin-top: -17px !important;
  }
  .c-grid .row.-mb-sp-17 > [class^="col"] {
    margin-top: 17px !important;
  }
  .c-grid .row.-pd-sp-18 {
    margin-left: -18px !important;
    margin-right: -18px !important;
    margin-top: -36px !important;
  }
  .c-grid .row.-pd-sp-18 > [class^="col"] {
    padding-left: 18px !important;
    padding-right: 18px !important;
    margin-top: 36px !important;
  }
  .c-grid .row.-mb-sp-18 {
    margin-top: -18px !important;
  }
  .c-grid .row.-mb-sp-18 > [class^="col"] {
    margin-top: 18px !important;
  }
  .c-grid .row.-pd-sp-19 {
    margin-left: -19px !important;
    margin-right: -19px !important;
    margin-top: -38px !important;
  }
  .c-grid .row.-pd-sp-19 > [class^="col"] {
    padding-left: 19px !important;
    padding-right: 19px !important;
    margin-top: 38px !important;
  }
  .c-grid .row.-mb-sp-19 {
    margin-top: -19px !important;
  }
  .c-grid .row.-mb-sp-19 > [class^="col"] {
    margin-top: 19px !important;
  }
  .c-grid .row.-pd-sp-20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
    margin-top: -40px !important;
  }
  .c-grid .row.-pd-sp-20 > [class^="col"] {
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-top: 40px !important;
  }
  .c-grid .row.-mb-sp-20 {
    margin-top: -20px !important;
  }
  .c-grid .row.-mb-sp-20 > [class^="col"] {
    margin-top: 20px !important;
  }
  .c-grid .row.-pd-sp-21 {
    margin-left: -21px !important;
    margin-right: -21px !important;
    margin-top: -42px !important;
  }
  .c-grid .row.-pd-sp-21 > [class^="col"] {
    padding-left: 21px !important;
    padding-right: 21px !important;
    margin-top: 42px !important;
  }
  .c-grid .row.-mb-sp-21 {
    margin-top: -21px !important;
  }
  .c-grid .row.-mb-sp-21 > [class^="col"] {
    margin-top: 21px !important;
  }
  .c-grid .row.-pd-sp-22 {
    margin-left: -22px !important;
    margin-right: -22px !important;
    margin-top: -44px !important;
  }
  .c-grid .row.-pd-sp-22 > [class^="col"] {
    padding-left: 22px !important;
    padding-right: 22px !important;
    margin-top: 44px !important;
  }
  .c-grid .row.-mb-sp-22 {
    margin-top: -22px !important;
  }
  .c-grid .row.-mb-sp-22 > [class^="col"] {
    margin-top: 22px !important;
  }
  .c-grid .row.-pd-sp-23 {
    margin-left: -23px !important;
    margin-right: -23px !important;
    margin-top: -46px !important;
  }
  .c-grid .row.-pd-sp-23 > [class^="col"] {
    padding-left: 23px !important;
    padding-right: 23px !important;
    margin-top: 46px !important;
  }
  .c-grid .row.-mb-sp-23 {
    margin-top: -23px !important;
  }
  .c-grid .row.-mb-sp-23 > [class^="col"] {
    margin-top: 23px !important;
  }
  .c-grid .row.-pd-sp-24 {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: -48px !important;
  }
  .c-grid .row.-pd-sp-24 > [class^="col"] {
    padding-left: 24px !important;
    padding-right: 24px !important;
    margin-top: 48px !important;
  }
  .c-grid .row.-mb-sp-24 {
    margin-top: -24px !important;
  }
  .c-grid .row.-mb-sp-24 > [class^="col"] {
    margin-top: 24px !important;
  }
  .c-grid .row.-pd-sp-25 {
    margin-left: -25px !important;
    margin-right: -25px !important;
    margin-top: -50px !important;
  }
  .c-grid .row.-pd-sp-25 > [class^="col"] {
    padding-left: 25px !important;
    padding-right: 25px !important;
    margin-top: 50px !important;
  }
  .c-grid .row.-mb-sp-25 {
    margin-top: -25px !important;
  }
  .c-grid .row.-mb-sp-25 > [class^="col"] {
    margin-top: 25px !important;
  }
  .c-grid .row.-pd-sp-26 {
    margin-left: -26px !important;
    margin-right: -26px !important;
    margin-top: -52px !important;
  }
  .c-grid .row.-pd-sp-26 > [class^="col"] {
    padding-left: 26px !important;
    padding-right: 26px !important;
    margin-top: 52px !important;
  }
  .c-grid .row.-mb-sp-26 {
    margin-top: -26px !important;
  }
  .c-grid .row.-mb-sp-26 > [class^="col"] {
    margin-top: 26px !important;
  }
  .c-grid .row.-pd-sp-27 {
    margin-left: -27px !important;
    margin-right: -27px !important;
    margin-top: -54px !important;
  }
  .c-grid .row.-pd-sp-27 > [class^="col"] {
    padding-left: 27px !important;
    padding-right: 27px !important;
    margin-top: 54px !important;
  }
  .c-grid .row.-mb-sp-27 {
    margin-top: -27px !important;
  }
  .c-grid .row.-mb-sp-27 > [class^="col"] {
    margin-top: 27px !important;
  }
  .c-grid .row.-pd-sp-28 {
    margin-left: -28px !important;
    margin-right: -28px !important;
    margin-top: -56px !important;
  }
  .c-grid .row.-pd-sp-28 > [class^="col"] {
    padding-left: 28px !important;
    padding-right: 28px !important;
    margin-top: 56px !important;
  }
  .c-grid .row.-mb-sp-28 {
    margin-top: -28px !important;
  }
  .c-grid .row.-mb-sp-28 > [class^="col"] {
    margin-top: 28px !important;
  }
  .c-grid .row.-pd-sp-29 {
    margin-left: -29px !important;
    margin-right: -29px !important;
    margin-top: -58px !important;
  }
  .c-grid .row.-pd-sp-29 > [class^="col"] {
    padding-left: 29px !important;
    padding-right: 29px !important;
    margin-top: 58px !important;
  }
  .c-grid .row.-mb-sp-29 {
    margin-top: -29px !important;
  }
  .c-grid .row.-mb-sp-29 > [class^="col"] {
    margin-top: 29px !important;
  }
  .c-grid .row.-pd-sp-30 {
    margin-left: -30px !important;
    margin-right: -30px !important;
    margin-top: -60px !important;
  }
  .c-grid .row.-pd-sp-30 > [class^="col"] {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 60px !important;
  }
  .c-grid .row.-mb-sp-30 {
    margin-top: -30px !important;
  }
  .c-grid .row.-mb-sp-30 > [class^="col"] {
    margin-top: 30px !important;
  }
  .c-grid .row.-pd-sp-31 {
    margin-left: -31px !important;
    margin-right: -31px !important;
    margin-top: -62px !important;
  }
  .c-grid .row.-pd-sp-31 > [class^="col"] {
    padding-left: 31px !important;
    padding-right: 31px !important;
    margin-top: 62px !important;
  }
  .c-grid .row.-mb-sp-31 {
    margin-top: -31px !important;
  }
  .c-grid .row.-mb-sp-31 > [class^="col"] {
    margin-top: 31px !important;
  }
  .c-grid .row.-pd-sp-32 {
    margin-left: -32px !important;
    margin-right: -32px !important;
    margin-top: -64px !important;
  }
  .c-grid .row.-pd-sp-32 > [class^="col"] {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-top: 64px !important;
  }
  .c-grid .row.-mb-sp-32 {
    margin-top: -32px !important;
  }
  .c-grid .row.-mb-sp-32 > [class^="col"] {
    margin-top: 32px !important;
  }
  .c-grid .row.-pd-sp-33 {
    margin-left: -33px !important;
    margin-right: -33px !important;
    margin-top: -66px !important;
  }
  .c-grid .row.-pd-sp-33 > [class^="col"] {
    padding-left: 33px !important;
    padding-right: 33px !important;
    margin-top: 66px !important;
  }
  .c-grid .row.-mb-sp-33 {
    margin-top: -33px !important;
  }
  .c-grid .row.-mb-sp-33 > [class^="col"] {
    margin-top: 33px !important;
  }
  .c-grid .row.-pd-sp-34 {
    margin-left: -34px !important;
    margin-right: -34px !important;
    margin-top: -68px !important;
  }
  .c-grid .row.-pd-sp-34 > [class^="col"] {
    padding-left: 34px !important;
    padding-right: 34px !important;
    margin-top: 68px !important;
  }
  .c-grid .row.-mb-sp-34 {
    margin-top: -34px !important;
  }
  .c-grid .row.-mb-sp-34 > [class^="col"] {
    margin-top: 34px !important;
  }
  .c-grid .row.-pd-sp-35 {
    margin-left: -35px !important;
    margin-right: -35px !important;
    margin-top: -70px !important;
  }
  .c-grid .row.-pd-sp-35 > [class^="col"] {
    padding-left: 35px !important;
    padding-right: 35px !important;
    margin-top: 70px !important;
  }
  .c-grid .row.-mb-sp-35 {
    margin-top: -35px !important;
  }
  .c-grid .row.-mb-sp-35 > [class^="col"] {
    margin-top: 35px !important;
  }
  .c-grid .row.-pd-sp-36 {
    margin-left: -36px !important;
    margin-right: -36px !important;
    margin-top: -72px !important;
  }
  .c-grid .row.-pd-sp-36 > [class^="col"] {
    padding-left: 36px !important;
    padding-right: 36px !important;
    margin-top: 72px !important;
  }
  .c-grid .row.-mb-sp-36 {
    margin-top: -36px !important;
  }
  .c-grid .row.-mb-sp-36 > [class^="col"] {
    margin-top: 36px !important;
  }
  .c-grid .row.-pd-sp-37 {
    margin-left: -37px !important;
    margin-right: -37px !important;
    margin-top: -74px !important;
  }
  .c-grid .row.-pd-sp-37 > [class^="col"] {
    padding-left: 37px !important;
    padding-right: 37px !important;
    margin-top: 74px !important;
  }
  .c-grid .row.-mb-sp-37 {
    margin-top: -37px !important;
  }
  .c-grid .row.-mb-sp-37 > [class^="col"] {
    margin-top: 37px !important;
  }
  .c-grid .row.-pd-sp-38 {
    margin-left: -38px !important;
    margin-right: -38px !important;
    margin-top: -76px !important;
  }
  .c-grid .row.-pd-sp-38 > [class^="col"] {
    padding-left: 38px !important;
    padding-right: 38px !important;
    margin-top: 76px !important;
  }
  .c-grid .row.-mb-sp-38 {
    margin-top: -38px !important;
  }
  .c-grid .row.-mb-sp-38 > [class^="col"] {
    margin-top: 38px !important;
  }
  .c-grid .row.-pd-sp-39 {
    margin-left: -39px !important;
    margin-right: -39px !important;
    margin-top: -78px !important;
  }
  .c-grid .row.-pd-sp-39 > [class^="col"] {
    padding-left: 39px !important;
    padding-right: 39px !important;
    margin-top: 78px !important;
  }
  .c-grid .row.-mb-sp-39 {
    margin-top: -39px !important;
  }
  .c-grid .row.-mb-sp-39 > [class^="col"] {
    margin-top: 39px !important;
  }
  .c-grid .row.-pd-sp-40 {
    margin-left: -40px !important;
    margin-right: -40px !important;
    margin-top: -80px !important;
  }
  .c-grid .row.-pd-sp-40 > [class^="col"] {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-top: 80px !important;
  }
  .c-grid .row.-mb-sp-40 {
    margin-top: -40px !important;
  }
  .c-grid .row.-mb-sp-40 > [class^="col"] {
    margin-top: 40px !important;
  }
  .c-grid .row.-pd-sp-41 {
    margin-left: -41px !important;
    margin-right: -41px !important;
    margin-top: -82px !important;
  }
  .c-grid .row.-pd-sp-41 > [class^="col"] {
    padding-left: 41px !important;
    padding-right: 41px !important;
    margin-top: 82px !important;
  }
  .c-grid .row.-mb-sp-41 {
    margin-top: -41px !important;
  }
  .c-grid .row.-mb-sp-41 > [class^="col"] {
    margin-top: 41px !important;
  }
  .c-grid .row.-pd-sp-42 {
    margin-left: -42px !important;
    margin-right: -42px !important;
    margin-top: -84px !important;
  }
  .c-grid .row.-pd-sp-42 > [class^="col"] {
    padding-left: 42px !important;
    padding-right: 42px !important;
    margin-top: 84px !important;
  }
  .c-grid .row.-mb-sp-42 {
    margin-top: -42px !important;
  }
  .c-grid .row.-mb-sp-42 > [class^="col"] {
    margin-top: 42px !important;
  }
  .c-grid .row.-pd-sp-43 {
    margin-left: -43px !important;
    margin-right: -43px !important;
    margin-top: -86px !important;
  }
  .c-grid .row.-pd-sp-43 > [class^="col"] {
    padding-left: 43px !important;
    padding-right: 43px !important;
    margin-top: 86px !important;
  }
  .c-grid .row.-mb-sp-43 {
    margin-top: -43px !important;
  }
  .c-grid .row.-mb-sp-43 > [class^="col"] {
    margin-top: 43px !important;
  }
  .c-grid .row.-pd-sp-44 {
    margin-left: -44px !important;
    margin-right: -44px !important;
    margin-top: -88px !important;
  }
  .c-grid .row.-pd-sp-44 > [class^="col"] {
    padding-left: 44px !important;
    padding-right: 44px !important;
    margin-top: 88px !important;
  }
  .c-grid .row.-mb-sp-44 {
    margin-top: -44px !important;
  }
  .c-grid .row.-mb-sp-44 > [class^="col"] {
    margin-top: 44px !important;
  }
  .c-grid .row.-pd-sp-45 {
    margin-left: -45px !important;
    margin-right: -45px !important;
    margin-top: -90px !important;
  }
  .c-grid .row.-pd-sp-45 > [class^="col"] {
    padding-left: 45px !important;
    padding-right: 45px !important;
    margin-top: 90px !important;
  }
  .c-grid .row.-mb-sp-45 {
    margin-top: -45px !important;
  }
  .c-grid .row.-mb-sp-45 > [class^="col"] {
    margin-top: 45px !important;
  }
  .c-grid .row.-pd-sp-46 {
    margin-left: -46px !important;
    margin-right: -46px !important;
    margin-top: -92px !important;
  }
  .c-grid .row.-pd-sp-46 > [class^="col"] {
    padding-left: 46px !important;
    padding-right: 46px !important;
    margin-top: 92px !important;
  }
  .c-grid .row.-mb-sp-46 {
    margin-top: -46px !important;
  }
  .c-grid .row.-mb-sp-46 > [class^="col"] {
    margin-top: 46px !important;
  }
  .c-grid .row.-pd-sp-47 {
    margin-left: -47px !important;
    margin-right: -47px !important;
    margin-top: -94px !important;
  }
  .c-grid .row.-pd-sp-47 > [class^="col"] {
    padding-left: 47px !important;
    padding-right: 47px !important;
    margin-top: 94px !important;
  }
  .c-grid .row.-mb-sp-47 {
    margin-top: -47px !important;
  }
  .c-grid .row.-mb-sp-47 > [class^="col"] {
    margin-top: 47px !important;
  }
  .c-grid .row.-pd-sp-48 {
    margin-left: -48px !important;
    margin-right: -48px !important;
    margin-top: -96px !important;
  }
  .c-grid .row.-pd-sp-48 > [class^="col"] {
    padding-left: 48px !important;
    padding-right: 48px !important;
    margin-top: 96px !important;
  }
  .c-grid .row.-mb-sp-48 {
    margin-top: -48px !important;
  }
  .c-grid .row.-mb-sp-48 > [class^="col"] {
    margin-top: 48px !important;
  }
  .c-grid .row.-pd-sp-49 {
    margin-left: -49px !important;
    margin-right: -49px !important;
    margin-top: -98px !important;
  }
  .c-grid .row.-pd-sp-49 > [class^="col"] {
    padding-left: 49px !important;
    padding-right: 49px !important;
    margin-top: 98px !important;
  }
  .c-grid .row.-mb-sp-49 {
    margin-top: -49px !important;
  }
  .c-grid .row.-mb-sp-49 > [class^="col"] {
    margin-top: 49px !important;
  }
  .c-grid .row.-pd-sp-50 {
    margin-left: -50px !important;
    margin-right: -50px !important;
    margin-top: -100px !important;
  }
  .c-grid .row.-pd-sp-50 > [class^="col"] {
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-top: 100px !important;
  }
  .c-grid .row.-mb-sp-50 {
    margin-top: -50px !important;
  }
  .c-grid .row.-mb-sp-50 > [class^="col"] {
    margin-top: 50px !important;
  }
  .c-grid .row.-pd-sp-51 {
    margin-left: -51px !important;
    margin-right: -51px !important;
    margin-top: -102px !important;
  }
  .c-grid .row.-pd-sp-51 > [class^="col"] {
    padding-left: 51px !important;
    padding-right: 51px !important;
    margin-top: 102px !important;
  }
  .c-grid .row.-mb-sp-51 {
    margin-top: -51px !important;
  }
  .c-grid .row.-mb-sp-51 > [class^="col"] {
    margin-top: 51px !important;
  }
  .c-grid .row.-pd-sp-52 {
    margin-left: -52px !important;
    margin-right: -52px !important;
    margin-top: -104px !important;
  }
  .c-grid .row.-pd-sp-52 > [class^="col"] {
    padding-left: 52px !important;
    padding-right: 52px !important;
    margin-top: 104px !important;
  }
  .c-grid .row.-mb-sp-52 {
    margin-top: -52px !important;
  }
  .c-grid .row.-mb-sp-52 > [class^="col"] {
    margin-top: 52px !important;
  }
  .c-grid .row.-pd-sp-53 {
    margin-left: -53px !important;
    margin-right: -53px !important;
    margin-top: -106px !important;
  }
  .c-grid .row.-pd-sp-53 > [class^="col"] {
    padding-left: 53px !important;
    padding-right: 53px !important;
    margin-top: 106px !important;
  }
  .c-grid .row.-mb-sp-53 {
    margin-top: -53px !important;
  }
  .c-grid .row.-mb-sp-53 > [class^="col"] {
    margin-top: 53px !important;
  }
  .c-grid .row.-pd-sp-54 {
    margin-left: -54px !important;
    margin-right: -54px !important;
    margin-top: -108px !important;
  }
  .c-grid .row.-pd-sp-54 > [class^="col"] {
    padding-left: 54px !important;
    padding-right: 54px !important;
    margin-top: 108px !important;
  }
  .c-grid .row.-mb-sp-54 {
    margin-top: -54px !important;
  }
  .c-grid .row.-mb-sp-54 > [class^="col"] {
    margin-top: 54px !important;
  }
  .c-grid .row.-pd-sp-55 {
    margin-left: -55px !important;
    margin-right: -55px !important;
    margin-top: -110px !important;
  }
  .c-grid .row.-pd-sp-55 > [class^="col"] {
    padding-left: 55px !important;
    padding-right: 55px !important;
    margin-top: 110px !important;
  }
  .c-grid .row.-mb-sp-55 {
    margin-top: -55px !important;
  }
  .c-grid .row.-mb-sp-55 > [class^="col"] {
    margin-top: 55px !important;
  }
  .c-grid .row.-pd-sp-56 {
    margin-left: -56px !important;
    margin-right: -56px !important;
    margin-top: -112px !important;
  }
  .c-grid .row.-pd-sp-56 > [class^="col"] {
    padding-left: 56px !important;
    padding-right: 56px !important;
    margin-top: 112px !important;
  }
  .c-grid .row.-mb-sp-56 {
    margin-top: -56px !important;
  }
  .c-grid .row.-mb-sp-56 > [class^="col"] {
    margin-top: 56px !important;
  }
  .c-grid .row.-pd-sp-57 {
    margin-left: -57px !important;
    margin-right: -57px !important;
    margin-top: -114px !important;
  }
  .c-grid .row.-pd-sp-57 > [class^="col"] {
    padding-left: 57px !important;
    padding-right: 57px !important;
    margin-top: 114px !important;
  }
  .c-grid .row.-mb-sp-57 {
    margin-top: -57px !important;
  }
  .c-grid .row.-mb-sp-57 > [class^="col"] {
    margin-top: 57px !important;
  }
  .c-grid .row.-pd-sp-58 {
    margin-left: -58px !important;
    margin-right: -58px !important;
    margin-top: -116px !important;
  }
  .c-grid .row.-pd-sp-58 > [class^="col"] {
    padding-left: 58px !important;
    padding-right: 58px !important;
    margin-top: 116px !important;
  }
  .c-grid .row.-mb-sp-58 {
    margin-top: -58px !important;
  }
  .c-grid .row.-mb-sp-58 > [class^="col"] {
    margin-top: 58px !important;
  }
  .c-grid .row.-pd-sp-59 {
    margin-left: -59px !important;
    margin-right: -59px !important;
    margin-top: -118px !important;
  }
  .c-grid .row.-pd-sp-59 > [class^="col"] {
    padding-left: 59px !important;
    padding-right: 59px !important;
    margin-top: 118px !important;
  }
  .c-grid .row.-mb-sp-59 {
    margin-top: -59px !important;
  }
  .c-grid .row.-mb-sp-59 > [class^="col"] {
    margin-top: 59px !important;
  }
  .c-grid .row.-pd-sp-60 {
    margin-left: -60px !important;
    margin-right: -60px !important;
    margin-top: -120px !important;
  }
  .c-grid .row.-pd-sp-60 > [class^="col"] {
    padding-left: 60px !important;
    padding-right: 60px !important;
    margin-top: 120px !important;
  }
  .c-grid .row.-mb-sp-60 {
    margin-top: -60px !important;
  }
  .c-grid .row.-mb-sp-60 > [class^="col"] {
    margin-top: 60px !important;
  }
}

.c-grid .col-12 {
  width: 100%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-11 {
  width: 91.66667%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-10 {
  width: 83.33333%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-9 {
  width: 75%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-8 {
  width: 66.66667%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-7 {
  width: 58.33333%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-6 {
  width: 50%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-5 {
  width: 41.66667%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-4 {
  width: 33.33333%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-3 {
  width: 25%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-2 {
  width: 16.66667%;
  padding: 0 15px;
  margin-top: 25px;
}

.c-grid .col-1 {
  width: 8.33333%;
  padding: 0 15px;
  margin-top: 25px;
}

@media (min-width: 813px) {
  .c-grid .col-pc-12 {
    width: 100%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-11 {
    width: 91.66667%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-10 {
    width: 83.33333%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-9 {
    width: 75%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-8 {
    width: 66.66667%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-7 {
    width: 58.33333%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-6 {
    width: 50%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-5 {
    width: 41.66667%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-4 {
    width: 33.33333%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-3 {
    width: 25%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-2 {
    width: 16.66667%;
    padding: 0 15px;
    margin-top: 25px;
  }
  .c-grid .col-pc-1 {
    width: 8.33333%;
    padding: 0 15px;
    margin-top: 25px;
  }
}

@media (max-width: 812px) {
  .c-grid .col-sp-12 {
    width: 100%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-11 {
    width: 91.66667%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-10 {
    width: 83.33333%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-9 {
    width: 75%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-8 {
    width: 66.66667%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-7 {
    width: 58.33333%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-6 {
    width: 50%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-5 {
    width: 41.66667%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-4 {
    width: 33.33333%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-3 {
    width: 25%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-2 {
    width: 16.66667%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
  .c-grid .col-sp-1 {
    width: 8.33333%;
    padding: 0 7.5px;
    margin-top: 20px;
  }
}

/* ==========================================================
  header
========================================================== */
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-width: 1260px;
  background: url("/images/common/bg_head_asanoha.png") #fbf9f2 fixed;
}

@media (max-width: 812px) {
  .header {
    min-width: 0;
  }
}

.header-in {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  height: 90px;
  margin: 0 auto;
  /*    transition: height .6s;*/
}

@media (min-width: 813px) {
  .home .header-in {
    height: 90px;
  }
}

@media (max-width: 812px) {
  .header-in {
    width: auto;
    height: 60px;
  }
}

.header-logo {
  opacity: 1 !important;
}

.header-logo > img {
  display: block;
  width: 280px;
  height: auto;
}

@media (max-width: 812px) {
  .header-logo > img {
    width: 214px;
    height: auto;
  }
}

.header-contact {
  display: block;
  color: #214268;
  font-size: 2.2rem;
  line-height: 1.2;
  text-decoration: none;
}

@media (max-width: 812px) {
  .header-contact {
    margin-right: 7px;
  }
}

.header-contact .txt-number {
  padding-left: 14px;
}

@media (max-width: 812px) {
  .header-contact .txt-number {
    display: none;
  }
}

@media (max-width: 812px) {
  .header-contact .icon {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    background-color: #214268;
    line-height: 1;
  }
}

.header-contact .icon::before {
  content: '\f87b';
}

@media (max-width: 812px) {
  .header-contact .icon::before {
    color: #fff;
    font-size: 2rem;
    content: '\f879';
  }
}

.header-search {
  font-size: 16px;
}

@media (max-width: 812px) {
  .header-search {
    display: none;
  }
}

.header-search-in {
  position: relative;
  display: flex;
  width: 262px;
  padding-right: 46px;
  background-color: #214268;
}

.header-search .input-search {
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  border: none;
  background: none;
  font-size: 1.6rem;
  color: #fff;
}

.header-search .btn-search {
  position: absolute;
  top: 0;
  right: 0;
  width: 46px;
  height: 40px;
  color: #fff;
  font-size: 2rem;
  text-align: center;
}

.header-btn-menu {
  position: relative;
  display: block;
  width: 26px;
  height: 20px;
  margin-left: 15px;
}

@media (min-width: 813px) {
  .header-btn-menu {
    display: none;
  }
}

.header-btn-menu span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #214268;
  transition: 0.3s ease-in-out;
}

.header-btn-menu span:nth-child(2) {
  top: 9px;
  opacity: 0;
}

.header-btn-menu span:nth-child(3) {
  top: 9px;
}

.header-btn-menu span:nth-child(4) {
  top: 18px;
}

.header-btn-menu.is-open > span:nth-child(1) {
  width: 0%;
  left: 50%;
}

.header-btn-menu.is-open > span:nth-child(2) {
  opacity: 1;
  transform: rotate(45deg);
}

.header-btn-menu.is-open > span:nth-child(3) {
  transform: rotate(-45deg);
}

.header-btn-menu.is-open > span:nth-child(4) {
  left: 50%;
  width: 0;
}

/* ==========================================================
  heading-01
========================================================== */
.c-heading-01 {
  position: relative;
  padding-bottom: 1em;
}

@media (max-width: 812px) {
  .c-heading-01 {
    margin: 0 0 0 5px;
  }
}

.c-heading-01 i {
  font-size: 2.4rem;
  color: #877166;
}

@media (max-width: 812px) {
  .c-heading-01 i {
    font-size: 2.5rem;
  }
}

.c-heading-01 span {
  padding-left: 17px;
  font-size: 2.6rem;
  font-weight: normal;
  letter-spacing: 0em;
}

@media (max-width: 812px) {
  .c-heading-01 span {
    font-size: 2.4rem;
  }
}

.c-heading-01.sm {
  display: flex;
  align-items: normal;
}

.c-heading-01.sm i {
  font-size: 2.7rem;
}

@media (max-width: 812px) {
  .c-heading-01.sm i {
    font-size: 2.3rem;
  }
}

.c-heading-01.sm span {
  padding-left: 10px;
  font-size: 1.6rem;
}

@media (max-width: 812px) {
  .c-heading-01.sm span {
    font-size: 1.8rem;
  }
}

.c-heading-01.black i {
  color: #214268;
}

.c-heading-01.black span {
  color: #000;
}

/* ==========================================================
  heading-02
========================================================== */
.ttl-madical {
  font-weight: normal;
  font-size: 1.8rem;
}

@media (max-width: 812px) {
  .ttl-madical {
    font-size: 2rem;
  }
}

.ttl-madical span {
  font-size: 1.6rem;
}

/* ==========================================================
  heading-02
========================================================== */
.c-heading-02 {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: normal;
}

@media (max-width: 812px) {
  .c-heading-02 {
    margin-bottom: 8px;
  }
}

/* ==========================================================
  hero
========================================================== */
.hero-cmn-01 img {
  display: block;
  width: 100%;
}

/* ==========================================================
  link
========================================================== */
/* c-link-cmn
---------------------------------------------------------- */
.c-link-cmn {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #2C598C;
  color: #fff;
  border-radius: 12px;
  height: 100px;
  padding: 0 20px;
  font-size: 1em;
  text-decoration: none;
}

@media (max-width: 812px) {
  .c-link-cmn {
    padding: 0 15px;
    height: 80px;
    border-radius: 12px;
  }
}

.c-link-cmn i {
  font-size: 3.6rem;
}

@media (max-width: 812px) {
  .c-link-cmn i {
    font-size: 4rem;
  }
}

.c-link-cmn span {
  padding-left: 30px;
}

@media (max-width: 812px) {
  .c-link-cmn span {
    padding-left: 20px;
  }
}

.c-link-cmn.center {
  justify-content: center;
}

.c-link-cmn.center i {
  padding-right: 20px;
}

.c-link-cmn.center span {
  display: flex;
  align-items: center;
  padding-left: 0;
}

@media (max-width: 812px) {
  .c-link-cmn.center span {
    padding-left: 0;
  }
}

/* ==========================================================
  list
========================================================== */
/* c-list-flow-01
---------------------------------------------------------- */
.c-list-cmn-01 {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 -20px;
}

@media (max-width: 812px) {
  .c-list-cmn-01 {
    flex-wrap: wrap;
    margin: 0 -6px -20px;
    width: inherit;
  }
}

.c-list-cmn-01 li {
  position: relative;
  padding: 0;
  margin-bottom: 10px;
  width: 14.28%;
}

@media (max-width: 812px) {
  .c-list-cmn-01 li {
    margin-bottom: 15px;
    padding: 0 6px;
    width: 50%;
  }
}

.c-list-cmn-01 li a {
  display: block;
  text-decoration: none;
}

.wrap-list-cmn {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: #F6F3E2;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}

.wrap-list-cmn > .c-list-cmn-01 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

.is-open .wrap-list-cmn {
  display: block;
  transition: all 0.3s ease;
  opacity: 1;
}

@media (max-width: 812px) {
  .is-open .wrap-list-cmn {
    display: none;
  }
}

.c-list-cmn-arrow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
  padding-left: 32px;
}

@media (max-width: 812px) {
  .c-list-cmn-arrow {
    padding-left: 20px;
  }
}

.c-list-cmn-arrow li {
  display: flex;
  flex-wrap: wrap;
  width: 33.33%;
  margin-bottom: 16px;
}

.c-list-cmn-arrow li:nth-child(3n+1) {
  width: 34.2%;
}

@media (max-width: 812px) {
  .c-list-cmn-arrow li:nth-child(3n+1) {
    width: 100%;
  }
}

.c-list-cmn-arrow li:nth-child(3n+2) {
  width: 34.2%;
}

@media (max-width: 812px) {
  .c-list-cmn-arrow li:nth-child(3n+2) {
    width: 100%;
  }
}

.c-list-cmn-arrow li:nth-child(3n+3) {
  width: 30%;
}

@media (max-width: 812px) {
  .c-list-cmn-arrow li:nth-child(3n+3) {
    width: 100%;
  }
}

@media (max-width: 812px) {
  .c-list-cmn-arrow li {
    margin-bottom: 14px;
    width: 100%;
  }
}

.c-list-cmn-arrow li a i {
  display: inline-block;
}

.c-list-cmn-arrow li a span {
  padding-left: 15px;
}

.c-list-cmn-btn {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -20px;
}

@media (max-width: 812px) {
  .c-list-cmn-btn {
    padding: 0 5px;
    margin-left: -5px;
    margin-right: -5px;
  }
}

.c-list-cmn-btn li {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 20px;
}

@media (max-width: 812px) {
  .c-list-cmn-btn li {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

.c-list-cmn-note li {
  margin-left: 10px;
  font-size: 1.4rem;
}

/* c-list-news
---------------------------------------------------------- */
.c-list-news {
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
}

.c-list-news .archives {
  padding: 12px 20px;
  text-align: right;
}

.c-list-news .archives a {
  background: #214268;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: inherit;
  line-height: 1.42875 !important;
  padding: 6px 10px;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  vertical-align: middle;
}

.c-list-news .archives a span {
  display: inline-block;
  padding-right: 8px;
}

.c-list-news .item {
  border-top: 1px dashed #f2f0eb;
}

.c-list-news .item:first-child {
  border-top: none;
}

.c-list-news .item:first-child .c-card-news {
  padding-top: 32px;
}

@media (max-width: 812px) {
  .c-list-news .item:first-child .c-card-news {
    padding-top: 18px;
  }
}

.c-list-news .item:last-child .c-card-news {
  padding-bottom: 32px;
}

@media (max-width: 812px) {
  .c-list-news .item:last-child .c-card-news {
    padding-bottom: 18px;
  }
}

/* c-list-time
---------------------------------------------------------- */
.c-list-time * {
  font-size: 1.2rem;
}

.c-list-time .item {
  display: flex;
  align-items: center;
  margin-top: 7px;
}

.c-list-time .item:first-child {
  margin-top: 0;
}

.c-list-time .tag {
  width: 114px;
  flex: 1 0 auto;
  font-size: 1.2rem;
}

@media (max-width: 812px) {
  .c-list-time .tag {
    width: 70px;
    font-size: 1.2rem;
  }
}

.c-list-time .text {
  width: 100%;
  letter-spacing: 0.1rem;
  padding-left: 15px;
}

@media (max-width: 812px) {
  .c-list-time .text {
    font-size: 1.6rem;
    padding-left: 8px;
  }
}

/* ==========================================================
  nav-global
========================================================== */
.nav-global {
  position: fixed;
  display: block;
  width: 100%;
  height: 0;
  top: 60px;
  left: 0;
  cursor: default;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  backface-visibility: hidden;
  z-index: 3;
  overflow-y: scroll;
}

.nav-global.is-open {
  opacity: 1;
  height: auto;
  visibility: visible;
  transition: all 0.5s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.nav-global-list {
  width: 100%;
  overflow: hidden;
}

.nav-global-menu.showsub .nav-global-link, .nav-global-menu.showsub .nav-global-link-sub {
  color: #fff;
  background-color: #2a5382;
}

.nav-global-menu.showsub .nav-global-link::before, .nav-global-menu.showsub .nav-global-link-sub::before {
  border-width: 5px 5px 0 5px;
  border-color: #fff transparent transparent transparent;
}

.nav-global-sub-menu {
  display: none;
}

.nav-global-sub-menu .nav-global-link, .nav-global-sub-menu .nav-global-link-sub {
  font-size: 1.6rem;
  padding: 10px 20px 12px 55px;
}

.nav-global-sub-menu .nav-global-link::before, .nav-global-sub-menu .nav-global-link-sub::before {
  left: 35px;
}

.nav-global-link, .nav-global-link-sub {
  width: 100%;
  display: block;
  position: relative;
  font-size: 1.8rem;
  color: #d2d9e1;
  background: #214268;
  border-top: 1px solid #2a5382;
  padding: 18px 20px 15px 38px;
}

.nav-global-link::before, .nav-global-link-sub::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #d2d9e1;
  transform: translateY(-50%);
}

.nav-global-bg {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: #000;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "alpha(opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  overflow: hidden;
}

.nav-global-bg.show {
  opacity: 0.5;
  filter: alpha(opacity=50);
  -ms-filter: "alpha(opacity=50)";
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  visibility: visible;
  overflow: visible;
}

.nav-global-icon {
  display: flex;
  background-color: #F6F3E2;
  padding: 15px 10px;
  text-align: center;
}

.nav-global-icon-item {
  width: 50%;
  display: block;
  padding: 0 7px;
}

.nav-global-icon-link {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  background-color: #E2DCC0;
}

.nav-global-icon-link .icon {
  font-size: 5rem;
  color: #877166;
}

.nav-global-icon-link p {
  color: #877166;
  padding-left: 1em;
}

/* ==========================================================
  nav-local
========================================================== */
.nav-local {
  background-color: #214268;
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  z-index: 3;
}

@media (max-width: 812px) {
  .nav-local {
    top: 60px;
  }
}

.nav-local-in {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
}

@media (max-width: 812px) {
  .nav-local-in {
    padding: 0 15px;
  }
}

.nav-local-list {
  display: flex;
  position: relative;
}

.nav-local-list-item {
  width: 33.33%;
}

.nav-local-list-item.is-current {
  background-color: #f7f3e2;
  opacity: 1;
  visibility: visible;
}

.nav-local-list-item.is-current .text {
  color: #555;
}

.nav-local-list-item.is-current .text:before {
  color: #214268;
}

@media (min-width: 813px) {
  .nav-local-list-item {
    /*border-left: 2px solid #8c9ba5;*/
    /*&:last-child() {
        border-right: 2px solid #8c9ba5;
      }*/
  }
  .nav-local-list-item:hover {
    background-color: #f0ebcf;
    opacity: 1;
  }
  .nav-local-list-item:hover .text {
    color: #555;
  }
  .nav-local-list-item:hover .text:before {
    color: #214268;
  }
  .nav-local-list-item:hover .wrap-list-cmn {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.nav-local-link {
  display: block;
  width: 100%;
  text-align: center;
  padding: 14px 10px 10px;
  transition: background-color 0.3s, color 0.3s;
  transform: translateZ(0);
}

@media (max-width: 812px) {
  .nav-local-link {
    padding: 13px 9px 10px;
  }
}

.nav-local-link .text {
  position: relative;
  font-size: 1.6rem;
  font-weight: 400;
  text-align: left;
  color: #fff;
  line-height: 1.1;
  padding-left: 33px;
}

@media (max-width: 812px) {
  .nav-local-link .text {
    width: 100%;
    font-size: 1rem;
    padding-left: 18px;
  }
}

.nav-local-link .text:before {
  content: '\f138';
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 1.8rem;
  font-weight: 900;
  color: #fff;
}

@media (max-width: 812px) {
  .nav-local-link .text:before {
    top: 1px;
    font-size: 1rem;
  }
}

.nav-local-link .en {
  font-size: 1rem;
}

@media (max-width: 812px) {
  .nav-local-link .en {
    font-size: 0.7rem;
  }
}

/* ==========================================================
  table
========================================================== */
.c-table-cmn {
  width: 100%;
}

.c-table-cmn .col1 {
  width: 10.52%;
}

.c-table-cmn .col2 {
  width: 15.8%;
}

.c-table-cmn .col3 {
  width: 24.5%;
}

.c-table-cmn .col4 {
  width: 29.82%;
}

.c-table-cmn th {
  border: 1px solid #707070;
  padding: 7px 18px;
  background-color: #BFAA99;
  color: #fff;
  vertical-align: top;
}

@media (max-width: 812px) {
  .c-table-cmn th {
    padding: 10px 18px;
  }
}

.c-table-cmn td {
  border: 1px solid #707070;
  padding: 18px 18px;
  vertical-align: top;
}

@media (max-width: 812px) {
  .c-table-cmn td {
    padding: 20px 10px;
  }
}

.c-table-cmn .small td {
  padding: 7px 18px;
}

@media (max-width: 812px) {
  .c-table-cmn .small td {
    padding: 9px 18px;
  }
}

@media (max-width: 812px) {
  .c-wrap-table {
    overflow: scroll;
  }
}

@media (max-width: 812px) {
  .c-wrap-table > .c-table-cmn {
    width: 730px;
  }
}

.c-table-time {
  width: 100%;
  font-size: 1.2rem;
}

.c-table-time .col1 {
  width: 16.2%;
}

.c-table-time .col2 {
  width: 49.52%;
}

.c-table-time .col3 {
  width: 34.28%;
}

.c-table-time .trow {
  display: flex;
}

.c-table-time .thead {
  margin-bottom: 15px;
}

@media (max-width: 812px) {
  .c-table-time .thead {
    margin-bottom: 5px;
  }
}

.c-table-time .thead .col1 {
  padding-left: 5px;
  padding-right: 10px;
}

.c-table-time .thead .col2 {
  padding-left: 5px;
  padding-right: 10px;
}

@media (max-width: 812px) {
  .c-table-time .thead .col2 {
    padding-right: 5px;
  }
}

.c-table-time .thead .col3 {
  padding-left: 10px;
  padding-right: 5px;
}

@media (max-width: 812px) {
  .c-table-time .thead .col3 {
    padding-left: 5px;
  }
}

.c-table-time .thead .c-tag-01 {
  font-size: 1.2rem;
}

@media (max-width: 812px) {
  .c-table-time .thead .c-tag-01 {
    font-size: 1.4rem;
  }
}

.c-table-time .tbody {
  letter-spacing: 0.2rem;
}

@media (max-width: 812px) {
  .c-table-time .tbody {
    font-size: 1.6rem;
    letter-spacing: 0;
  }
}

.c-table-time .tbody .c-tag-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
}

@media (max-width: 812px) {
  .c-table-time .tbody .c-tag-01 {
    font-size: 1.4rem;
    padding: 2px;
  }
}

.c-table-time .tbody .col1 {
  padding-right: 10px;
  padding-bottom: 4px;
  padding-top: 6px;
}

@media (max-width: 812px) {
  .c-table-time .tbody .col1 {
    padding-right: 7px;
  }
}

.c-table-time .tbody .col2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding: 9px 30px 9px 10px;
  border-right: 1px solid #e5e1d8;
  border-bottom: 1px solid #e5e1d8;
}

@media (max-width: 812px) {
  .c-table-time .tbody .col2 {
    padding: 9px 20px 4px 10px;
  }
}

.c-table-time .tbody .col3 {
  display: flex;
  align-items: center;
  padding: 9px 10px 9px 35px;
  border-bottom: 1px solid #e5e1d8;
}

@media (max-width: 812px) {
  .c-table-time .tbody .col3 {
    padding: 9px 10px 4px 20px;
  }
}

.c-table-time .tbody .trow:last-child .col2,
.c-table-time .tbody .trow:last-child .col3 {
  border-bottom: 0;
}

table.schedule-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 960px;
}

@media (min-width: 813px) {
  table.schedule-table {
    width: 100%;
  }
}

table.schedule-table th, table.schedule-table td {
  border: 1px solid #707070;
}

table.schedule-table thead th {
  background: #bfaa99;
  color: #fff;
  font-size: 60%;
  font-weight: 700;
  padding: .4em 0;
  text-align: center;
}

table.schedule-table thead th.time {
  width: 60px;
}

@media (min-width: 813px) {
  table.schedule-table thead th.time {
    width: 72px;
  }
}

table.schedule-table td {
  padding: .5em;
  text-align: center;
  vertical-align: top;
  white-space: nowrap;
  min-width: 100px;
}

table.schedule-table td.time, table.schedule-table td.num {
  width: 50px;
  min-width: auto;
}

table.schedule-table td.time {
  background: #F3EBE7;
  padding: .5em 0;
  text-align: center;
}

table.schedule-table td.num {
  line-height: 1.2;
  padding: .5em 0;
  text-align: center;
  vertical-align: middle;
}

table.schedule-table td.num .room_num {
  font-size: .7em;
}

table.reception {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 2em;
  padding: 0;
}

table.reception th, table.reception td {
  border: 1px solid #707070;
}

table.reception th {
  background: #bfaa99;
  color: #fff;
  padding: .5em;
  text-align: center;
  width: 60px;
}

@media (min-width: 813px) {
  table.reception th {
    width: 72px;
  }
}

table.reception td {
  padding: .5em;
}

table.reception td.time {
  background: #F3EBE7;
  text-align: center;
  width: 60px;
}

@media (min-width: 813px) {
  table.reception td.time {
    width: 72px;
  }
}

/* ==========================================================
  tag
========================================================== */
/* c-tag-01
---------------------------------------------------------- */
.c-tag-01 {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  color: #fff;
  text-align: center;
  background-color: #214268;
  border-radius: 4px;
  padding: 2px 15px;
  /* background color */
  /* size */
}

@media (max-width: 812px) {
  .c-tag-01 {
    font-size: 1.1rem;
    border-radius: 4px;
    padding: 2px 9px;
  }
}

.c-tag-01.orange {
  background-color: #FF9D7B;
}

.c-tag-01.green {
  background-color: #B0D186;
}

.c-tag-01.brown {
  color: #214268;
  background-color: #f7f3e2;
}

.c-tag-01.md {
  padding-top: 4px;
  padding-bottom: 4px;
}

@media (max-width: 812px) {
  .c-tag-01.md {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.c-tag-01.sm {
  font-size: 1.2rem;
}

@media (max-width: 812px) {
  .c-tag-01.sm {
    font-size: 8px;
  }
}

.c-tag-01.full-width {
  width: 100%;
}

/* ==========================================================
  text-cmn
========================================================== */
/* ==========================================================
  text-color
========================================================== */
.c-text-color-01 {
  color: #8C563A;
  font-size: 1.4rem;
}

.c-text-white {
  color: #fff;
}

.c-text-black {
  color: #000;
}

.c-text-blue {
  color: #214268;
}

/* ==========================================================
  wrapper
========================================================== */
@media (min-width: 813px) {
  .wrapper {
    min-width: 1260px;
  }
}

.contents {
  background: url("/images/common/bg_asanoha.png") #f6f3e2 fixed;
  padding-top: 161px;
  position: relative;
}

.home .contents {
  padding-top: 90px;
}

@media (max-width: 812px) {
  .home .contents {
    padding-top: 60px;
  }
}

@media (max-width: 812px) {
  .contents {
    padding-top: 107px;
    padding-bottom: 50px;
  }
}

.contents .content-in {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px 100px;
  background: #f6f3e2;
  position: relative;
}

.home .contents .content-in:before {
  content: '';
  background: rgba(255, 255, 255, 0.5);
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 812px) {
  .home .contents .content-in:before {
    background: none;
  }
}

@media (max-width: 812px) {
  .contents .content-in {
    padding: 0 15px 50px;
  }
}

.sec-cmn {
  padding: 55px 0;
}

@media (max-width: 812px) {
  .sec-cmn {
    padding: 40px 0;
  }
}

/*! Yaku Han JP (Gothic - Small Amount) v3.0.0 SIL by Qrac / Based on Noto Sans CJK JP */
@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 100;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Thin.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Thin.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Thin.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 200;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Light.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Light.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Light.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 300;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-DemiLight.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-DemiLight.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-DemiLight.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 400;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Regular.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Regular.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Regular.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 500;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Medium.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Medium.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Medium.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 700;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Bold.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Bold.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Bold.woff") format("woff");
}

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: 900;
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Black.eot");
  src: url("../webfonts/YakuHanJPs/YakuHanJPs-Black.woff2") format("woff2"), url("../webfonts/YakuHanJPs/YakuHanJPs-Black.woff") format("woff");
}

.fc-base {
  color: #214268;
}

.fc-first {
  color: #2a5382;
}

.fc-second {
  color: #F3EBE7;
}

.fc-third {
  color: #CEB0A3;
}

.fc-fourth {
  color: #EDDAD0;
}

.fc-five {
  color: #FF9D7B;
}

.fc-six {
  color: #B0D186;
}

.fc-seven {
  color: #877166;
}

.fc-gray-100 {
  color: #f8f9fa;
}

.fc-gray-200 {
  color: #e9ecef;
}

.fc-gray-300 {
  color: #dee2e6;
}

.fc-gray-400 {
  color: #ced4da;
}

.fc-gray-500 {
  color: #adb5bd;
}

.fc-gray-600 {
  color: #6c757d;
}

.fc-gray-700 {
  color: #495057;
}

.fc-gray-800 {
  color: #343a40;
}

.fc-gray-900 {
  color: #212529;
}

.fc-white {
  color: #fff;
}

.fc-black {
  color: #000;
}

.fc-red {
  color: #b0120a;
}

.fc-yellow {
  color: #fff000;
}

.fc-orange {
  color: #f18f2c;
}

.fc-green {
  color: #63a764;
}

.fc-blue {
  color: #005fcc;
}

.fc-pink {
  color: #d43776;
}

.exlarge {
  font-size: 200% !important;
}

.xxlarge {
  font-size: 150% !important;
}

.xlarge {
  font-size: 125% !important;
}

.large {
  font-size: 112.5% !important;
}

.medium {
  font-size: 87.5% !important;
}

.small {
  font-size: 75% !important;
}

.xsmall {
  font-size: 62.5% !important;
}

@media (max-width: 812px) {
  .section-inner {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media (min-width: 813px) {
  .section-inner {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media (max-width: 812px) {
  .section-inner-upper {
    padding-bottom: 1em;
  }
}

@media (min-width: 813px) {
  .section-inner-upper {
    padding-bottom: 1em;
  }
}

@media (max-width: 812px) {
  .section-inner-lower {
    padding-top: 1em;
  }
}

@media (min-width: 813px) {
  .section-inner-lower {
    padding-top: 1em;
  }
}

@media (max-width: 812px) {
  .element {
    padding-top: 1em;
  }
}

@media (min-width: 813px) {
  .element {
    padding-top: 1em;
  }
}

.grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.8rem;
}

@media (min-width: 813px) {
  .grid {
    margin: 0 -1.2rem;
  }
}

.grid-inner {
  display: flex;
  flex-flow: row wrap;
  margin: -0.8rem -0.8rem 0;
}

@media (min-width: 813px) {
  .grid-inner {
    margin: -1.2rem -1.2rem 0;
  }
}

.col {
  padding: 0.8rem;
  flex: 1 1 0;
}

@media (min-width: 813px) {
  .col {
    padding: 1.2rem;
  }
}

.col figure {
  text-align: center;
}

.sm-1 {
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
}

.sm-1-center {
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
  margin: 0 auto;
}

.sm-offset-1 {
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
  margin-left: 8.33333%;
}

.sm-2 {
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

.sm-2-center {
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
  margin: 0 auto;
}

.sm-offset-2 {
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
  margin-left: 16.66667%;
}

.sm-3 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
}

.sm-3-center {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin: 0 auto;
}

.sm-offset-3 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  margin-left: 25%;
}

.sm-4 {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
}

.sm-4-center {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
  margin: 0 auto;
}

.sm-offset-4 {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
  margin-left: 33.33333%;
}

.sm-5 {
  flex: 0 0 41.66667%;
  width: 41.66667%;
  max-width: 41.66667%;
}

.sm-5-center {
  flex: 0 0 41.66667%;
  width: 41.66667%;
  max-width: 41.66667%;
  margin: 0 auto;
}

.sm-offset-5 {
  flex: 0 0 41.66667%;
  width: 41.66667%;
  max-width: 41.66667%;
  margin-left: 41.66667%;
}

.sm-6 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}

.sm-6-center {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin: 0 auto;
}

.sm-offset-6 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  margin-left: 50%;
}

.sm-7 {
  flex: 0 0 58.33333%;
  width: 58.33333%;
  max-width: 58.33333%;
}

.sm-7-center {
  flex: 0 0 58.33333%;
  width: 58.33333%;
  max-width: 58.33333%;
  margin: 0 auto;
}

.sm-offset-7 {
  flex: 0 0 58.33333%;
  width: 58.33333%;
  max-width: 58.33333%;
  margin-left: 58.33333%;
}

.sm-8 {
  flex: 0 0 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%;
}

.sm-8-center {
  flex: 0 0 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%;
  margin: 0 auto;
}

.sm-offset-8 {
  flex: 0 0 66.66667%;
  width: 66.66667%;
  max-width: 66.66667%;
  margin-left: 66.66667%;
}

.sm-9 {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
}

.sm-9-center {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  margin: 0 auto;
}

.sm-offset-9 {
  flex: 0 0 75%;
  width: 75%;
  max-width: 75%;
  margin-left: 75%;
}

.sm-10 {
  flex: 0 0 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
}

.sm-10-center {
  flex: 0 0 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
  margin: 0 auto;
}

.sm-offset-10 {
  flex: 0 0 83.33333%;
  width: 83.33333%;
  max-width: 83.33333%;
  margin-left: 83.33333%;
}

.sm-11 {
  flex: 0 0 91.66667%;
  width: 91.66667%;
  max-width: 91.66667%;
}

.sm-11-center {
  flex: 0 0 91.66667%;
  width: 91.66667%;
  max-width: 91.66667%;
  margin: 0 auto;
}

.sm-offset-11 {
  flex: 0 0 91.66667%;
  width: 91.66667%;
  max-width: 91.66667%;
  margin-left: 91.66667%;
}

.sm-12 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

.sm-12-center {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.sm-offset-12 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  margin-left: 100%;
}

@media (min-width: 813px) {
  .lg-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }
  .lg-1-center {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
    margin: 0 auto;
  }
  .lg-offset-1 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
    margin-left: 8.33333%;
  }
  .lg-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
  }
  .lg-2-center {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
    margin: 0 auto;
  }
  .lg-offset-2 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
    margin-left: 16.66667%;
  }
  .lg-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .lg-3-center {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin: 0 auto;
  }
  .lg-offset-3 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    margin-left: 25%;
  }
  .lg-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }
  .lg-4-center {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
    margin: 0 auto;
  }
  .lg-offset-4 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
    margin-left: 33.33333%;
  }
  .lg-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }
  .lg-5-center {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
    margin: 0 auto;
  }
  .lg-offset-5 {
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
    margin-left: 41.66667%;
  }
  .lg-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
  }
  .lg-6-center {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin: 0 auto;
  }
  .lg-offset-6 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    margin-left: 50%;
  }
  .lg-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }
  .lg-7-center {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
    margin: 0 auto;
  }
  .lg-offset-7 {
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
    margin-left: 58.33333%;
  }
  .lg-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }
  .lg-8-center {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
    margin: 0 auto;
  }
  .lg-offset-8 {
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
    margin-left: 66.66667%;
  }
  .lg-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
  }
  .lg-9-center {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin: 0 auto;
  }
  .lg-offset-9 {
    flex: 0 0 75%;
    width: 75%;
    max-width: 75%;
    margin-left: 75%;
  }
  .lg-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
  }
  .lg-10-center {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
    margin: 0 auto;
  }
  .lg-offset-10 {
    flex: 0 0 83.33333%;
    width: 83.33333%;
    max-width: 83.33333%;
    margin-left: 83.33333%;
  }
  .lg-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
  }
  .lg-11-center {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
    margin: 0 auto;
  }
  .lg-offset-11 {
    flex: 0 0 91.66667%;
    width: 91.66667%;
    max-width: 91.66667%;
    margin-left: 91.66667%;
  }
  .lg-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .lg-12-center {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  .lg-offset-12 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    margin-left: 100%;
  }
}

.grid-tile {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.8rem;
}

@media (min-width: 813px) {
  .grid-tile {
    margin: 0 -1.2rem;
  }
}

.grid-tile .sm-col-1 {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
  padding: 0.8rem;
}

.grid-tile .sm-col-2 {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  padding: 0.8rem;
}

.grid-tile .sm-col-3 {
  flex: 0 0 33.33333%;
  width: 33.33333%;
  max-width: 33.33333%;
  padding: 0.8rem;
}

.grid-tile .sm-col-4 {
  flex: 0 0 25%;
  width: 25%;
  max-width: 25%;
  padding: 0.8rem;
}

.grid-tile .sm-col-5 {
  flex: 0 0 20%;
  width: 20%;
  max-width: 20%;
  padding: 0.8rem;
}

.grid-tile .sm-col-6 {
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
  padding: 0.8rem;
}

.grid-tile .sm-col-7 {
  flex: 0 0 14.28571%;
  width: 14.28571%;
  max-width: 14.28571%;
  padding: 0.8rem;
}

.grid-tile .sm-col-8 {
  flex: 0 0 12.5%;
  width: 12.5%;
  max-width: 12.5%;
  padding: 0.8rem;
}

.grid-tile .sm-col-9 {
  flex: 0 0 11.11111%;
  width: 11.11111%;
  max-width: 11.11111%;
  padding: 0.8rem;
}

.grid-tile .sm-col-10 {
  flex: 0 0 10%;
  width: 10%;
  max-width: 10%;
  padding: 0.8rem;
}

.grid-tile .sm-col-11 {
  flex: 0 0 9.09091%;
  width: 9.09091%;
  max-width: 9.09091%;
  padding: 0.8rem;
}

.grid-tile .sm-col-12 {
  flex: 0 0 8.33333%;
  width: 8.33333%;
  max-width: 8.33333%;
  padding: 0.8rem;
}

@media (min-width: 813px) {
  .grid-tile .lg-col-1 {
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-2 {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-3 {
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-4 {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-5 {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-6 {
    flex: 0 0 16.66667%;
    width: 16.66667%;
    max-width: 16.66667%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-7 {
    flex: 0 0 14.28571%;
    width: 14.28571%;
    max-width: 14.28571%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-8 {
    flex: 0 0 12.5%;
    width: 12.5%;
    max-width: 12.5%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-9 {
    flex: 0 0 11.11111%;
    width: 11.11111%;
    max-width: 11.11111%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-10 {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-11 {
    flex: 0 0 9.09091%;
    width: 9.09091%;
    max-width: 9.09091%;
    padding: 1.2rem;
  }
  .grid-tile .lg-col-12 {
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
    padding: 1.2rem;
  }
}

.align-end {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-end-vertical {
  align-content: flex-end;
}

.align-center-vertical {
  align-content: center;
}

.align-justify-vertical {
  align-content: space-between;
}

.align-spaced-vertical {
  align-content: space-around;
}

.item-top {
  align-items: flex-start;
}

.item-top-child {
  align-self: flex-start;
}

.item-end {
  align-items: flex-end;
}

.item-end-child {
  align-self: flex-end;
}

.item-center {
  align-items: center;
}

.item-center-child {
  align-self: center;
}

.item-stretch {
  align-items: stretch;
}

.item-stretch-child {
  align-self: stretch;
}

.flex-row {
  display: flex;
  flex-flow: row wrap;
}

.flex-column {
  display: flex;
  flex-flow: column wrap;
}

.icon span::after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
  margin-left: .4em;
}

.icon span.left::after {
  content: none;
}

.icon span.left::before {
  font-family: "Font Awesome 5 Pro";
  content: "";
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
  margin-right: .4em;
}

.icon span.arrows::after {
  content: "" !important;
}

.icon span.arrows.left::after {
  content: none !important;
}

.icon span.arrows.left::before {
  content: "" !important;
}

.icon span.pdf::after {
  content: "" !important;
}

.icon span.pdf.left::after {
  content: none !important;
}

.icon span.pdf.left::before {
  content: "" !important;
}

.icon span.window::after {
  content: "" !important;
}

.icon span.window.left::after {
  content: none !important;
}

.icon span.window.left::before {
  content: "" !important;
}

.icon span.tel::after {
  content: "" !important;
}

.icon span.tel.left::after {
  content: none !important;
}

.icon span.tel.left::before {
  content: "" !important;
}

.icon span.mail::after {
  content: "" !important;
}

.icon span.mail.left::after {
  content: none !important;
}

.icon span.mail.left::before {
  content: "" !important;
}

.icon span.fax::after {
  content: "" !important;
}

.icon span.fax.left::after {
  content: none !important;
}

.icon span.fax.left::before {
  content: "" !important;
}

.icon span.privacy::after {
  content: "" !important;
}

.icon span.privacy.left::after {
  content: none !important;
}

.icon span.privacy.left::before {
  content: "" !important;
}

.icon span.link::after {
  content: "" !important;
}

.icon span.link.left::after {
  content: none !important;
}

.icon span.link.left::before {
  content: "" !important;
}

.icon span.ic-base::after, .icon span.ic-base::before {
  color: #214268;
}

.icon span.ic-first::after, .icon span.ic-first::before {
  color: #2a5382;
}

.icon span.ic-second::after, .icon span.ic-second::before {
  color: #F3EBE7;
}

.icon span.ic-third::after, .icon span.ic-third::before {
  color: #CEB0A3;
}

.icon span.ic-fourth::after, .icon span.ic-fourth::before {
  color: #EDDAD0;
}

.icon span.ic-five::after, .icon span.ic-five::before {
  color: #FF9D7B;
}

.icon span.ic-six::after, .icon span.ic-six::before {
  color: #B0D186;
}

.icon span.ic-seven::after, .icon span.ic-seven::before {
  color: #877166;
}

.icon span.ic-gray-100::after, .icon span.ic-gray-100::before {
  color: #f8f9fa;
}

.icon span.ic-gray-200::after, .icon span.ic-gray-200::before {
  color: #e9ecef;
}

.icon span.ic-gray-300::after, .icon span.ic-gray-300::before {
  color: #dee2e6;
}

.icon span.ic-gray-400::after, .icon span.ic-gray-400::before {
  color: #ced4da;
}

.icon span.ic-gray-500::after, .icon span.ic-gray-500::before {
  color: #adb5bd;
}

.icon span.ic-gray-600::after, .icon span.ic-gray-600::before {
  color: #6c757d;
}

.icon span.ic-gray-700::after, .icon span.ic-gray-700::before {
  color: #495057;
}

.icon span.ic-gray-800::after, .icon span.ic-gray-800::before {
  color: #343a40;
}

.icon span.ic-gray-900::after, .icon span.ic-gray-900::before {
  color: #212529;
}

.icon span.ic-white::after, .icon span.ic-white::before {
  color: #fff;
}

.icon span.ic-black::after, .icon span.ic-black::before {
  color: #000;
}

.icon span.ic-red::after, .icon span.ic-red::before {
  color: #b0120a;
}

.icon span.ic-yellow::after, .icon span.ic-yellow::before {
  color: #fff000;
}

.icon span.ic-orange::after, .icon span.ic-orange::before {
  color: #f18f2c;
}

.icon span.ic-green::after, .icon span.ic-green::before {
  color: #63a764;
}

.icon span.ic-blue::after, .icon span.ic-blue::before {
  color: #005fcc;
}

.icon span.ic-pink::after, .icon span.ic-pink::before {
  color: #d43776;
}

.button, input[type='submit'] {
  background: #214268;
  border: 3px solid transparent;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: inherit;
  line-height: 1.42875 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background 0.2s ease-out, color 0.2s ease-out;
  vertical-align: middle;
  padding: calc( 0.8em - 3px) calc( 1em - 3px);
}

@media (min-width: 813px) {
  .button, input[type='submit'] {
    padding: calc( 1em - 3px) calc( 1em - 3px);
  }
}

.button:hover, input:hover[type='submit'] {
  background: #152941;
  color: #fff;
  text-decoration: none;
}

.button.base, input.base[type='submit'] {
  background: #214268;
  border: 3px solid transparent;
  color: #fff;
}

.button.base:hover, input.base:hover[type='submit'] {
  background: #152941;
  color: #fff;
}

.button.first, input.first[type='submit'] {
  background: #2a5382;
  border: 3px solid transparent;
  color: #fff;
}

.button.first:hover, input.first:hover[type='submit'] {
  background: #1e3a5b;
  color: #fff;
}

.button.second, input.second[type='submit'] {
  background: #F3EBE7;
  border: 3px solid transparent;
  color: #214268;
}

.button.second:hover, input.second:hover[type='submit'] {
  background: #e2cfc5;
  color: #214268;
}

.button.third, input.third[type='submit'] {
  background: #CEB0A3;
  border: 3px solid transparent;
  color: #fff;
}

.button.third:hover, input.third:hover[type='submit'] {
  background: #bc9382;
  color: #fff;
}

.button.fourth, input.fourth[type='submit'] {
  background: #EDDAD0;
  border: 3px solid transparent;
  color: #fff;
}

.button.fourth:hover, input.fourth:hover[type='submit'] {
  background: #dfbdab;
  color: #fff;
}

.button.five, input.five[type='submit'] {
  background: #FF9D7B;
  border: 3px solid transparent;
  color: #fff;
}

.button.five:hover, input.five:hover[type='submit'] {
  background: #ff7748;
  color: #fff;
}

.button.seven, input.seven[type='submit'] {
  background: #877166;
  border: 3px solid transparent;
  color: #fff;
}

.button.seven:hover, input.seven:hover[type='submit'] {
  background: #6a5950;
  color: #fff;
}

.button.exlarge, input.exlarge[type='submit'] {
  padding: calc( 0.4em - 3px) calc( 0.4em - 3px);
}

@media (min-width: 813px) {
  .button.exlarge, input.exlarge[type='submit'] {
    padding: calc( 0.4em - 3px) calc( 0.4em - 3px);
  }
}

.button.xxlarge, input.xxlarge[type='submit'] {
  padding: calc( 0.45em - 3px) calc( 0.45em - 3px);
}

@media (min-width: 813px) {
  .button.xxlarge, input.xxlarge[type='submit'] {
    padding: calc( 0.45em - 3px) calc( 0.6em - 3px);
  }
}

.button.xlarge, input.xlarge[type='submit'] {
  padding: calc( 0.7em - 3px) calc( 0.8em - 3px);
}

@media (min-width: 813px) {
  .button.xlarge, input.xlarge[type='submit'] {
    padding: calc( 0.5em - 3px) calc( 0.8em - 3px);
  }
}

.button.large, input.large[type='submit'] {
  padding: calc( 0.8em - 3px) calc( 0.8em - 3px);
}

@media (min-width: 813px) {
  .button.large, input.large[type='submit'] {
    padding: calc( 0.45em - 3px) calc( 0.8em - 3px);
  }
}

.button.medium, input.medium[type='submit'] {
  padding: calc( 0.9em - 3px) calc( 1em - 3px);
}

@media (min-width: 813px) {
  .button.medium, input.medium[type='submit'] {
    padding: calc( 0.7em - 3px) calc( 0.8em - 3px);
  }
}

.button.small, input.small[type='submit'] {
  padding: calc( 1em - 3px) calc( 1em - 3px);
}

@media (min-width: 813px) {
  .button.small, input.small[type='submit'] {
    padding: calc( 0.6em - 3px) calc( 1em - 3px);
  }
}

.button.xsmall, input.xsmall[type='submit'] {
  padding: calc( 1.2em - 3px) calc( 1.2em - 3px);
}

@media (min-width: 813px) {
  .button.xsmall, input.xsmall[type='submit'] {
    padding: calc( 0.7em - 3px) calc( 1em - 3px);
  }
}

.button.schedule, input.schedule[type='submit'] {
  padding: .5em 1em;
}

.button.outline, input.outline[type='submit'] {
  background: #fff;
  border: 3px solid #214268;
  color: #214268;
  text-shadow: none;
}

.button.outline:hover, input.outline:hover[type='submit'] {
  background: #214268;
  color: #fff;
  text-decoration: none;
}

.button.outline.base, input.outline.base[type='submit'] {
  background: #fff;
  border: 3px solid #214268;
  color: #214268;
}

.button.outline.base:hover, input.outline.base:hover[type='submit'] {
  background: #214268;
  color: #fff;
}

.button.outline.first, input.outline.first[type='submit'] {
  background: #fff;
  border: 3px solid #2a5382;
  color: #2a5382;
}

.button.outline.first:hover, input.outline.first:hover[type='submit'] {
  background: #2a5382;
  color: #fff;
}

.button.outline.second, input.outline.second[type='submit'] {
  background: #fff;
  border: 3px solid #F3EBE7;
  color: #F3EBE7;
}

.button.outline.second:hover, input.outline.second:hover[type='submit'] {
  background: #F3EBE7;
  color: #fff;
}

.button.outline.third, input.outline.third[type='submit'] {
  background: #fff;
  border: 3px solid #CEB0A3;
  color: #CEB0A3;
}

.button.outline.third:hover, input.outline.third:hover[type='submit'] {
  background: #CEB0A3;
  color: #fff;
}

.button.outline.fourth, input.outline.fourth[type='submit'] {
  background: #fff;
  border: 3px solid #EDDAD0;
  color: #EDDAD0;
}

.button.outline.fourth:hover, input.outline.fourth:hover[type='submit'] {
  background: #EDDAD0;
  color: #fff;
}

.button.outline.five, input.outline.five[type='submit'] {
  background: #fff;
  border: 3px solid #FF9D7B;
  color: #FF9D7B;
}

.button.outline.five:hover, input.outline.five:hover[type='submit'] {
  background: #FF9D7B;
  color: #fff;
}

.button.outline.seven, input.outline.seven[type='submit'] {
  background: #fff;
  border: 3px solid #877166;
  color: #877166;
}

.button.outline.seven:hover, input.outline.seven:hover[type='submit'] {
  background: #877166;
  color: #fff;
}

.button.expanded, input.expanded[type='submit'] {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.button span, input[type='submit'] span,
.button.outline span,
input.outline[type='submit'] span {
  display: inline-block;
  position: relative;
  padding-right: 2em;
}

.button span::after, input[type='submit'] span::after,
.button.outline span::after,
input.outline[type='submit'] span::after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
  font-weight: 900;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.button span.left::after, input[type='submit'] span.left::after,
.button.outline span.left::after,
input.outline[type='submit'] span.left::after {
  content: none;
}

.button span.left::before, input[type='submit'] span.left::before,
.button.outline span.left::before,
input.outline[type='submit'] span.left::before {
  font-family: "Font Awesome 5 Pro";
  content: "";
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
  font-weight: 900;
  padding-left: 2em;
  padding-right: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.button span.arrows::after, input[type='submit'] span.arrows::after,
.button.outline span.arrows::after,
input.outline[type='submit'] span.arrows::after {
  content: "" !important;
}

.button span.arrows.left::after, input[type='submit'] span.arrows.left::after,
.button.outline span.arrows.left::after,
input.outline[type='submit'] span.arrows.left::after {
  content: none !important;
}

.button span.arrows.left::before, input[type='submit'] span.arrows.left::before,
.button.outline span.arrows.left::before,
input.outline[type='submit'] span.arrows.left::before {
  content: "" !important;
}

.button span.pdf::after, input[type='submit'] span.pdf::after,
.button.outline span.pdf::after,
input.outline[type='submit'] span.pdf::after {
  content: "" !important;
}

.button span.pdf.left::after, input[type='submit'] span.pdf.left::after,
.button.outline span.pdf.left::after,
input.outline[type='submit'] span.pdf.left::after {
  content: none !important;
}

.button span.pdf.left::before, input[type='submit'] span.pdf.left::before,
.button.outline span.pdf.left::before,
input.outline[type='submit'] span.pdf.left::before {
  content: "" !important;
}

.button span.window::after, input[type='submit'] span.window::after,
.button.outline span.window::after,
input.outline[type='submit'] span.window::after {
  content: "" !important;
}

.button span.window.left::after, input[type='submit'] span.window.left::after,
.button.outline span.window.left::after,
input.outline[type='submit'] span.window.left::after {
  content: none !important;
}

.button span.window.left::before, input[type='submit'] span.window.left::before,
.button.outline span.window.left::before,
input.outline[type='submit'] span.window.left::before {
  content: "" !important;
}

.button span.tel::after, input[type='submit'] span.tel::after,
.button.outline span.tel::after,
input.outline[type='submit'] span.tel::after {
  content: "" !important;
}

.button span.tel.left::after, input[type='submit'] span.tel.left::after,
.button.outline span.tel.left::after,
input.outline[type='submit'] span.tel.left::after {
  content: none !important;
}

.button span.tel.left::before, input[type='submit'] span.tel.left::before,
.button.outline span.tel.left::before,
input.outline[type='submit'] span.tel.left::before {
  content: "" !important;
}

.button span.mail::after, input[type='submit'] span.mail::after,
.button.outline span.mail::after,
input.outline[type='submit'] span.mail::after {
  content: "" !important;
}

.button span.mail.left::after, input[type='submit'] span.mail.left::after,
.button.outline span.mail.left::after,
input.outline[type='submit'] span.mail.left::after {
  content: none !important;
}

.button span.mail.left::before, input[type='submit'] span.mail.left::before,
.button.outline span.mail.left::before,
input.outline[type='submit'] span.mail.left::before {
  content: "" !important;
}

.button span.fax::after, input[type='submit'] span.fax::after,
.button.outline span.fax::after,
input.outline[type='submit'] span.fax::after {
  content: "" !important;
}

.button span.fax.left::after, input[type='submit'] span.fax.left::after,
.button.outline span.fax.left::after,
input.outline[type='submit'] span.fax.left::after {
  content: none !important;
}

.button span.fax.left::before, input[type='submit'] span.fax.left::before,
.button.outline span.fax.left::before,
input.outline[type='submit'] span.fax.left::before {
  content: "" !important;
}

.button span.privacy::after, input[type='submit'] span.privacy::after,
.button.outline span.privacy::after,
input.outline[type='submit'] span.privacy::after {
  content: "" !important;
}

.button span.privacy.left::after, input[type='submit'] span.privacy.left::after,
.button.outline span.privacy.left::after,
input.outline[type='submit'] span.privacy.left::after {
  content: none !important;
}

.button span.privacy.left::before, input[type='submit'] span.privacy.left::before,
.button.outline span.privacy.left::before,
input.outline[type='submit'] span.privacy.left::before {
  content: "" !important;
}

.button span.link::after, input[type='submit'] span.link::after,
.button.outline span.link::after,
input.outline[type='submit'] span.link::after {
  content: "" !important;
}

.button span.link.left::after, input[type='submit'] span.link.left::after,
.button.outline span.link.left::after,
input.outline[type='submit'] span.link.left::after {
  content: none !important;
}

.button span.link.left::before, input[type='submit'] span.link.left::before,
.button.outline span.link.left::before,
input.outline[type='submit'] span.link.left::before {
  content: "" !important;
}

.button span.ic-base::after, input[type='submit'] span.ic-base::after, .button span.ic-base::before, input[type='submit'] span.ic-base::before,
.button.outline span.ic-base::after,
input.outline[type='submit'] span.ic-base::after,
.button.outline span.ic-base::before,
input.outline[type='submit'] span.ic-base::before {
  color: #214268;
}

.button span.ic-first::after, input[type='submit'] span.ic-first::after, .button span.ic-first::before, input[type='submit'] span.ic-first::before,
.button.outline span.ic-first::after,
input.outline[type='submit'] span.ic-first::after,
.button.outline span.ic-first::before,
input.outline[type='submit'] span.ic-first::before {
  color: #2a5382;
}

.button span.ic-second::after, input[type='submit'] span.ic-second::after, .button span.ic-second::before, input[type='submit'] span.ic-second::before,
.button.outline span.ic-second::after,
input.outline[type='submit'] span.ic-second::after,
.button.outline span.ic-second::before,
input.outline[type='submit'] span.ic-second::before {
  color: #F3EBE7;
}

.button span.ic-third::after, input[type='submit'] span.ic-third::after, .button span.ic-third::before, input[type='submit'] span.ic-third::before,
.button.outline span.ic-third::after,
input.outline[type='submit'] span.ic-third::after,
.button.outline span.ic-third::before,
input.outline[type='submit'] span.ic-third::before {
  color: #CEB0A3;
}

.button span.ic-fourth::after, input[type='submit'] span.ic-fourth::after, .button span.ic-fourth::before, input[type='submit'] span.ic-fourth::before,
.button.outline span.ic-fourth::after,
input.outline[type='submit'] span.ic-fourth::after,
.button.outline span.ic-fourth::before,
input.outline[type='submit'] span.ic-fourth::before {
  color: #EDDAD0;
}

.button span.ic-five::after, input[type='submit'] span.ic-five::after, .button span.ic-five::before, input[type='submit'] span.ic-five::before,
.button.outline span.ic-five::after,
input.outline[type='submit'] span.ic-five::after,
.button.outline span.ic-five::before,
input.outline[type='submit'] span.ic-five::before {
  color: #FF9D7B;
}

.button span.ic-six::after, input[type='submit'] span.ic-six::after, .button span.ic-six::before, input[type='submit'] span.ic-six::before,
.button.outline span.ic-six::after,
input.outline[type='submit'] span.ic-six::after,
.button.outline span.ic-six::before,
input.outline[type='submit'] span.ic-six::before {
  color: #B0D186;
}

.button span.ic-seven::after, input[type='submit'] span.ic-seven::after, .button span.ic-seven::before, input[type='submit'] span.ic-seven::before,
.button.outline span.ic-seven::after,
input.outline[type='submit'] span.ic-seven::after,
.button.outline span.ic-seven::before,
input.outline[type='submit'] span.ic-seven::before {
  color: #877166;
}

.button span.ic-gray-100::after, input[type='submit'] span.ic-gray-100::after, .button span.ic-gray-100::before, input[type='submit'] span.ic-gray-100::before,
.button.outline span.ic-gray-100::after,
input.outline[type='submit'] span.ic-gray-100::after,
.button.outline span.ic-gray-100::before,
input.outline[type='submit'] span.ic-gray-100::before {
  color: #f8f9fa;
}

.button span.ic-gray-200::after, input[type='submit'] span.ic-gray-200::after, .button span.ic-gray-200::before, input[type='submit'] span.ic-gray-200::before,
.button.outline span.ic-gray-200::after,
input.outline[type='submit'] span.ic-gray-200::after,
.button.outline span.ic-gray-200::before,
input.outline[type='submit'] span.ic-gray-200::before {
  color: #e9ecef;
}

.button span.ic-gray-300::after, input[type='submit'] span.ic-gray-300::after, .button span.ic-gray-300::before, input[type='submit'] span.ic-gray-300::before,
.button.outline span.ic-gray-300::after,
input.outline[type='submit'] span.ic-gray-300::after,
.button.outline span.ic-gray-300::before,
input.outline[type='submit'] span.ic-gray-300::before {
  color: #dee2e6;
}

.button span.ic-gray-400::after, input[type='submit'] span.ic-gray-400::after, .button span.ic-gray-400::before, input[type='submit'] span.ic-gray-400::before,
.button.outline span.ic-gray-400::after,
input.outline[type='submit'] span.ic-gray-400::after,
.button.outline span.ic-gray-400::before,
input.outline[type='submit'] span.ic-gray-400::before {
  color: #ced4da;
}

.button span.ic-gray-500::after, input[type='submit'] span.ic-gray-500::after, .button span.ic-gray-500::before, input[type='submit'] span.ic-gray-500::before,
.button.outline span.ic-gray-500::after,
input.outline[type='submit'] span.ic-gray-500::after,
.button.outline span.ic-gray-500::before,
input.outline[type='submit'] span.ic-gray-500::before {
  color: #adb5bd;
}

.button span.ic-gray-600::after, input[type='submit'] span.ic-gray-600::after, .button span.ic-gray-600::before, input[type='submit'] span.ic-gray-600::before,
.button.outline span.ic-gray-600::after,
input.outline[type='submit'] span.ic-gray-600::after,
.button.outline span.ic-gray-600::before,
input.outline[type='submit'] span.ic-gray-600::before {
  color: #6c757d;
}

.button span.ic-gray-700::after, input[type='submit'] span.ic-gray-700::after, .button span.ic-gray-700::before, input[type='submit'] span.ic-gray-700::before,
.button.outline span.ic-gray-700::after,
input.outline[type='submit'] span.ic-gray-700::after,
.button.outline span.ic-gray-700::before,
input.outline[type='submit'] span.ic-gray-700::before {
  color: #495057;
}

.button span.ic-gray-800::after, input[type='submit'] span.ic-gray-800::after, .button span.ic-gray-800::before, input[type='submit'] span.ic-gray-800::before,
.button.outline span.ic-gray-800::after,
input.outline[type='submit'] span.ic-gray-800::after,
.button.outline span.ic-gray-800::before,
input.outline[type='submit'] span.ic-gray-800::before {
  color: #343a40;
}

.button span.ic-gray-900::after, input[type='submit'] span.ic-gray-900::after, .button span.ic-gray-900::before, input[type='submit'] span.ic-gray-900::before,
.button.outline span.ic-gray-900::after,
input.outline[type='submit'] span.ic-gray-900::after,
.button.outline span.ic-gray-900::before,
input.outline[type='submit'] span.ic-gray-900::before {
  color: #212529;
}

.button span.ic-white::after, input[type='submit'] span.ic-white::after, .button span.ic-white::before, input[type='submit'] span.ic-white::before,
.button.outline span.ic-white::after,
input.outline[type='submit'] span.ic-white::after,
.button.outline span.ic-white::before,
input.outline[type='submit'] span.ic-white::before {
  color: #fff;
}

.button span.ic-black::after, input[type='submit'] span.ic-black::after, .button span.ic-black::before, input[type='submit'] span.ic-black::before,
.button.outline span.ic-black::after,
input.outline[type='submit'] span.ic-black::after,
.button.outline span.ic-black::before,
input.outline[type='submit'] span.ic-black::before {
  color: #000;
}

.button span.ic-red::after, input[type='submit'] span.ic-red::after, .button span.ic-red::before, input[type='submit'] span.ic-red::before,
.button.outline span.ic-red::after,
input.outline[type='submit'] span.ic-red::after,
.button.outline span.ic-red::before,
input.outline[type='submit'] span.ic-red::before {
  color: #b0120a;
}

.button span.ic-yellow::after, input[type='submit'] span.ic-yellow::after, .button span.ic-yellow::before, input[type='submit'] span.ic-yellow::before,
.button.outline span.ic-yellow::after,
input.outline[type='submit'] span.ic-yellow::after,
.button.outline span.ic-yellow::before,
input.outline[type='submit'] span.ic-yellow::before {
  color: #fff000;
}

.button span.ic-orange::after, input[type='submit'] span.ic-orange::after, .button span.ic-orange::before, input[type='submit'] span.ic-orange::before,
.button.outline span.ic-orange::after,
input.outline[type='submit'] span.ic-orange::after,
.button.outline span.ic-orange::before,
input.outline[type='submit'] span.ic-orange::before {
  color: #f18f2c;
}

.button span.ic-green::after, input[type='submit'] span.ic-green::after, .button span.ic-green::before, input[type='submit'] span.ic-green::before,
.button.outline span.ic-green::after,
input.outline[type='submit'] span.ic-green::after,
.button.outline span.ic-green::before,
input.outline[type='submit'] span.ic-green::before {
  color: #63a764;
}

.button span.ic-blue::after, input[type='submit'] span.ic-blue::after, .button span.ic-blue::before, input[type='submit'] span.ic-blue::before,
.button.outline span.ic-blue::after,
input.outline[type='submit'] span.ic-blue::after,
.button.outline span.ic-blue::before,
input.outline[type='submit'] span.ic-blue::before {
  color: #005fcc;
}

.button span.ic-pink::after, input[type='submit'] span.ic-pink::after, .button span.ic-pink::before, input[type='submit'] span.ic-pink::before,
.button.outline span.ic-pink::after,
input.outline[type='submit'] span.ic-pink::after,
.button.outline span.ic-pink::before,
input.outline[type='submit'] span.ic-pink::before {
  color: #d43776;
}

.block {
  background: #fff;
  border: 4px solid #214268;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

.block.height {
  height: 100%;
}

.block.base {
  border: 4px solid #214268;
}

.block.first {
  border: 4px solid #2a5382;
}

.block.second {
  border: 4px solid #F3EBE7;
}

.block.third {
  border: 4px solid #CEB0A3;
}

.block.fourth {
  border: 4px solid #EDDAD0;
}

.block.five {
  border: 4px solid #FF9D7B;
}

.block.seven {
  border: 4px solid #877166;
}

.block.gray-100 {
  border: 4px solid #f8f9fa;
}

.block.gray-200 {
  border: 4px solid #e9ecef;
}

.block.gray-300 {
  border: 4px solid #dee2e6;
}

.block.gray-400 {
  border: 4px solid #ced4da;
}

.block.gray-500 {
  border: 4px solid #adb5bd;
}

.block.gray-600 {
  border: 4px solid #6c757d;
}

.block.gray-700 {
  border: 4px solid #495057;
}

.block.gray-800 {
  border: 4px solid #343a40;
}

.block.gray-900 {
  border: 4px solid #212529;
}

.block.white {
  border: 4px solid #fff;
}

.block.black {
  border: 4px solid #000;
}

.block.red {
  border: 4px solid #b0120a;
}

.block.yellow {
  border: 4px solid #fff000;
}

.block.orange {
  border: 4px solid #f18f2c;
}

.block.green {
  border: 4px solid #63a764;
}

.block.blue {
  border: 4px solid #005fcc;
}

.block.pink {
  border: 4px solid #d43776;
}

.block-header {
  background: #214268;
  color: #fff;
  font-size: inherit;
  font-weight: bold;
  padding: 0.3em 1em calc( .3em + 4px);
}

.base .block-header {
  background: #214268;
}

.first .block-header {
  background: #2a5382;
}

.second .block-header {
  background: #F3EBE7;
}

.third .block-header {
  background: #CEB0A3;
}

.fourth .block-header {
  background: #EDDAD0;
}

.five .block-header {
  background: #FF9D7B;
}

.seven .block-header {
  background: #877166;
}

.gray-100 .block-header {
  background: #f8f9fa;
}

.gray-200 .block-header {
  background: #e9ecef;
}

.gray-300 .block-header {
  background: #dee2e6;
}

.gray-400 .block-header {
  background: #ced4da;
}

.gray-500 .block-header {
  background: #adb5bd;
}

.gray-600 .block-header {
  background: #6c757d;
}

.gray-700 .block-header {
  background: #495057;
}

.gray-800 .block-header {
  background: #343a40;
}

.gray-900 .block-header {
  background: #212529;
}

.white .block-header {
  background: #fff;
}

.black .block-header {
  background: #000;
}

.red .block-header {
  background: #b0120a;
}

.yellow .block-header {
  background: #fff000;
}

.orange .block-header {
  background: #f18f2c;
}

.green .block-header {
  background: #63a764;
}

.blue .block-header {
  background: #005fcc;
}

.pink .block-header {
  background: #d43776;
}

.block-body {
  line-height: 1.8;
  padding: 1em;
}

@media (min-width: 813px) {
  .block-body {
    line-height: 2;
  }
}

ul, ol {
  list-style: none;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

ul.li-mb li:not(:last-child), ol.li-mb li:not(:last-child) {
  margin-bottom: .8em;
}

ul.li-mb ul, ul.li-mb ol, ol.li-mb ul, ol.li-mb ol {
  margin-top: .8em;
}

ul.indent, ol.indent {
  margin-left: 1em;
}

ul.indent ul, ol.indent ul {
  margin-top: .8em;
}

ul.disc {
  list-style: disc outside;
  padding-left: 1.4em;
}

ul.disc ul {
  list-style: circle outside;
  padding-left: 1em;
}

ul.square li {
  line-height: inherit;
  padding-left: 1.2em;
  position: relative;
}

ul.square li:before {
  font-family: "Font Awesome 5 Pro";
  content: '\f0c8';
  color: #214268;
  font-weight: 900;
  margin-right: .4em;
  position: absolute;
  left: 0;
}

ul.circle li {
  line-height: inherit;
  padding-left: 1.2em;
  position: relative;
}

ul.circle li:before {
  font-family: "Font Awesome 5 Pro";
  content: '\f111';
  color: #214268;
  font-weight: 900;
  margin-right: .4em;
  position: absolute;
  left: 0;
}

.list-flex {
  display: flex;
  flex-flow: row wrap;
}

.list-flex li:not(:first-child) {
  margin-left: .5em;
}

ol.num {
  list-style: decimal outside;
  padding-left: 1em;
}

ol.num ol {
  list-style: decimal outside;
  padding-left: 1em;
}

dl dt {
  font-weight: bold;
}

dl dt:not(:first-child) {
  margin-top: 1em;
}

dl dd {
  line-height: 1.4;
}

.label {
  background: #214268;
  border: 1px solid transparent;
  border-radius: 0px;
  color: #fff;
  display: inline-block;
  font-size: 0.875em;
  line-height: 1.42875 !important;
  padding: 0.425em 0.6em;
  text-align: center;
  position: relative;
}

@media (min-width: 813px) {
  .label {
    padding: 0.425em 0.6em;
  }
}

.label.base {
  background: #214268;
  color: #fff;
}

.label.first {
  background: #2a5382;
  color: #fff;
}

.label.second {
  background: #F3EBE7;
  color: #fff;
}

.label.third {
  background: #CEB0A3;
  color: #fff;
}

.label.fourth {
  background: #EDDAD0;
  color: #fff;
}

.label.five {
  background: #FF9D7B;
  color: #fff;
}

.label.pink {
  background: #d43776;
  color: #fff;
}

.label.gray-100 {
  background: #f8f9fa;
}

.label.gray-200 {
  background: #e9ecef;
}

.label.gray-300 {
  background: #dee2e6;
}

.label.gray-400 {
  background: #ced4da;
}

.label.gray-500 {
  background: #adb5bd;
}

.label.gray-600 {
  background: #6c757d;
}

.label.gray-700 {
  background: #495057;
}

.label.gray-800 {
  background: #343a40;
}

.label.gray-900 {
  background: #212529;
}

.label.white {
  background: #fff;
}

.label.black {
  background: #000;
}

.label.red {
  background: #b0120a;
}

.label.yellow {
  background: #fff000;
}

.label.orange {
  background: #f18f2c;
}

.label.green {
  background: #63a764;
}

.label.blue {
  background: #005fcc;
}

.label.pink {
  background: #d43776;
}

.label.outline {
  background: #fff;
  border: 1px solid #214268;
  color: #214268;
}

.label.outline.base {
  border: 1px solid #214268;
  color: #214268;
}

.label.outline.first {
  border: 1px solid #2a5382;
  color: #2a5382;
}

.label.outline.second {
  border: 1px solid #F3EBE7;
  color: #F3EBE7;
}

.label.outline.third {
  border: 1px solid #CEB0A3;
  color: #CEB0A3;
}

.label.outline.fourth {
  border: 1px solid #EDDAD0;
  color: #EDDAD0;
}

.label.outline.five {
  border: 1px solid #FF9D7B;
  color: #FF9D7B;
}

.label.outline.seven {
  border: 1px solid #877166;
  color: #877166;
}

.label.outline.gray-100 {
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}

.label.outline.gray-200 {
  border: 1px solid #e9ecef;
  color: #e9ecef;
}

.label.outline.gray-300 {
  border: 1px solid #dee2e6;
  color: #dee2e6;
}

.label.outline.gray-400 {
  border: 1px solid #ced4da;
  color: #ced4da;
}

.label.outline.gray-500 {
  border: 1px solid #adb5bd;
  color: #adb5bd;
}

.label.outline.gray-600 {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.label.outline.gray-700 {
  border: 1px solid #495057;
  color: #495057;
}

.label.outline.gray-800 {
  border: 1px solid #343a40;
  color: #343a40;
}

.label.outline.gray-900 {
  border: 1px solid #212529;
  color: #212529;
}

.label.outline.white {
  border: 1px solid #fff;
  color: #fff;
}

.label.outline.black {
  border: 1px solid #000;
  color: #000;
}

.label.outline.red {
  border: 1px solid #b0120a;
  color: #b0120a;
}

.label.outline.yellow {
  border: 1px solid #fff000;
  color: #fff000;
}

.label.outline.orange {
  border: 1px solid #f18f2c;
  color: #f18f2c;
}

.label.outline.green {
  border: 1px solid #63a764;
  color: #63a764;
}

.label.outline.blue {
  border: 1px solid #005fcc;
  color: #005fcc;
}

.label.outline.pink {
  border: 1px solid #d43776;
  color: #d43776;
}

.label.expanded {
  display: block;
}

span.label {
  margin-left: .5em;
  margin-right: 0;
}

span.label.left {
  margin-left: 0;
  margin-right: .5em;
}

table.potable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}

table.potable th, table.potable td {
  border: 1px solid #707070;
  padding: .5em 1em;
}

table.potable th {
  background: #BFAA99;
}

table.potable td {
  background: #fff;
  overflow-wrap: break-word;
}

table.potable thead th {
  color: #fff;
}

table.potable tbody th {
  background: #F3EBE7;
}

th.nowrap,
td.nowrap {
  white-space: nowrap;
}

.th-block {
  border-bottom: 1px solid #707070;
}

@media (min-width: 813px) {
  .th-block {
    border: none;
  }
}

.th-block th, .th-block td {
  display: block;
}

@media (min-width: 813px) {
  .th-block th, .th-block td {
    display: table-cell;
  }
}

.th-block th {
  background: #BFAA99;
  border-bottom: none;
  width: 100%;
}

@media (min-width: 813px) {
  .th-block th {
    width: 15%;
  }
}

.th-block td {
  border-bottom: none;
}

@media (min-width: 813px) {
  .th-block td {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
  }
}

.table-scroll-wrapper {
  margin-bottom: 2em;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 813px) {
  .table-scroll-wrapper {
    white-space: normal;
  }
}

.form-field + .form-field {
  margin-top: 1em;
}

@media (min-width: 813px) {
  .form-field + .form-field {
    margin-top: 1em;
  }
}

label {
  display: block;
}

label.item {
  color: inherit;
  font-weight: normal;
  margin-bottom: .5em;
}

label.item-contact {
  border-left: 4px solid #2a5382;
  font-size: .875em;
  margin-bottom: .4em;
  padding-left: .4em;
}

label.item-confirm {
  border-left: 4px solid #2a5382;
  font-size: .875em;
  margin-bottom: .4em;
  padding-left: .4em;
}

.validate_error {
  display: inline-flex;
  align-items: center;
  margin-left: .4em;
}

.validate_error .error {
  color: #b0120a;
  font-size: .875em;
  font-weight: 700;
}

.essential {
  background: #214268;
  color: #fff;
  display: inline-block;
  font-size: .8em;
  margin-left: .5em;
  padding: .2em .5em;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
textarea {
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: none;
  color: #333;
  font-size: 1em;
  padding: .5em 1em;
  line-height: 1.42857143;
  width: 100%;
  transition: border-color 0.2s ease-out, background-color 0.2s ease-out;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']):focus,
textarea:focus {
  background: #fff;
  border-color: #214268;
  box-shadow: 0 0 6px 1px rgba(33, 66, 104, 0.075);
  outline: 0;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']):focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button'])::-moz-placeholder,
textarea::-moz-placeholder {
  color: #999;
  opacity: 1;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #999;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button'])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
}

input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button'])::-ms-expand,
textarea::-ms-expand {
  background-color: #fff;
  border: 0;
}

textarea {
  height: auto;
}

textarea.confirm {
  resize: none;
  overflow: hidden;
}

select {
  width: 100%;
}

select::-moz-focus-inner {
  border: 0;
}

select::-ms-expand {
  display: none;
}

.select {
  display: inline-block;
  position: relative;
}

.select select {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: inherit;
  cursor: pointer;
  display: block;
  font-size: 1em;
  height: 3em;
  line-height: 1.42857143;
  padding: 0 2.5em 0 1em;
  position: relative;
  outline: none;
  overflow: hidden;
}

.select:after {
  border: 1px solid #999;
  border-right: 0;
  border-top: 0;
  content: '';
  display: block;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 1em;
  transform: rotate(-45deg) translateY(-50%);
  width: 6px;
  height: 6px;
}

@media (min-width: 813px) {
  .select:after {
    width: 9px;
    height: 9px;
  }
}

.checkbox-group,
.radio-group {
  display: inline-block;
}

.checkbox-group.row,
.radio-group.row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.checkbox-group.row .label-checkbox + .label-checkbox,
.checkbox-group.row .label-radio + .label-radio,
.radio-group.row .label-checkbox + .label-checkbox,
.radio-group.row .label-radio + .label-radio {
  margin-left: 1em;
}

.checkbox-group.privacy,
.radio-group.privacy {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.label-checkbox input[type='checkbox'],
.label-radio input[type='radio'] {
  cursor: pointer;
}

.label-checkbox,
.label-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.label-checkbox .lever,
.label-radio .lever {
  padding-left: .4em;
}

.mt4 {
  margin-top: 0.4rem !important;
}

@media (min-width: 813px) {
  .mt4 {
    margin-top: 0.37143rem !important;
  }
}

.mb4 {
  margin-bottom: 0.4rem !important;
}

@media (min-width: 813px) {
  .mb4 {
    margin-bottom: 0.37143rem !important;
  }
}

.mr4 {
  margin-right: 0.4rem !important;
}

@media (min-width: 813px) {
  .mr4 {
    margin-right: 0.37143rem !important;
  }
}

.ml4 {
  margin-left: 0.4rem !important;
}

@media (min-width: 813px) {
  .ml4 {
    margin-left: 0.37143rem !important;
  }
}

.pt4 {
  padding-top: 0.4rem !important;
}

@media (min-width: 813px) {
  .pt4 {
    padding-top: 0.37143rem !important;
  }
}

.pb4 {
  padding-bottom: 0.4rem !important;
}

@media (min-width: 813px) {
  .pb4 {
    padding-bottom: 0.37143rem !important;
  }
}

.pr4 {
  padding-right: 0.4rem !important;
}

@media (min-width: 813px) {
  .pr4 {
    padding-right: 0.37143rem !important;
  }
}

.pl4 {
  padding-left: 0.4rem !important;
}

@media (min-width: 813px) {
  .pl4 {
    padding-left: 0.37143rem !important;
  }
}

.mt8 {
  margin-top: 0.8rem !important;
}

@media (min-width: 813px) {
  .mt8 {
    margin-top: 0.74286rem !important;
  }
}

.mb8 {
  margin-bottom: 0.8rem !important;
}

@media (min-width: 813px) {
  .mb8 {
    margin-bottom: 0.74286rem !important;
  }
}

.mr8 {
  margin-right: 0.8rem !important;
}

@media (min-width: 813px) {
  .mr8 {
    margin-right: 0.74286rem !important;
  }
}

.ml8 {
  margin-left: 0.8rem !important;
}

@media (min-width: 813px) {
  .ml8 {
    margin-left: 0.74286rem !important;
  }
}

.pt8 {
  padding-top: 0.8rem !important;
}

@media (min-width: 813px) {
  .pt8 {
    padding-top: 0.74286rem !important;
  }
}

.pb8 {
  padding-bottom: 0.8rem !important;
}

@media (min-width: 813px) {
  .pb8 {
    padding-bottom: 0.74286rem !important;
  }
}

.pr8 {
  padding-right: 0.8rem !important;
}

@media (min-width: 813px) {
  .pr8 {
    padding-right: 0.74286rem !important;
  }
}

.pl8 {
  padding-left: 0.8rem !important;
}

@media (min-width: 813px) {
  .pl8 {
    padding-left: 0.74286rem !important;
  }
}

.mt12 {
  margin-top: 1.2rem !important;
}

@media (min-width: 813px) {
  .mt12 {
    margin-top: 1.11429rem !important;
  }
}

.mb12 {
  margin-bottom: 1.2rem !important;
}

@media (min-width: 813px) {
  .mb12 {
    margin-bottom: 1.11429rem !important;
  }
}

.mr12 {
  margin-right: 1.2rem !important;
}

@media (min-width: 813px) {
  .mr12 {
    margin-right: 1.11429rem !important;
  }
}

.ml12 {
  margin-left: 1.2rem !important;
}

@media (min-width: 813px) {
  .ml12 {
    margin-left: 1.11429rem !important;
  }
}

.pt12 {
  padding-top: 1.2rem !important;
}

@media (min-width: 813px) {
  .pt12 {
    padding-top: 1.11429rem !important;
  }
}

.pb12 {
  padding-bottom: 1.2rem !important;
}

@media (min-width: 813px) {
  .pb12 {
    padding-bottom: 1.11429rem !important;
  }
}

.pr12 {
  padding-right: 1.2rem !important;
}

@media (min-width: 813px) {
  .pr12 {
    padding-right: 1.11429rem !important;
  }
}

.pl12 {
  padding-left: 1.2rem !important;
}

@media (min-width: 813px) {
  .pl12 {
    padding-left: 1.11429rem !important;
  }
}

.mt16 {
  margin-top: 1.6rem !important;
}

@media (min-width: 813px) {
  .mt16 {
    margin-top: 1.48571rem !important;
  }
}

.mb16 {
  margin-bottom: 1.6rem !important;
}

@media (min-width: 813px) {
  .mb16 {
    margin-bottom: 1.48571rem !important;
  }
}

.mr16 {
  margin-right: 1.6rem !important;
}

@media (min-width: 813px) {
  .mr16 {
    margin-right: 1.48571rem !important;
  }
}

.ml16 {
  margin-left: 1.6rem !important;
}

@media (min-width: 813px) {
  .ml16 {
    margin-left: 1.48571rem !important;
  }
}

.pt16 {
  padding-top: 1.6rem !important;
}

@media (min-width: 813px) {
  .pt16 {
    padding-top: 1.48571rem !important;
  }
}

.pb16 {
  padding-bottom: 1.6rem !important;
}

@media (min-width: 813px) {
  .pb16 {
    padding-bottom: 1.48571rem !important;
  }
}

.pr16 {
  padding-right: 1.6rem !important;
}

@media (min-width: 813px) {
  .pr16 {
    padding-right: 1.48571rem !important;
  }
}

.pl16 {
  padding-left: 1.6rem !important;
}

@media (min-width: 813px) {
  .pl16 {
    padding-left: 1.48571rem !important;
  }
}

.mt20 {
  margin-top: 2rem !important;
}

@media (min-width: 813px) {
  .mt20 {
    margin-top: 1.85714rem !important;
  }
}

.mb20 {
  margin-bottom: 2rem !important;
}

@media (min-width: 813px) {
  .mb20 {
    margin-bottom: 1.85714rem !important;
  }
}

.mr20 {
  margin-right: 2rem !important;
}

@media (min-width: 813px) {
  .mr20 {
    margin-right: 1.85714rem !important;
  }
}

.ml20 {
  margin-left: 2rem !important;
}

@media (min-width: 813px) {
  .ml20 {
    margin-left: 1.85714rem !important;
  }
}

.pt20 {
  padding-top: 2rem !important;
}

@media (min-width: 813px) {
  .pt20 {
    padding-top: 1.85714rem !important;
  }
}

.pb20 {
  padding-bottom: 2rem !important;
}

@media (min-width: 813px) {
  .pb20 {
    padding-bottom: 1.85714rem !important;
  }
}

.pr20 {
  padding-right: 2rem !important;
}

@media (min-width: 813px) {
  .pr20 {
    padding-right: 1.85714rem !important;
  }
}

.pl20 {
  padding-left: 2rem !important;
}

@media (min-width: 813px) {
  .pl20 {
    padding-left: 1.85714rem !important;
  }
}

.mt24 {
  margin-top: 2.4rem !important;
}

@media (min-width: 813px) {
  .mt24 {
    margin-top: 2.22857rem !important;
  }
}

.mb24 {
  margin-bottom: 2.4rem !important;
}

@media (min-width: 813px) {
  .mb24 {
    margin-bottom: 2.22857rem !important;
  }
}

.mr24 {
  margin-right: 2.4rem !important;
}

@media (min-width: 813px) {
  .mr24 {
    margin-right: 2.22857rem !important;
  }
}

.ml24 {
  margin-left: 2.4rem !important;
}

@media (min-width: 813px) {
  .ml24 {
    margin-left: 2.22857rem !important;
  }
}

.pt24 {
  padding-top: 2.4rem !important;
}

@media (min-width: 813px) {
  .pt24 {
    padding-top: 2.22857rem !important;
  }
}

.pb24 {
  padding-bottom: 2.4rem !important;
}

@media (min-width: 813px) {
  .pb24 {
    padding-bottom: 2.22857rem !important;
  }
}

.pr24 {
  padding-right: 2.4rem !important;
}

@media (min-width: 813px) {
  .pr24 {
    padding-right: 2.22857rem !important;
  }
}

.pl24 {
  padding-left: 2.4rem !important;
}

@media (min-width: 813px) {
  .pl24 {
    padding-left: 2.22857rem !important;
  }
}

.mt28 {
  margin-top: 2.8rem !important;
}

@media (min-width: 813px) {
  .mt28 {
    margin-top: 2.6rem !important;
  }
}

.mb28 {
  margin-bottom: 2.8rem !important;
}

@media (min-width: 813px) {
  .mb28 {
    margin-bottom: 2.6rem !important;
  }
}

.mr28 {
  margin-right: 2.8rem !important;
}

@media (min-width: 813px) {
  .mr28 {
    margin-right: 2.6rem !important;
  }
}

.ml28 {
  margin-left: 2.8rem !important;
}

@media (min-width: 813px) {
  .ml28 {
    margin-left: 2.6rem !important;
  }
}

.pt28 {
  padding-top: 2.8rem !important;
}

@media (min-width: 813px) {
  .pt28 {
    padding-top: 2.6rem !important;
  }
}

.pb28 {
  padding-bottom: 2.8rem !important;
}

@media (min-width: 813px) {
  .pb28 {
    padding-bottom: 2.6rem !important;
  }
}

.pr28 {
  padding-right: 2.8rem !important;
}

@media (min-width: 813px) {
  .pr28 {
    padding-right: 2.6rem !important;
  }
}

.pl28 {
  padding-left: 2.8rem !important;
}

@media (min-width: 813px) {
  .pl28 {
    padding-left: 2.6rem !important;
  }
}

.mt32 {
  margin-top: 3.2rem !important;
}

@media (min-width: 813px) {
  .mt32 {
    margin-top: 2.97143rem !important;
  }
}

.mb32 {
  margin-bottom: 3.2rem !important;
}

@media (min-width: 813px) {
  .mb32 {
    margin-bottom: 2.97143rem !important;
  }
}

.mr32 {
  margin-right: 3.2rem !important;
}

@media (min-width: 813px) {
  .mr32 {
    margin-right: 2.97143rem !important;
  }
}

.ml32 {
  margin-left: 3.2rem !important;
}

@media (min-width: 813px) {
  .ml32 {
    margin-left: 2.97143rem !important;
  }
}

.pt32 {
  padding-top: 3.2rem !important;
}

@media (min-width: 813px) {
  .pt32 {
    padding-top: 2.97143rem !important;
  }
}

.pb32 {
  padding-bottom: 3.2rem !important;
}

@media (min-width: 813px) {
  .pb32 {
    padding-bottom: 2.97143rem !important;
  }
}

.pr32 {
  padding-right: 3.2rem !important;
}

@media (min-width: 813px) {
  .pr32 {
    padding-right: 2.97143rem !important;
  }
}

.pl32 {
  padding-left: 3.2rem !important;
}

@media (min-width: 813px) {
  .pl32 {
    padding-left: 2.97143rem !important;
  }
}

.mt36 {
  margin-top: 3.6rem !important;
}

@media (min-width: 813px) {
  .mt36 {
    margin-top: 3.34286rem !important;
  }
}

.mb36 {
  margin-bottom: 3.6rem !important;
}

@media (min-width: 813px) {
  .mb36 {
    margin-bottom: 3.34286rem !important;
  }
}

.mr36 {
  margin-right: 3.6rem !important;
}

@media (min-width: 813px) {
  .mr36 {
    margin-right: 3.34286rem !important;
  }
}

.ml36 {
  margin-left: 3.6rem !important;
}

@media (min-width: 813px) {
  .ml36 {
    margin-left: 3.34286rem !important;
  }
}

.pt36 {
  padding-top: 3.6rem !important;
}

@media (min-width: 813px) {
  .pt36 {
    padding-top: 3.34286rem !important;
  }
}

.pb36 {
  padding-bottom: 3.6rem !important;
}

@media (min-width: 813px) {
  .pb36 {
    padding-bottom: 3.34286rem !important;
  }
}

.pr36 {
  padding-right: 3.6rem !important;
}

@media (min-width: 813px) {
  .pr36 {
    padding-right: 3.34286rem !important;
  }
}

.pl36 {
  padding-left: 3.6rem !important;
}

@media (min-width: 813px) {
  .pl36 {
    padding-left: 3.34286rem !important;
  }
}

.mt40 {
  margin-top: 4rem !important;
}

@media (min-width: 813px) {
  .mt40 {
    margin-top: 3.71429rem !important;
  }
}

.mb40 {
  margin-bottom: 4rem !important;
}

@media (min-width: 813px) {
  .mb40 {
    margin-bottom: 3.71429rem !important;
  }
}

.mr40 {
  margin-right: 4rem !important;
}

@media (min-width: 813px) {
  .mr40 {
    margin-right: 3.71429rem !important;
  }
}

.ml40 {
  margin-left: 4rem !important;
}

@media (min-width: 813px) {
  .ml40 {
    margin-left: 3.71429rem !important;
  }
}

.pt40 {
  padding-top: 4rem !important;
}

@media (min-width: 813px) {
  .pt40 {
    padding-top: 3.71429rem !important;
  }
}

.pb40 {
  padding-bottom: 4rem !important;
}

@media (min-width: 813px) {
  .pb40 {
    padding-bottom: 3.71429rem !important;
  }
}

.pr40 {
  padding-right: 4rem !important;
}

@media (min-width: 813px) {
  .pr40 {
    padding-right: 3.71429rem !important;
  }
}

.pl40 {
  padding-left: 4rem !important;
}

@media (min-width: 813px) {
  .pl40 {
    padding-left: 3.71429rem !important;
  }
}

.mt44 {
  margin-top: 4.4rem !important;
}

@media (min-width: 813px) {
  .mt44 {
    margin-top: 4.08571rem !important;
  }
}

.mb44 {
  margin-bottom: 4.4rem !important;
}

@media (min-width: 813px) {
  .mb44 {
    margin-bottom: 4.08571rem !important;
  }
}

.mr44 {
  margin-right: 4.4rem !important;
}

@media (min-width: 813px) {
  .mr44 {
    margin-right: 4.08571rem !important;
  }
}

.ml44 {
  margin-left: 4.4rem !important;
}

@media (min-width: 813px) {
  .ml44 {
    margin-left: 4.08571rem !important;
  }
}

.pt44 {
  padding-top: 4.4rem !important;
}

@media (min-width: 813px) {
  .pt44 {
    padding-top: 4.08571rem !important;
  }
}

.pb44 {
  padding-bottom: 4.4rem !important;
}

@media (min-width: 813px) {
  .pb44 {
    padding-bottom: 4.08571rem !important;
  }
}

.pr44 {
  padding-right: 4.4rem !important;
}

@media (min-width: 813px) {
  .pr44 {
    padding-right: 4.08571rem !important;
  }
}

.pl44 {
  padding-left: 4.4rem !important;
}

@media (min-width: 813px) {
  .pl44 {
    padding-left: 4.08571rem !important;
  }
}

.mt48 {
  margin-top: 4.8rem !important;
}

@media (min-width: 813px) {
  .mt48 {
    margin-top: 4.45714rem !important;
  }
}

.mb48 {
  margin-bottom: 4.8rem !important;
}

@media (min-width: 813px) {
  .mb48 {
    margin-bottom: 4.45714rem !important;
  }
}

.mr48 {
  margin-right: 4.8rem !important;
}

@media (min-width: 813px) {
  .mr48 {
    margin-right: 4.45714rem !important;
  }
}

.ml48 {
  margin-left: 4.8rem !important;
}

@media (min-width: 813px) {
  .ml48 {
    margin-left: 4.45714rem !important;
  }
}

.pt48 {
  padding-top: 4.8rem !important;
}

@media (min-width: 813px) {
  .pt48 {
    padding-top: 4.45714rem !important;
  }
}

.pb48 {
  padding-bottom: 4.8rem !important;
}

@media (min-width: 813px) {
  .pb48 {
    padding-bottom: 4.45714rem !important;
  }
}

.pr48 {
  padding-right: 4.8rem !important;
}

@media (min-width: 813px) {
  .pr48 {
    padding-right: 4.45714rem !important;
  }
}

.pl48 {
  padding-left: 4.8rem !important;
}

@media (min-width: 813px) {
  .pl48 {
    padding-left: 4.45714rem !important;
  }
}

.mt52 {
  margin-top: 5.2rem !important;
}

@media (min-width: 813px) {
  .mt52 {
    margin-top: 4.82857rem !important;
  }
}

.mb52 {
  margin-bottom: 5.2rem !important;
}

@media (min-width: 813px) {
  .mb52 {
    margin-bottom: 4.82857rem !important;
  }
}

.mr52 {
  margin-right: 5.2rem !important;
}

@media (min-width: 813px) {
  .mr52 {
    margin-right: 4.82857rem !important;
  }
}

.ml52 {
  margin-left: 5.2rem !important;
}

@media (min-width: 813px) {
  .ml52 {
    margin-left: 4.82857rem !important;
  }
}

.pt52 {
  padding-top: 5.2rem !important;
}

@media (min-width: 813px) {
  .pt52 {
    padding-top: 4.82857rem !important;
  }
}

.pb52 {
  padding-bottom: 5.2rem !important;
}

@media (min-width: 813px) {
  .pb52 {
    padding-bottom: 4.82857rem !important;
  }
}

.pr52 {
  padding-right: 5.2rem !important;
}

@media (min-width: 813px) {
  .pr52 {
    padding-right: 4.82857rem !important;
  }
}

.pl52 {
  padding-left: 5.2rem !important;
}

@media (min-width: 813px) {
  .pl52 {
    padding-left: 4.82857rem !important;
  }
}

.mt56 {
  margin-top: 5.6rem !important;
}

@media (min-width: 813px) {
  .mt56 {
    margin-top: 5.2rem !important;
  }
}

.mb56 {
  margin-bottom: 5.6rem !important;
}

@media (min-width: 813px) {
  .mb56 {
    margin-bottom: 5.2rem !important;
  }
}

.mr56 {
  margin-right: 5.6rem !important;
}

@media (min-width: 813px) {
  .mr56 {
    margin-right: 5.2rem !important;
  }
}

.ml56 {
  margin-left: 5.6rem !important;
}

@media (min-width: 813px) {
  .ml56 {
    margin-left: 5.2rem !important;
  }
}

.pt56 {
  padding-top: 5.6rem !important;
}

@media (min-width: 813px) {
  .pt56 {
    padding-top: 5.2rem !important;
  }
}

.pb56 {
  padding-bottom: 5.6rem !important;
}

@media (min-width: 813px) {
  .pb56 {
    padding-bottom: 5.2rem !important;
  }
}

.pr56 {
  padding-right: 5.6rem !important;
}

@media (min-width: 813px) {
  .pr56 {
    padding-right: 5.2rem !important;
  }
}

.pl56 {
  padding-left: 5.6rem !important;
}

@media (min-width: 813px) {
  .pl56 {
    padding-left: 5.2rem !important;
  }
}

.mt60 {
  margin-top: 6rem !important;
}

@media (min-width: 813px) {
  .mt60 {
    margin-top: 5.57143rem !important;
  }
}

.mb60 {
  margin-bottom: 6rem !important;
}

@media (min-width: 813px) {
  .mb60 {
    margin-bottom: 5.57143rem !important;
  }
}

.mr60 {
  margin-right: 6rem !important;
}

@media (min-width: 813px) {
  .mr60 {
    margin-right: 5.57143rem !important;
  }
}

.ml60 {
  margin-left: 6rem !important;
}

@media (min-width: 813px) {
  .ml60 {
    margin-left: 5.57143rem !important;
  }
}

.pt60 {
  padding-top: 6rem !important;
}

@media (min-width: 813px) {
  .pt60 {
    padding-top: 5.57143rem !important;
  }
}

.pb60 {
  padding-bottom: 6rem !important;
}

@media (min-width: 813px) {
  .pb60 {
    padding-bottom: 5.57143rem !important;
  }
}

.pr60 {
  padding-right: 6rem !important;
}

@media (min-width: 813px) {
  .pr60 {
    padding-right: 5.57143rem !important;
  }
}

.pl60 {
  padding-left: 6rem !important;
}

@media (min-width: 813px) {
  .pl60 {
    padding-left: 5.57143rem !important;
  }
}

.mt64 {
  margin-top: 6.4rem !important;
}

@media (min-width: 813px) {
  .mt64 {
    margin-top: 5.94286rem !important;
  }
}

.mb64 {
  margin-bottom: 6.4rem !important;
}

@media (min-width: 813px) {
  .mb64 {
    margin-bottom: 5.94286rem !important;
  }
}

.mr64 {
  margin-right: 6.4rem !important;
}

@media (min-width: 813px) {
  .mr64 {
    margin-right: 5.94286rem !important;
  }
}

.ml64 {
  margin-left: 6.4rem !important;
}

@media (min-width: 813px) {
  .ml64 {
    margin-left: 5.94286rem !important;
  }
}

.pt64 {
  padding-top: 6.4rem !important;
}

@media (min-width: 813px) {
  .pt64 {
    padding-top: 5.94286rem !important;
  }
}

.pb64 {
  padding-bottom: 6.4rem !important;
}

@media (min-width: 813px) {
  .pb64 {
    padding-bottom: 5.94286rem !important;
  }
}

.pr64 {
  padding-right: 6.4rem !important;
}

@media (min-width: 813px) {
  .pr64 {
    padding-right: 5.94286rem !important;
  }
}

.pl64 {
  padding-left: 6.4rem !important;
}

@media (min-width: 813px) {
  .pl64 {
    padding-left: 5.94286rem !important;
  }
}

.mt68 {
  margin-top: 6.8rem !important;
}

@media (min-width: 813px) {
  .mt68 {
    margin-top: 6.31429rem !important;
  }
}

.mb68 {
  margin-bottom: 6.8rem !important;
}

@media (min-width: 813px) {
  .mb68 {
    margin-bottom: 6.31429rem !important;
  }
}

.mr68 {
  margin-right: 6.8rem !important;
}

@media (min-width: 813px) {
  .mr68 {
    margin-right: 6.31429rem !important;
  }
}

.ml68 {
  margin-left: 6.8rem !important;
}

@media (min-width: 813px) {
  .ml68 {
    margin-left: 6.31429rem !important;
  }
}

.pt68 {
  padding-top: 6.8rem !important;
}

@media (min-width: 813px) {
  .pt68 {
    padding-top: 6.31429rem !important;
  }
}

.pb68 {
  padding-bottom: 6.8rem !important;
}

@media (min-width: 813px) {
  .pb68 {
    padding-bottom: 6.31429rem !important;
  }
}

.pr68 {
  padding-right: 6.8rem !important;
}

@media (min-width: 813px) {
  .pr68 {
    padding-right: 6.31429rem !important;
  }
}

.pl68 {
  padding-left: 6.8rem !important;
}

@media (min-width: 813px) {
  .pl68 {
    padding-left: 6.31429rem !important;
  }
}

.mt72 {
  margin-top: 7.2rem !important;
}

@media (min-width: 813px) {
  .mt72 {
    margin-top: 6.68571rem !important;
  }
}

.mb72 {
  margin-bottom: 7.2rem !important;
}

@media (min-width: 813px) {
  .mb72 {
    margin-bottom: 6.68571rem !important;
  }
}

.mr72 {
  margin-right: 7.2rem !important;
}

@media (min-width: 813px) {
  .mr72 {
    margin-right: 6.68571rem !important;
  }
}

.ml72 {
  margin-left: 7.2rem !important;
}

@media (min-width: 813px) {
  .ml72 {
    margin-left: 6.68571rem !important;
  }
}

.pt72 {
  padding-top: 7.2rem !important;
}

@media (min-width: 813px) {
  .pt72 {
    padding-top: 6.68571rem !important;
  }
}

.pb72 {
  padding-bottom: 7.2rem !important;
}

@media (min-width: 813px) {
  .pb72 {
    padding-bottom: 6.68571rem !important;
  }
}

.pr72 {
  padding-right: 7.2rem !important;
}

@media (min-width: 813px) {
  .pr72 {
    padding-right: 6.68571rem !important;
  }
}

.pl72 {
  padding-left: 7.2rem !important;
}

@media (min-width: 813px) {
  .pl72 {
    padding-left: 6.68571rem !important;
  }
}

.mt76 {
  margin-top: 7.6rem !important;
}

@media (min-width: 813px) {
  .mt76 {
    margin-top: 7.05714rem !important;
  }
}

.mb76 {
  margin-bottom: 7.6rem !important;
}

@media (min-width: 813px) {
  .mb76 {
    margin-bottom: 7.05714rem !important;
  }
}

.mr76 {
  margin-right: 7.6rem !important;
}

@media (min-width: 813px) {
  .mr76 {
    margin-right: 7.05714rem !important;
  }
}

.ml76 {
  margin-left: 7.6rem !important;
}

@media (min-width: 813px) {
  .ml76 {
    margin-left: 7.05714rem !important;
  }
}

.pt76 {
  padding-top: 7.6rem !important;
}

@media (min-width: 813px) {
  .pt76 {
    padding-top: 7.05714rem !important;
  }
}

.pb76 {
  padding-bottom: 7.6rem !important;
}

@media (min-width: 813px) {
  .pb76 {
    padding-bottom: 7.05714rem !important;
  }
}

.pr76 {
  padding-right: 7.6rem !important;
}

@media (min-width: 813px) {
  .pr76 {
    padding-right: 7.05714rem !important;
  }
}

.pl76 {
  padding-left: 7.6rem !important;
}

@media (min-width: 813px) {
  .pl76 {
    padding-left: 7.05714rem !important;
  }
}

.mt80 {
  margin-top: 8rem !important;
}

@media (min-width: 813px) {
  .mt80 {
    margin-top: 7.42857rem !important;
  }
}

.mb80 {
  margin-bottom: 8rem !important;
}

@media (min-width: 813px) {
  .mb80 {
    margin-bottom: 7.42857rem !important;
  }
}

.mr80 {
  margin-right: 8rem !important;
}

@media (min-width: 813px) {
  .mr80 {
    margin-right: 7.42857rem !important;
  }
}

.ml80 {
  margin-left: 8rem !important;
}

@media (min-width: 813px) {
  .ml80 {
    margin-left: 7.42857rem !important;
  }
}

.pt80 {
  padding-top: 8rem !important;
}

@media (min-width: 813px) {
  .pt80 {
    padding-top: 7.42857rem !important;
  }
}

.pb80 {
  padding-bottom: 8rem !important;
}

@media (min-width: 813px) {
  .pb80 {
    padding-bottom: 7.42857rem !important;
  }
}

.pr80 {
  padding-right: 8rem !important;
}

@media (min-width: 813px) {
  .pr80 {
    padding-right: 7.42857rem !important;
  }
}

.pl80 {
  padding-left: 8rem !important;
}

@media (min-width: 813px) {
  .pl80 {
    padding-left: 7.42857rem !important;
  }
}

.mt84 {
  margin-top: 8.4rem !important;
}

@media (min-width: 813px) {
  .mt84 {
    margin-top: 7.8rem !important;
  }
}

.mb84 {
  margin-bottom: 8.4rem !important;
}

@media (min-width: 813px) {
  .mb84 {
    margin-bottom: 7.8rem !important;
  }
}

.mr84 {
  margin-right: 8.4rem !important;
}

@media (min-width: 813px) {
  .mr84 {
    margin-right: 7.8rem !important;
  }
}

.ml84 {
  margin-left: 8.4rem !important;
}

@media (min-width: 813px) {
  .ml84 {
    margin-left: 7.8rem !important;
  }
}

.pt84 {
  padding-top: 8.4rem !important;
}

@media (min-width: 813px) {
  .pt84 {
    padding-top: 7.8rem !important;
  }
}

.pb84 {
  padding-bottom: 8.4rem !important;
}

@media (min-width: 813px) {
  .pb84 {
    padding-bottom: 7.8rem !important;
  }
}

.pr84 {
  padding-right: 8.4rem !important;
}

@media (min-width: 813px) {
  .pr84 {
    padding-right: 7.8rem !important;
  }
}

.pl84 {
  padding-left: 8.4rem !important;
}

@media (min-width: 813px) {
  .pl84 {
    padding-left: 7.8rem !important;
  }
}

.mt88 {
  margin-top: 8.8rem !important;
}

@media (min-width: 813px) {
  .mt88 {
    margin-top: 8.17143rem !important;
  }
}

.mb88 {
  margin-bottom: 8.8rem !important;
}

@media (min-width: 813px) {
  .mb88 {
    margin-bottom: 8.17143rem !important;
  }
}

.mr88 {
  margin-right: 8.8rem !important;
}

@media (min-width: 813px) {
  .mr88 {
    margin-right: 8.17143rem !important;
  }
}

.ml88 {
  margin-left: 8.8rem !important;
}

@media (min-width: 813px) {
  .ml88 {
    margin-left: 8.17143rem !important;
  }
}

.pt88 {
  padding-top: 8.8rem !important;
}

@media (min-width: 813px) {
  .pt88 {
    padding-top: 8.17143rem !important;
  }
}

.pb88 {
  padding-bottom: 8.8rem !important;
}

@media (min-width: 813px) {
  .pb88 {
    padding-bottom: 8.17143rem !important;
  }
}

.pr88 {
  padding-right: 8.8rem !important;
}

@media (min-width: 813px) {
  .pr88 {
    padding-right: 8.17143rem !important;
  }
}

.pl88 {
  padding-left: 8.8rem !important;
}

@media (min-width: 813px) {
  .pl88 {
    padding-left: 8.17143rem !important;
  }
}

.mt92 {
  margin-top: 9.2rem !important;
}

@media (min-width: 813px) {
  .mt92 {
    margin-top: 8.54286rem !important;
  }
}

.mb92 {
  margin-bottom: 9.2rem !important;
}

@media (min-width: 813px) {
  .mb92 {
    margin-bottom: 8.54286rem !important;
  }
}

.mr92 {
  margin-right: 9.2rem !important;
}

@media (min-width: 813px) {
  .mr92 {
    margin-right: 8.54286rem !important;
  }
}

.ml92 {
  margin-left: 9.2rem !important;
}

@media (min-width: 813px) {
  .ml92 {
    margin-left: 8.54286rem !important;
  }
}

.pt92 {
  padding-top: 9.2rem !important;
}

@media (min-width: 813px) {
  .pt92 {
    padding-top: 8.54286rem !important;
  }
}

.pb92 {
  padding-bottom: 9.2rem !important;
}

@media (min-width: 813px) {
  .pb92 {
    padding-bottom: 8.54286rem !important;
  }
}

.pr92 {
  padding-right: 9.2rem !important;
}

@media (min-width: 813px) {
  .pr92 {
    padding-right: 8.54286rem !important;
  }
}

.pl92 {
  padding-left: 9.2rem !important;
}

@media (min-width: 813px) {
  .pl92 {
    padding-left: 8.54286rem !important;
  }
}

.mt96 {
  margin-top: 9.6rem !important;
}

@media (min-width: 813px) {
  .mt96 {
    margin-top: 8.91429rem !important;
  }
}

.mb96 {
  margin-bottom: 9.6rem !important;
}

@media (min-width: 813px) {
  .mb96 {
    margin-bottom: 8.91429rem !important;
  }
}

.mr96 {
  margin-right: 9.6rem !important;
}

@media (min-width: 813px) {
  .mr96 {
    margin-right: 8.91429rem !important;
  }
}

.ml96 {
  margin-left: 9.6rem !important;
}

@media (min-width: 813px) {
  .ml96 {
    margin-left: 8.91429rem !important;
  }
}

.pt96 {
  padding-top: 9.6rem !important;
}

@media (min-width: 813px) {
  .pt96 {
    padding-top: 8.91429rem !important;
  }
}

.pb96 {
  padding-bottom: 9.6rem !important;
}

@media (min-width: 813px) {
  .pb96 {
    padding-bottom: 8.91429rem !important;
  }
}

.pr96 {
  padding-right: 9.6rem !important;
}

@media (min-width: 813px) {
  .pr96 {
    padding-right: 8.91429rem !important;
  }
}

.pl96 {
  padding-left: 9.6rem !important;
}

@media (min-width: 813px) {
  .pl96 {
    padding-left: 8.91429rem !important;
  }
}

.mt100 {
  margin-top: 10rem !important;
}

@media (min-width: 813px) {
  .mt100 {
    margin-top: 9.28571rem !important;
  }
}

.mb100 {
  margin-bottom: 10rem !important;
}

@media (min-width: 813px) {
  .mb100 {
    margin-bottom: 9.28571rem !important;
  }
}

.mr100 {
  margin-right: 10rem !important;
}

@media (min-width: 813px) {
  .mr100 {
    margin-right: 9.28571rem !important;
  }
}

.ml100 {
  margin-left: 10rem !important;
}

@media (min-width: 813px) {
  .ml100 {
    margin-left: 9.28571rem !important;
  }
}

.pt100 {
  padding-top: 10rem !important;
}

@media (min-width: 813px) {
  .pt100 {
    padding-top: 9.28571rem !important;
  }
}

.pb100 {
  padding-bottom: 10rem !important;
}

@media (min-width: 813px) {
  .pb100 {
    padding-bottom: 9.28571rem !important;
  }
}

.pr100 {
  padding-right: 10rem !important;
}

@media (min-width: 813px) {
  .pr100 {
    padding-right: 9.28571rem !important;
  }
}

.pl100 {
  padding-left: 10rem !important;
}

@media (min-width: 813px) {
  .pl100 {
    padding-left: 9.28571rem !important;
  }
}

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.anchor-adj {
  padding-top: 16px;
  margin-top: -16px;
}

@media (min-width: 813px) {
  .anchor-adj {
    padding-top: 1em;
    margin-top: -1em;
  }
}

.no-decoration {
  text-decoration: none !important;
}

.lh-10 {
  line-height: 1 !important;
}

.lh-11 {
  line-height: 1.1 !important;
}

.lh-12 {
  line-height: 1.2 !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.lh-14 {
  line-height: 1.4 !important;
}

.lh-15 {
  line-height: 1.5 !important;
}

.lh-16 {
  line-height: 1.6 !important;
}

.lh-17 {
  line-height: 1.7 !important;
}

.lh-18 {
  line-height: 1.8 !important;
}

.lh-19 {
  line-height: 1.9 !important;
}

.lh-20 {
  line-height: 2 !important;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* ==========================================================
  Top Page
========================================================== */
/* .slider-banner
---------------------------------------------------------- */
.slider-banner-wrap {
  position: relative;
}

@media (max-width: 812px) {
  .slider-banner-wrap {
    padding: 0 15px;
  }
}

.slider-banner-wrap .arrow {
  display: block;
  position: absolute;
  font-size: 2.8rem;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s;
  cursor: pointer;
  z-index: 4;
}

@media (max-width: 812px) {
  .slider-banner-wrap .arrow {
    font-size: 1.4rem;
  }
}

.slider-banner-wrap .arrow:hover {
  opacity: 0.7;
}

.slider-banner-wrap .arrow-left {
  left: -25px;
}

@media (max-width: 812px) {
  .slider-banner-wrap .arrow-left {
    left: 0;
  }
}

.slider-banner-wrap .arrow-right {
  right: -25px;
}

@media (max-width: 812px) {
  .slider-banner-wrap .arrow-right {
    right: 0;
  }
}

.slider-banner {
  overflow: hidden;
}

.slider-banner .item {
  padding: 0 10px;
}

@media (max-width: 812px) {
  .slider-banner .item {
    padding: 0 8px;
  }
}

.slider-banner a {
  display: block;
  outline: none;
}

.slider-banner img {
  width: 100%;
}

.slider-banner .slick-list {
  margin: 0 -10px;
  overflow: hidden !important;
}

@media (max-width: 812px) {
  .slider-banner .slick-list {
    margin: 0 -8px;
  }
}

@media (min-width: 813px) {
  .slider-main {
    margin-top: 0;
  }
}

.top-slider-wrapper {
  position: relative;
}

.canvas-wrapper {
  background: #214268;
  opacity: .6;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.top-slider-copy {
  display: block;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  z-index: 2;
}

@media (min-width: 813px) {
  .top-slider-copy {
    top: 60%;
    width: 36%;
  }
}

.top-slider-copy img {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.background-hexagon {
  background: transparent;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.regimen-list-wrapper {
  width: 100%;
}

dl.regimen-list {
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  line-height: 1.4;
}

dl.regimen-list dt {
  display: flex;
  flex: 0 0 30%;
  align-items: center;
  justify-content: center;
}

dl.regimen-list dt.dt-bg_1 {
  background: #D6F5C6;
}

dl.regimen-list dt.dt-bg_2 {
  background: #FFE17F;
}

dl.regimen-list dt.dt-bg_3 {
  background: #C3DAFF;
}

dl.regimen-list dt.dt-bg_4 {
  background: #FFE17F;
}

dl.regimen-list dt.dt-bg_5 {
  background: #FFFEA8;
}

dl.regimen-list dt.dt-bg_6 {
  background: #FFD4D5;
}

dl.regimen-list dt.dt-bg_7 {
  background: #D9D1F9;
}

dl.regimen-list dt.dt-bg_8 {
  background: #D6F5C6;
}

dl.regimen-list dd {
  border-left: 1px solid #ced4da;
  flex: 0 0 70%;
}

dl.regimen-list dd div {
  padding: 1.2rem 3.6rem 1.2rem 1.2rem;
}

dl.regimen-list dd div + div {
  border-top: 1px solid #ced4da;
}

dl.regimen-list dd a {
  display: inline-block;
  position: relative;
}

dl.regimen-list dd a:after {
  color: #2a5382;
  content: '\f1c1';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-size: 1.4em;
  font-weight: 400;
  margin-left: .4em;
  position: absolute;
  top: 50%;
  right: -2.4rem;
  transform: translateY(-50%);
}

dl.regimen-list._header {
  border-top: 1px solid #ced4da;
  text-align: center;
}

dl.regimen-list._header dt, dl.regimen-list._header dd {
  background: #ccedff;
  padding: .5em 0;
}

@media (min-width: 813px) {
  dl.regimen-list dt {
    flex: 0 0 20%;
  }
  dl.regimen-list dd {
    flex: 0 0 80%;
  }
}

.reception-time li {
  display: flex;
}

.reception-time li span {
  display: block;
  width: 50px;
}

@media (min-width: 813px) {
  .reception-time li span {
    width: 80px;
  }
}

.tel-anchor {
  display: inline-block;
  position: relative;
}

.tel-anchor:before {
  content: '\f2a0';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-right: .4em;
}

.button-schedule-min {
  background: #214268;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: inherit;
  line-height: 1.42875 !important;
  margin-top: 1em;
  padding: 6px 10px;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  vertical-align: middle;
}

.button-schedule-min span {
  display: inline-block;
  padding-right: 20px;
  position: relative;
}

.button-schedule-min span::after {
  font-family: "Font Awesome 5 Pro";
  content: "";
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
  font-weight: 900;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tel-re-exam-number {
  font-size: 24px;
  margin-bottom: 12px;
}

@media (min-width: 813px) {
  .tel-re-exam-number {
    font-size: 32px;
  }
}

.approve-negative-wrapper {
  display: flex;
  margin: 32px -8px 0;
}

.approve-container, .negative-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  width: 50%;
  padding: 0 8px;
}

.an-icon-header {
  margin-bottom: 16px;
  text-align: center;
}

.an-icon-header img {
  height: 36px;
  width: auto;
}

@media (min-width: 813px) {
  .an-icon-header {
    text-align: left;
  }
  .an-icon-header img {
    height: 52px;
  }
}

.an-arrow-icon {
  color: #ceb0a3;
  font-size: 28px;
  padding: 12px 0;
  text-align: center;
}

.an-arrow-icon.second {
  color: #e1c8b9;
}

@media (min-width: 813px) {
  .an-arrow-icon {
    font-size: 36px;
    padding: 20px 0;
  }
  .an-arrow-icon.second {
    padding: 48px 0 20px;
  }
}

.an-block {
  border: 3px solid #abc493;
  border-radius: 4px;
  height: 100%;
}

.an-block.second {
  border-color: #ceb0a3;
}

.an-block.third {
  border-color: #c49893;
}

.an-block-header {
  background: #abc493;
  color: #fff;
  padding: 4px 12px;
}

.second .an-block-header {
  background: #ceb0a3;
}

.third .an-block-header {
  background: #c49893;
}

.an-block-body {
  padding: 16px 12px;
}

@media (min-width: 813px) {
  .an-block-body {
    padding: 24px 16px;
  }
}

.an-h-negative {
  background: #e1c8b9;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 4px;
}

@media (min-width: 813px) {
  .an-h-negative {
    margin-bottom: 20px;
    padding: 6px 16px;
  }
}

.schedule_content .doctor span {
  display: block;
  font-size: 60%;
  line-height: 1;
}

.schedule_content .comment, .schedule_content .comment_sub {
  font-size: 60%;
}

.schedule_content + .schedule_content {
  margin-top: 1em;
}

.schedule_content a {
  color: inherit;
  text-decoration: underline;
}

.schedule_content a:hover {
  text-decoration: none;
}

.schedule-button-wrapper {
  margin-bottom: 2em;
  text-align: left;
}

.schedule-heading-container {
  display: block;
  margin-bottom: 1em;
  text-align: right;
}

@media (min-width: 813px) {
  .schedule-heading-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.schedule-heading-container h2 {
  padding-bottom: 1em;
  text-align: left;
}

@media (min-width: 813px) {
  .schedule-heading-container h2 {
    padding-bottom: 0;
  }
}

.schedule-heading-department {
  border-bottom: #EDDAD0 2px solid;
  display: block;
  margin-bottom: 1em;
  padding-bottom: .5em;
  text-align: right;
}

@media (min-width: 813px) {
  .schedule-heading-department {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
}

.schedule-heading-department h3 {
  border-bottom: none;
  font-size: 2rem;
  margin: 0;
  padding-bottom: .5em;
  text-align: left;
}

@media (min-width: 813px) {
  .schedule-heading-department h3 {
    padding-bottom: 0;
  }
}

.schedule-pdf-download-button {
  background: #214268;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: .5em 1em;
}

.schedule-pdf-download-button i {
  margin-right: .5em;
}

.dr-wrap {
  display: block;
  font-size: 1.5em;
}

.dr-position {
  color: #6c757d;
  font-size: .8em;
  margin-bottom: .5em;
  margin-right: 1em;
}

.dr-name {
  display: flex;
  align-items: flex-end;
}

.dr-name .dr-kana {
  display: block;
  font-size: .625em;
  margin-bottom: .2em;
}

.dr-name a, .dr-name .no-anchor {
  line-height: 1.2;
  text-decoration: none;
}

.dr-sub {
  color: #6c757d;
  font-size: .6em;
}

/*
.dr-wrap{
      display: flex;
      align-items: flex-end;
      font-size: 1.5em;
      .dr-position{
        margin-right: 1em;
        color: $gray-600;
        font-size: .8em;
      }
      .dr-name{
        .dr-kana{
          display: block;
          font-size: 0.625em;
        }
      }
      @include mq-sp {
        font-size: 1.5em;
      }
    }
    */
/* Slider */
.slick-loading .slick-list {
  background: #fff;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ==========================================================
  utilities & helper
========================================================== */
/* display
---------------------------------------------------------- */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

@media (min-width: 813px) {
  .d-pc-none {
    display: none !important;
  }
  .d-pc-inline {
    display: inline !important;
  }
  .d-pc-inline-block {
    display: inline-block !important;
  }
  .d-pc-block {
    display: block !important;
  }
  .d-pc-flex {
    display: flex !important;
  }
  .d-pc-table {
    display: table !important;
  }
  .d-pc-table-cell {
    display: table-cell !important;
  }
}

@media (max-width: 812px) {
  .d-sp-inline {
    display: inline !important;
  }
  .d-sp-inline-block {
    display: inline-block !important;
  }
  .d-sp-block {
    display: block !important;
  }
  .d-sp-flex {
    display: flex !important;
  }
  .d-sp-table {
    display: table !important;
  }
  .d-sp-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 813px) {
  .only-sp {
    display: none !important;
  }
}

@media (max-width: 812px) {
  .only-pc {
    display: none !important;
  }
}

/* font style
---------------------------------------------------------- */
.fw-bold {
  font-weight: bold !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* text align
---------------------------------------------------------- */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* spacing
---------------------------------------------------------- */
.mt-0 {
  margin-top: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

@media (min-width: 813px) {
  .mt-pc-0 {
    margin-top: 0px !important;
  }
  .pt-pc-0 {
    padding-top: 0px !important;
  }
  .mb-pc-0 {
    margin-bottom: 0px !important;
  }
  .pb-pc-0 {
    padding-bottom: 0px !important;
  }
  .ml-pc-0 {
    margin-left: 0px !important;
  }
  .pl-pc-0 {
    padding-left: 0px !important;
  }
  .mr-pc-0 {
    margin-right: 0px !important;
  }
  .pr-pc-0 {
    padding-right: 0px !important;
  }
  .mt-pc-5 {
    margin-top: 5px !important;
  }
  .pt-pc-5 {
    padding-top: 5px !important;
  }
  .mb-pc-5 {
    margin-bottom: 5px !important;
  }
  .pb-pc-5 {
    padding-bottom: 5px !important;
  }
  .ml-pc-5 {
    margin-left: 5px !important;
  }
  .pl-pc-5 {
    padding-left: 5px !important;
  }
  .mr-pc-5 {
    margin-right: 5px !important;
  }
  .pr-pc-5 {
    padding-right: 5px !important;
  }
  .mt-pc-10 {
    margin-top: 10px !important;
  }
  .pt-pc-10 {
    padding-top: 10px !important;
  }
  .mb-pc-10 {
    margin-bottom: 10px !important;
  }
  .pb-pc-10 {
    padding-bottom: 10px !important;
  }
  .ml-pc-10 {
    margin-left: 10px !important;
  }
  .pl-pc-10 {
    padding-left: 10px !important;
  }
  .mr-pc-10 {
    margin-right: 10px !important;
  }
  .pr-pc-10 {
    padding-right: 10px !important;
  }
  .mt-pc-15 {
    margin-top: 15px !important;
  }
  .pt-pc-15 {
    padding-top: 15px !important;
  }
  .mb-pc-15 {
    margin-bottom: 15px !important;
  }
  .pb-pc-15 {
    padding-bottom: 15px !important;
  }
  .ml-pc-15 {
    margin-left: 15px !important;
  }
  .pl-pc-15 {
    padding-left: 15px !important;
  }
  .mr-pc-15 {
    margin-right: 15px !important;
  }
  .pr-pc-15 {
    padding-right: 15px !important;
  }
  .mt-pc-20 {
    margin-top: 20px !important;
  }
  .pt-pc-20 {
    padding-top: 20px !important;
  }
  .mb-pc-20 {
    margin-bottom: 20px !important;
  }
  .pb-pc-20 {
    padding-bottom: 20px !important;
  }
  .ml-pc-20 {
    margin-left: 20px !important;
  }
  .pl-pc-20 {
    padding-left: 20px !important;
  }
  .mr-pc-20 {
    margin-right: 20px !important;
  }
  .pr-pc-20 {
    padding-right: 20px !important;
  }
  .mt-pc-25 {
    margin-top: 25px !important;
  }
  .pt-pc-25 {
    padding-top: 25px !important;
  }
  .mb-pc-25 {
    margin-bottom: 25px !important;
  }
  .pb-pc-25 {
    padding-bottom: 25px !important;
  }
  .ml-pc-25 {
    margin-left: 25px !important;
  }
  .pl-pc-25 {
    padding-left: 25px !important;
  }
  .mr-pc-25 {
    margin-right: 25px !important;
  }
  .pr-pc-25 {
    padding-right: 25px !important;
  }
  .mt-pc-30 {
    margin-top: 30px !important;
  }
  .pt-pc-30 {
    padding-top: 30px !important;
  }
  .mb-pc-30 {
    margin-bottom: 30px !important;
  }
  .pb-pc-30 {
    padding-bottom: 30px !important;
  }
  .ml-pc-30 {
    margin-left: 30px !important;
  }
  .pl-pc-30 {
    padding-left: 30px !important;
  }
  .mr-pc-30 {
    margin-right: 30px !important;
  }
  .pr-pc-30 {
    padding-right: 30px !important;
  }
  .mt-pc-35 {
    margin-top: 35px !important;
  }
  .pt-pc-35 {
    padding-top: 35px !important;
  }
  .mb-pc-35 {
    margin-bottom: 35px !important;
  }
  .pb-pc-35 {
    padding-bottom: 35px !important;
  }
  .ml-pc-35 {
    margin-left: 35px !important;
  }
  .pl-pc-35 {
    padding-left: 35px !important;
  }
  .mr-pc-35 {
    margin-right: 35px !important;
  }
  .pr-pc-35 {
    padding-right: 35px !important;
  }
  .mt-pc-40 {
    margin-top: 40px !important;
  }
  .pt-pc-40 {
    padding-top: 40px !important;
  }
  .mb-pc-40 {
    margin-bottom: 40px !important;
  }
  .pb-pc-40 {
    padding-bottom: 40px !important;
  }
  .ml-pc-40 {
    margin-left: 40px !important;
  }
  .pl-pc-40 {
    padding-left: 40px !important;
  }
  .mr-pc-40 {
    margin-right: 40px !important;
  }
  .pr-pc-40 {
    padding-right: 40px !important;
  }
  .mt-pc-50 {
    margin-top: 50px !important;
  }
  .pt-pc-50 {
    padding-top: 50px !important;
  }
  .mb-pc-50 {
    margin-bottom: 50px !important;
  }
  .pb-pc-50 {
    padding-bottom: 50px !important;
  }
  .ml-pc-50 {
    margin-left: 50px !important;
  }
  .pl-pc-50 {
    padding-left: 50px !important;
  }
  .mr-pc-50 {
    margin-right: 50px !important;
  }
  .pr-pc-50 {
    padding-right: 50px !important;
  }
  .mt-pc-55 {
    margin-top: 55px !important;
  }
  .pt-pc-55 {
    padding-top: 55px !important;
  }
  .mb-pc-55 {
    margin-bottom: 55px !important;
  }
  .pb-pc-55 {
    padding-bottom: 55px !important;
  }
  .ml-pc-55 {
    margin-left: 55px !important;
  }
  .pl-pc-55 {
    padding-left: 55px !important;
  }
  .mr-pc-55 {
    margin-right: 55px !important;
  }
  .pr-pc-55 {
    padding-right: 55px !important;
  }
  .mt-pc-60 {
    margin-top: 60px !important;
  }
  .pt-pc-60 {
    padding-top: 60px !important;
  }
  .mb-pc-60 {
    margin-bottom: 60px !important;
  }
  .pb-pc-60 {
    padding-bottom: 60px !important;
  }
  .ml-pc-60 {
    margin-left: 60px !important;
  }
  .pl-pc-60 {
    padding-left: 60px !important;
  }
  .mr-pc-60 {
    margin-right: 60px !important;
  }
  .pr-pc-60 {
    padding-right: 60px !important;
  }
}

@media (max-width: 812px) {
  .mt-sp-0 {
    margin-top: 0px !important;
  }
  .pt-sp-0 {
    padding-top: 0px !important;
  }
  .mb-sp-0 {
    margin-bottom: 0px !important;
  }
  .pb-sp-0 {
    padding-bottom: 0px !important;
  }
  .ml-sp-0 {
    margin-left: 0px !important;
  }
  .pl-sp-0 {
    padding-left: 0px !important;
  }
  .mr-sp-0 {
    margin-right: 0px !important;
  }
  .pr-sp-0 {
    padding-right: 0px !important;
  }
  .mt-sp-5 {
    margin-top: 5px !important;
  }
  .pt-sp-5 {
    padding-top: 5px !important;
  }
  .mb-sp-5 {
    margin-bottom: 5px !important;
  }
  .pb-sp-5 {
    padding-bottom: 5px !important;
  }
  .ml-sp-5 {
    margin-left: 5px !important;
  }
  .pl-sp-5 {
    padding-left: 5px !important;
  }
  .mr-sp-5 {
    margin-right: 5px !important;
  }
  .pr-sp-5 {
    padding-right: 5px !important;
  }
  .mt-sp-10 {
    margin-top: 10px !important;
  }
  .pt-sp-10 {
    padding-top: 10px !important;
  }
  .mb-sp-10 {
    margin-bottom: 10px !important;
  }
  .pb-sp-10 {
    padding-bottom: 10px !important;
  }
  .ml-sp-10 {
    margin-left: 10px !important;
  }
  .pl-sp-10 {
    padding-left: 10px !important;
  }
  .mr-sp-10 {
    margin-right: 10px !important;
  }
  .pr-sp-10 {
    padding-right: 10px !important;
  }
  .mt-sp-15 {
    margin-top: 15px !important;
  }
  .pt-sp-15 {
    padding-top: 15px !important;
  }
  .mb-sp-15 {
    margin-bottom: 15px !important;
  }
  .pb-sp-15 {
    padding-bottom: 15px !important;
  }
  .ml-sp-15 {
    margin-left: 15px !important;
  }
  .pl-sp-15 {
    padding-left: 15px !important;
  }
  .mr-sp-15 {
    margin-right: 15px !important;
  }
  .pr-sp-15 {
    padding-right: 15px !important;
  }
  .mt-sp-20 {
    margin-top: 20px !important;
  }
  .pt-sp-20 {
    padding-top: 20px !important;
  }
  .mb-sp-20 {
    margin-bottom: 20px !important;
  }
  .pb-sp-20 {
    padding-bottom: 20px !important;
  }
  .ml-sp-20 {
    margin-left: 20px !important;
  }
  .pl-sp-20 {
    padding-left: 20px !important;
  }
  .mr-sp-20 {
    margin-right: 20px !important;
  }
  .pr-sp-20 {
    padding-right: 20px !important;
  }
  .mt-sp-25 {
    margin-top: 25px !important;
  }
  .pt-sp-25 {
    padding-top: 25px !important;
  }
  .mb-sp-25 {
    margin-bottom: 25px !important;
  }
  .pb-sp-25 {
    padding-bottom: 25px !important;
  }
  .ml-sp-25 {
    margin-left: 25px !important;
  }
  .pl-sp-25 {
    padding-left: 25px !important;
  }
  .mr-sp-25 {
    margin-right: 25px !important;
  }
  .pr-sp-25 {
    padding-right: 25px !important;
  }
  .mt-sp-30 {
    margin-top: 30px !important;
  }
  .pt-sp-30 {
    padding-top: 30px !important;
  }
  .mb-sp-30 {
    margin-bottom: 30px !important;
  }
  .pb-sp-30 {
    padding-bottom: 30px !important;
  }
  .ml-sp-30 {
    margin-left: 30px !important;
  }
  .pl-sp-30 {
    padding-left: 30px !important;
  }
  .mr-sp-30 {
    margin-right: 30px !important;
  }
  .pr-sp-30 {
    padding-right: 30px !important;
  }
  .mt-sp-35 {
    margin-top: 35px !important;
  }
  .pt-sp-35 {
    padding-top: 35px !important;
  }
  .mb-sp-35 {
    margin-bottom: 35px !important;
  }
  .pb-sp-35 {
    padding-bottom: 35px !important;
  }
  .ml-sp-35 {
    margin-left: 35px !important;
  }
  .pl-sp-35 {
    padding-left: 35px !important;
  }
  .mr-sp-35 {
    margin-right: 35px !important;
  }
  .pr-sp-35 {
    padding-right: 35px !important;
  }
  .mt-sp-40 {
    margin-top: 40px !important;
  }
  .pt-sp-40 {
    padding-top: 40px !important;
  }
  .mb-sp-40 {
    margin-bottom: 40px !important;
  }
  .pb-sp-40 {
    padding-bottom: 40px !important;
  }
  .ml-sp-40 {
    margin-left: 40px !important;
  }
  .pl-sp-40 {
    padding-left: 40px !important;
  }
  .mr-sp-40 {
    margin-right: 40px !important;
  }
  .pr-sp-40 {
    padding-right: 40px !important;
  }
  .mt-sp-50 {
    margin-top: 50px !important;
  }
  .pt-sp-50 {
    padding-top: 50px !important;
  }
  .mb-sp-50 {
    margin-bottom: 50px !important;
  }
  .pb-sp-50 {
    padding-bottom: 50px !important;
  }
  .ml-sp-50 {
    margin-left: 50px !important;
  }
  .pl-sp-50 {
    padding-left: 50px !important;
  }
  .mr-sp-50 {
    margin-right: 50px !important;
  }
  .pr-sp-50 {
    padding-right: 50px !important;
  }
  .mt-sp-55 {
    margin-top: 55px !important;
  }
  .pt-sp-55 {
    padding-top: 55px !important;
  }
  .mb-sp-55 {
    margin-bottom: 55px !important;
  }
  .pb-sp-55 {
    padding-bottom: 55px !important;
  }
  .ml-sp-55 {
    margin-left: 55px !important;
  }
  .pl-sp-55 {
    padding-left: 55px !important;
  }
  .mr-sp-55 {
    margin-right: 55px !important;
  }
  .pr-sp-55 {
    padding-right: 55px !important;
  }
  .mt-sp-60 {
    margin-top: 60px !important;
  }
  .pt-sp-60 {
    padding-top: 60px !important;
  }
  .mb-sp-60 {
    margin-bottom: 60px !important;
  }
  .pb-sp-60 {
    padding-bottom: 60px !important;
  }
  .ml-sp-60 {
    margin-left: 60px !important;
  }
  .pl-sp-60 {
    padding-left: 60px !important;
  }
  .mr-sp-60 {
    margin-right: 60px !important;
  }
  .pr-sp-60 {
    padding-right: 60px !important;
  }
}

h3 {
  font-size: 1.4em;
  border-bottom: #EDDAD0 2px solid;
  color: #6c4939;
  padding-bottom: 0.2em;
  margin-bottom: 1em;
}

h4 {
  font-size: 1.2em;
  background: #c5a292;
  color: #fff;
  padding: 6px 15px;
  margin-bottom: 0.6em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

/* 電話番号リンク消す_PC*/
@media (min-width: 813px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

/*マウスオーバーで画像拡大_院長画像_使いませんでした*/
.img_wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.img_wrap img {
  -webkit-transition: -webkit-transform .3s linear;
  transition: -webkit-transform .3s linear;
  transition: transform .3s linear;
  transition: transform .3s linear, -webkit-transform .3s linear;
}

.img_wrap:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition-duration: 0.3s;
}

/*テーブル*/
.table-fixed {
  table-layout: fixed;
}

.c-table-cmn tr td:first-child {
  white-space: nowrap;
}

/*外来担当医表一覧 テーブル直後のh3の上指定*/
.c-wrap-table + h3 {
  margin-top: 2em;
}

/*ヘッダーお問い合わせのテキスト追加_PCのみ*/
.head_toiawase_txt {
  display: none;
}

.home .head_toiawase_txt {
  display: none;
}

@media (min-width: 813px) {
  .home .head_toiawase_txt {
    display: block;
    font-size: 1.8rem;
    color: #fff;
    opacity: 0.8;
    text-align: right;
  }
}

/*トップメン画像に重ねて臨時アナウンス表示*/
.top_extra_info {
  z-index: 1;
  position: absolute;
  left: 10%;
  bottom: 10px;
  background: none;
  width: 80%;
  text-align: center;
}

.top_extra_info a {
  display: block;
  padding: 10px 20px;
  border: 2px solid #fff !important;
  background: #ff7748 !important;
}

@media (min-width: 813px) {
  .top_extra_info {
    left: 25%;
    bottom: 40px;
    right: 40px;
    width: 50%;
  }
  .top_extra_info a {
    padding: 20px;
  }
}

img.border {
  border: solid 1px #ccc;
}

th.nowrap {
  white-space: nowrap;
}

table.table-center th, table.table-center td {
  text-align: center;
}

.nav-local-list-item .nav-child-wrap {
  display: none;
  visibility: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
}

@media (min-width: 813px) {
  .nav-local-list-item .nav-child-wrap {
    visibility: visible;
  }
}

.nav-local-list-item .nav-child-wrap .nav-child-inner {
  display: flex;
  padding: 2.24rem;
  background: #f0ebcf;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items {
  padding: 5.44rem 1.92rem 1.92rem 1.92rem;
  width: 25%;
  position: relative;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items a {
  text-decoration: underline;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-list li {
  font-size: 1.36rem;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-list li a::before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 1.28rem;
  display: inline-block;
  color: rgba(135, 113, 102, 0.8);
  margin-right: 0.4rem;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-list li a[target='_blank']::before {
  content: "\f2d2" !important;
  font-family: "Font Awesome 5 Pro";
  display: inline-block;
  font-weight: 900;
  color: rgba(135, 113, 102, 0.8);
  margin-right: 0.4rem;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-list li + li {
  padding-top: 0.4rem;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-heading {
  font-size: 1.68rem;
  position: absolute;
  top: 0;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-heading .nav-child-category {
  display: flex;
  align-items: center;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-heading .nav-child-category i {
  position: relative;
  color: #fff;
  background: #877166;
  border-radius: 50%;
  width: 3.52rem;
  height: 3.52rem;
  font-size: 1.68rem;
  margin-right: 0.8rem;
}

.nav-local-list-item .nav-child-wrap .nav-child-inner .nav-child-items .nav-child-heading .nav-child-category i::before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-global {
  z-index: 5;
  max-height: 100%;
  margin-top: -60px;
  padding-top: 60px;
}

.s-nav-local-list {
  color: #fff;
}

.s-nav-local-list a {
  color: #fff;
  display: block;
}

.s-nav-local-list .s-nav-parent a, .s-nav-local-list .s-nav-parent .trigger {
  padding: 1em;
}

.s-nav-local-list .s-nav-child {
  display: none;
}

.s-nav-local-list .s-nav-child.open {
  display: block;
}

.s-nav-local-list .trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.s-nav-local-list .trigger::after {
  right: 1em;
  content: '\f078';
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 0.75em;
  display: inline-block;
  transition: transform 0.25s linear;
  color: #fff;
  z-index: 0;
}

.s-nav-local-list .trigger.current::after {
  transform: rotate(180deg);
}

.s-nav-local-list .nav-first a, .s-nav-local-list .nav-first .trigger {
  background: #274e7b;
}

.s-nav-local-list .nav-second a, .s-nav-local-list .nav-second .trigger {
  padding-left: 2em;
  background: #2d5b8f;
}

.s-nav-local-list .nav-third a, .s-nav-local-list .nav-third .trigger {
  padding-left: 3em;
  background: #3367a2;
}

.jintai-wrap {
  padding: 24px;
  margin: 0;
  position: relative;
}

.jintai-wrap .jintai-items {
  font-size: 16px;
  position: absolute;
}

.jintai-wrap .jintai-elem a {
  font-size: 16px;
  background: rgba(135, 113, 102, 0.9);
  color: #fff;
  padding: 6px 12px;
  border-radius: 6px;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}

.jintai-wrap .jintai-elem a:hover {
  color: #fff;
  opacity: 1;
}

.jintai-wrap .jintai-inner {
  width: 200px;
  margin: 0 auto;
  position: relative;
}

.jintai-wrap .circle-right {
  position: relative;
}

.jintai-wrap .circle-right::before {
  color: #877166;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '\f111';
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.jintai-wrap .circle-right a {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.jintai-wrap .circle-left {
  position: relative;
}

.jintai-wrap .circle-left::before {
  color: #877166;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: '\f111';
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.jintai-wrap .circle-left a {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.jintai-wrap .toubu {
  top: 20px;
  left: 108px;
}

.jintai-wrap .face {
  top: 60px;
  right: 108px;
}

.jintai-wrap .keibu {
  top: 100px;
  left: 108px;
}

.jintai-wrap .kyobu {
  top: 160px;
  right: 108px;
}

.jintai-wrap .fukubu {
  top: 250px;
  left: 108px;
}

.jintai-wrap .kafukubu {
  top: 310px;
  left: 108px;
}

.jintai-wrap .koshi {
  top: 280px;
  right: 76px;
}

.jintai-wrap .kashi {
  top: 380px;
  right: 76px;
}

.jintai-wrap .all {
  bottom: 45px;
  left: 0;
}

.jintai-wrap .other {
  bottom: 0;
  left: 0;
}

@media (min-width: 813px) {
  .jintai-content .jintai-desc {
    font-size: 100%;
  }
}

.jintai-content + .jintai-content {
  margin-top: 1em;
}

#neighbor > ul {
  margin-top: 0 !important;
}

.reservation-wrapper {
  overflow: hidden;
}

.reservation-container-wrapper {
  display: flex;
  flex-flow: column nowrap;
  transition: transform .5s;
}

.reservation-container {
  display: none;
  width: 100%;
  max-width: 100%;
}

.reservation-container.is_visible {
  display: block;
  animation: show .4s linear 0s;
}

.form-container {
  background: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 2em;
  max-width: 330px;
}

@media (min-width: 813px) {
  .form-container {
    max-width: 812px;
  }
}

.reservation-button-wrapper {
  display: flex;
  align-items: center;
  margin: 2em -.5em 0;
}

.reservation-button-wrapper div {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
  padding: 0 .5em;
}

.reservation-button-wrapper div._left {
  text-align: left;
}

.reservation-button-wrapper div._right {
  text-align: right;
}

@media (max-width: 812px) {
  .reservation-button-wrapper.last_item {
    display: block;
  }
  .reservation-button-wrapper.last_item div {
    flex: none;
    width: 100%;
    max-width: none;
  }
  .reservation-button-wrapper.last_item div + div {
    margin-top: 1em;
  }
}

.next-button,
.prev-button {
  background: #2a5382;
  border: 4px solid transparent;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  padding: .5em .875em;
  text-align: center;
}

.next-button span,
.prev-button span {
  display: inline-block;
  position: relative;
}

.next-button span::after,
.prev-button span::after {
  color: inherit;
  content: '\f061';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-left: .5em;
  width: 1.28571429em;
  text-align: center;
}

.next-button.disabled,
.prev-button.disabled {
  background: #e9ecef;
  color: #ced4da;
  pointer-events: none;
}

.prev-button {
  background: #fff;
  border: 4px solid #2a5382;
  color: #2a5382;
}

.prev-button span::before {
  color: inherit;
  content: '\f060';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-right: .5em;
  width: 1.28571429em;
  text-align: center;
}

.prev-button span::after {
  content: none;
}

@media (max-width: 812px) {
  .sp-block-btn {
    width: 100%;
  }
}

.course-heading {
  font-weight: 700;
}

.invisible {
  display: none !important;
}

label.res {
  color: #6c757d;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .5em;
}

label.rsv-label-radio [type='radio'] {
  display: none;
}

label.rsv-label-radio [type='radio'] + span {
  background: #f6f3e2;
  border: 3px solid #CEB0A3;
  border-radius: 8px;
  transition: background 0.2s ease-out;
  cursor: pointer;
  display: block;
  padding: 1em 1em 1em 2.5em;
  position: relative;
}

label.rsv-label-radio [type='radio'] + span:before {
  color: inherit;
  content: '\f111';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  margin-right: .5em;
  width: 1.28571429em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
}

label.rsv-label-radio [type='radio']:checked + span {
  background: #2a5382;
  border: 3px solid #214268;
  color: #fff;
}

label.rsv-label-radio [type='radio']:checked + span:before {
  content: none;
}

label.rsv-label-radio [type='radio']:checked + span:after {
  color: inherit;
  content: '\f058';
  display: inline-block;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-right: .5em;
  width: 1.28571429em;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 1em;
  transform: translateY(-50%);
}

label.rsv-label-check input {
  margin-right: .5em;
}

label.rsv-label-check + label.rsv-label-check {
  margin-top: .5em;
}

ul.confirm-list > li:not(:last-child) {
  margin-bottom: 2em;
}

.confirm-list-heading {
  color: #6c757d;
  font-size: 90%;
  margin-bottom: 1em;
  padding-left: 1em;
  position: relative;
}

.confirm-list-heading:before, .confirm-list-heading:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
}

.confirm-list-heading:before {
  background: #CEB0A3;
  top: 0;
  width: 6px;
  height: 100%;
}

.confirm-list-heading:after {
  border-bottom: 1px solid #e9ecef;
  bottom: -6px;
  width: 100%;
}

.confirm-list-content {
  padding-left: 1em;
}

.accordion-wrapper {
  width: 100%;
}

.accordion .accordion-heading {
  background: #f6f3e2;
  border-bottom: 1px solid #f0ebcf;
  color: #877166;
  cursor: pointer;
  font-size: .875em;
  outline: none;
  margin: 0;
  padding: .875em 1em;
  position: relative;
  transition: background 0.2s ease-out;
  width: 100%;
}

.accordion .accordion-heading:hover {
  background: #f0ebcf;
}

.accordion .accordion-heading:after {
  border: 2px solid #877166;
  border-right: 0;
  border-top: 0;
  content: '';
  display: block;
  pointer-events: none;
  position: absolute;
  top: 48%;
  right: 1em;
  transform: rotate(-45deg) translateY(-50%);
  width: 6px;
  height: 6px;
}

@media (min-width: 813px) {
  .accordion .accordion-heading:after {
    width: 10px;
    height: 10px;
  }
}

.accordion .accordion-heading.active:after {
  border: 2px solid #877166;
  border-left: 0;
  border-bottom: 0;
}

.accordion .accordion-contents {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

.accordion .accordion-contents-inner {
  background: #fff;
  padding: 1em;
}

.contact-form-grid {
  display: flex;
  flex-flow: column wrap;
}

.contact-form-grid + .contact-form-grid {
  margin-top: 2em;
}

@media (min-width: 813px) {
  .contact-form-grid {
    flex-flow: row wrap;
  }
}

.title-col {
  background: #F3EBE7;
  font-size: .875em;
  margin-bottom: 1em;
  padding: .6em 1em;
}

@media (min-width: 813px) {
  .title-col {
    margin-bottom: 0;
    flex: 0 0 380px;
    width: 380px;
    max-width: 380px;
  }
}

.input-col {
  flex: 1;
}

@media (min-width: 813px) {
  .input-col {
    margin-left: 1em;
  }
}

.contact-form-confirm-wrapper {
  margin: 0 auto;
  max-width: 640px;
}

form.confirm input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
form.confirm textarea {
  border: none;
}

form.confirm input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']):focus,
form.confirm textarea:focus {
  background: #fff;
  box-shadow: none;
  outline: 0;
}

form.confirm input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']):focus::placeholder,
form.confirm textarea:focus::placeholder {
  color: transparent;
}

dl.confirm-list {
  word-break: break-word;
}

dl.confirm-list dt {
  border-left: 4px solid #2a5382;
  font-size: .875em;
  margin-bottom: .4em;
  padding-left: .4em;
}

dl.confirm-list dt:not(:first-child) {
  margin-top: 2em;
}

dl.confirm-list dd {
  padding-left: 1em;
}
