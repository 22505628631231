/* ==========================================================
  blackground
========================================================== */
/* bg-color
---------------------------------------------------------- */
.c-bg-brown-01 {
  background-color: $mainBrown1;
}
.c-bg-brown-02 {
  background-color: $mainBrown2;
}
.c-bg-brown-03 {
  background-color: $mainBrown3;
}
