/* c-grid
---------------------------------------------------------- */
/*
  Exam
  <div class="row pd-32 pd-sp-0 mb-35">
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
    <div class="col-pc-6 col-sp-12">
      test
    </div>
  </div>
  **-pd-32 -> .row { margin-left: -32px; margin-right: -32px;}
          -> .row [class^="col"] { padding-left: 32px; padding-right: 32px;}
*/
.c-grid {
  .col {
    width: 100%;
    padding: 0 15px;
    margin-top: 25px;

    @include mq-sp() {
      padding: 0 7.5px;
      margin-top: 20px;
    }

    &:last-child { margin-bottom: 0;}
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -25px;
    margin-left: -15px;
    margin-right: -15px;

    @include mq-sp() {
      margin-top: -20px;
      margin-left: -7.5px;
      margin-right: -7.5px;
    }

    &:last-child { margin-bottom: 0;}

    @for $pd from 0 through 60 {
      &.-pd-#{$pd} {
        margin-left: -($pd) + px !important;
        margin-right: -($pd) + px !important;

        & > [class^="col"] {
          padding-left: $pd + px !important;
          padding-right: $pd + px !important;
        }
      }
      &.-mb-#{$pd} {
        margin-top: -($pd) + px !important;

        & > [class^="col"] {
          margin-top: $pd + px !important;
        }
      }
    }
    @include mq-pc() {
      @for $pd from 0 through 60 {
        &.-pd-pc-#{$pd} {
          margin-left: -($pd) + px !important;
          margin-right: -($pd) + px !important;
          margin-top: -($pd * 2) + px !important;
          & > [class^="col"] {
            padding-left: $pd + px !important;
            padding-right: $pd + px !important;
            margin-top: $pd * 2 + px !important;
          }
        }

        &.-mb-pc-#{$pd} {
          margin-top: -($pd) + px !important;

          & > [class^="col"] {
            margin-top: $pd + px !important;
          }
        }
      }
    }
    @include mq-sp() {
      @for $pd from 0 through 60 {
        &.-pd-sp-#{$pd} {
          margin-left: -($pd) + px !important;
          margin-right: -($pd) + px !important;
          margin-top: -($pd * 2) + px !important;
          & > [class^="col"] {
            padding-left: $pd + px !important;
            padding-right: $pd + px !important;
            margin-top: $pd * 2 + px !important;
          }
        }

        &.-mb-sp-#{$pd} {
          margin-top: -($pd) + px !important;

          & > [class^="col"] {
            margin-top: $pd + px !important;
          }
        }
      }
    }
  }

/* c-grid col-i
---------------------------------------------------------- */
  $i: 1;
  $col-count: 12 !default;
  $col-width: 100% / $col-count;

  @function col($columns){
    @return $col-width * $columns;
  }
  @for $i from $col-count through 1 {
    .col-#{$i} {
      width: $col-width * $i;
      padding: 0 15px;
      margin-top: 25px;
    }
  }

  @include mq-pc {
    @for $i from $col-count through 1 {
      .col-pc-#{$i} {
        width: $col-width * $i;
        padding: 0 15px;
        margin-top: 25px;
      }
    }
  }

  @include mq-sp {
    @for $i from $col-count through 1 {
      .col-sp-#{$i} {
        width: $col-width * $i;
        padding: 0 7.5px;
        margin-top: 20px;
      }
    }
  }
}
