@charset 'UTF-8';

// grid
// ----
.grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 ( - grid-margin(sm) );
  @include mq-pc {
    margin: 0 ( - grid-margin(lg) );
  }
}

.grid-inner {
  display: flex;
  flex-flow: row wrap;
  margin: ( ( - grid-margin(sm)) ( - grid-margin(sm)) 0 );
  @include mq-pc {
    margin: ( ( - grid-margin(lg)) ( - grid-margin(lg)) 0 );
  }
}

.col {
  padding: grid-margin(sm);
  flex: 1 1 0;
  @include mq-pc {
    padding: grid-margin(lg);
  }
  & figure {
    text-align: center;
  }
}

@for $i from 1 through $grid-columns {
  .sm-#{$i} {
    @include make-grid( 100% / ( $grid-columns / $i ) );
  }
  .sm-#{$i}-center {
    @include make-grid( 100% / ( $grid-columns / $i ));
    margin: 0 auto;
  }
  .sm-offset-#{$i} {
    @include make-grid( 100% / ( $grid-columns / $i ));
    margin-left: ( 100% / ( $grid-columns / $i ) );
  }
}

@include mq-pc {
  @for $i from 1 through $grid-columns {
    .lg-#{$i} {
      @include make-grid( 100% / ( $grid-columns / $i ) );
    }
    .lg-#{$i}-center {
      @include make-grid( 100% / ( $grid-columns / $i ) );
      margin: 0 auto;
    }
    .lg-offset-#{$i} {
      @include make-grid( 100% / ( $grid-columns / $i ));
      margin-left: ( 100% / ( $grid-columns / $i ) );
    }
  }
}

// grid tile
// ---------
.grid-tile {
  display: flex;
  flex-flow: row wrap;
  margin: 0 (- grid-margin(sm));
  @include mq-pc {
    margin: 0 (- grid-margin(lg));
  }
  // flex items
  @for $i from 1 through $grid-columns {
    .sm-col-#{$i} {
      @include make-grid( 100% / $i );
      padding: grid-margin(sm);
    }
  }
  @include mq-pc {
    @for $i from 1 through $grid-columns {
      .lg-col-#{$i} {
        @include make-grid( 100% / $i );
        padding: grid-margin(lg);
      }
    }
  }
}

// 揃える
// ----
.align-end {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

// 縦組み  旧Android iOS6以前は未対応
.align-end-vertical {
  align-content: flex-end;
}

.align-center-vertical {
  align-content: center;
}

.align-justify-vertical {
  align-content: space-between;
}

.align-spaced-vertical {
  align-content: space-around;
}

// 子要素の天地の揃え align-itemsは親要素・align-selfは子要素
.item-top {
  align-items: flex-start;
}

.item-top-child {
  align-self: flex-start;
}

.item-end {
  align-items: flex-end;
}

.item-end-child {
  align-self: flex-end;
}

.item-center {
  align-items: center;
}

.item-center-child {
  align-self: center;
}

.item-stretch {
  align-items: stretch;
}

.item-stretch-child {
  align-self: stretch;
}
