@charset 'UTF-8';

// 各項目の間隔
// ---------
.form-field + .form-field {
  margin-top: map-get( $form-field-margin, sm );
  @include mq-pc {
    margin-top: map-get( $form-field-margin, lg );
  }
}

label {
  display: block;
  &.item {
    color: inherit;
    font-weight: normal;
    margin-bottom: .5em;
  }
  // お問い合わせフォーム
  &.item-contact {
    border-left: 4px solid $lightBlue;
    font-size: .875em;
    margin-bottom: .4em;
    padding-left: .4em;
  }
  &.item-confirm {
    border-left: 4px solid $lightBlue;
    font-size: .875em;
    margin-bottom: .4em;
    padding-left: .4em;
  }
}

// jquery.validate.min.js エラー表示用
.validate_error {
  display: inline-flex;
  align-items: center;
  margin-left: .4em;
  .error {
    color: $red-color;
    font-size: .875em;
    font-weight: 700;
  }
}

// 必須
.essential {
  background: $base-color;
  color: $white-color;
  display: inline-block;
  font-size: .8em;
  margin-left: .5em;
  padding: .2em .5em;
}


// 外枠
// ---------
input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
textarea {
  background: $white-color;
  border: 1px solid #ccc;
  box-shadow: none;
  color: #333;
  //display: block;
  font-size: 1em;
  //height: 3em;
  padding: .5em 1em;
  line-height: 1.42857143;
  width: 100%;
  transition: border-color $transition, background-color $transition;

  &:focus {
    background: $white-color;
    border-color: $base-color;
    box-shadow: 0 0 6px 1px rgba($base-color, .075);
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #999;
  }
  &::-webkit-input-placeholder {
    color: #999;
  }
  &::-ms-expand {
    background-color: #fff;
    border: 0;
  }
}

textarea {
  height: auto;
  //padding: 1em;
  &.confirm {
    resize: none;
    overflow: hidden;
  }
}

// SELECT
// ------
select {
  width: 100%;
  &::-moz-focus-inner {
    border: 0;
  }
}

select::-ms-expand {
  display: none;
}

.select {
  display: inline-block;
  position: relative;
  & select {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: inherit;
    cursor: pointer;
    display: block;
    font-size: 1em;
    height: 3em;
    line-height: 1.42857143;
    padding: 0 2.5em 0 1em;
    position: relative;
    outline: none;
    overflow: hidden;
  }

  &:after { // 矢印
    border: 1px solid #999;
    border-right: 0;
    border-top: 0;
    content: '';
    display: block;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: rotate(-45deg) translateY(-50%);
    width: 6px;
    height: 6px;
    @include mq-pc {
      width: 9px;
      height: 9px;
    }
  }
}

// Radio Button & Check Box
// ------------------------
.checkbox-group,
.radio-group {
  display: inline-block;

  &.row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .label-checkbox + .label-checkbox,
    .label-radio + .label-radio {
      margin-left: 1em;
    }
  }

  &.privacy {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }
}

.label-checkbox input[type='checkbox'],
.label-radio input[type='radio'] {
  cursor: pointer;
}

.label-checkbox,
.label-radio {
  cursor: pointer;
  display: flex;
  align-items: center;
  .lever {
    padding-left: .4em;
  }
}

// Submit Button
// -------------
input[type='submit'] {
  @extend .button;
}
