@charset 'UTF-8';

////////////////////////////////////////////////////////////
// utility
////////////////////////////////////////////////////////////

//画像に枠線を付ける
img.border{
  border: solid 1px #ccc;
}

////////////////////////////////////////////////////////////
// table
////////////////////////////////////////////////////////////

//テープル用追加クラス
th.nowrap{
  white-space: nowrap;
}
table.table-center{
  th,td{
    text-align: center;
  }
}

////////////////////////////////////////////////////////////
// グローバルメニュー
////////////////////////////////////////////////////////////

.nav-local-list-item{
  //そとみ
  .nav-child-wrap{
    display: none;
    visibility: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    @include mq-pc {
      visibility: visible;
    }
    //なかみ
    .nav-child-inner{
      display: flex;
      padding: 2.24rem;
      background: darken($beige, 5%);
      //ナビ内のカラム
      .nav-child-items{
        padding: 5.44rem 1.92rem 1.92rem 1.92rem;
        width: 25%;
        position: relative;
        a{
          text-decoration: underline;
        }
        //カラム内のリスト
        .nav-child-list{
          li{
            font-size: 1.36rem;
            a::before{
              content: "\f054";
              font-family: $icon-font-family;
              font-weight: 900;
              font-size: 1.28rem;
              display: inline-block;
              color: rgba($chocoBrown, 0.8);
              margin-right: 0.4rem;
            }
            a[target='_blank']::before{
              content: "\f2d2" !important;
              font-family: $icon-font-family;
              display: inline-block;
              font-weight: 900;
              color: rgba($chocoBrown, 0.8);
              margin-right: 0.4rem;
            }
          }
          //リストのすきま
          li + li{
            padding-top: 0.4rem;
          }
        }
        //アイコンとカテゴリ名
        .nav-child-heading{
          font-size: 1.68rem;
          position: absolute;
          top: 0;
          .nav-child-category{
            display: flex;
            align-items: center;
            i{
              position: relative;
              color: #fff;
              background: $chocoBrown;
              border-radius: 50%;
              width: 3.52rem;
              height: 3.52rem;
              font-size: 1.68rem;
              margin-right: 0.8rem;
              &::before{
                position :absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
              }//i::before
            }//i
          }//.nav-child-category
        }//.nav-child-heading
      }//.nav-child-items
    }//.nav-child-inner
  }//.nav-child-wrap
}//.nav-local-list-item

////////////////////////////////////////////////////////////
// バーガーメニュー
////////////////////////////////////////////////////////////

.nav-global{
  z-index: 5;
  max-height: 100%;
  margin-top: -60px;//ヘッダー分ずれる対策
  padding-top: 60px;//ヘッダー分ずれる対策
}

.s-nav-local-list{
  color: #fff;
  a{
    color: #fff;
    display: block;
  }
  .s-nav-parent{
    a,.trigger{
      padding: 1em;
    }
  }
  .s-nav-child{
    display: none;
    &.open{
      display: block;
    }
  }
  //スライドダウンのトリガー
  .trigger{
    //position: relatve;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //下矢印
    &::after{
      //position: absolute;
      right: 1em;
      content: '\f078';
      font-family: $icon-font-family;
      font-weight: 900;
      font-size: 0.75em;
      display:inline-block;
      transition: transform 0.25s linear;
      color: #fff;
      z-index: 0;
    }
    &.current::after{
      transform:rotate(180deg);
    }
  }
  //第1階層
  .nav-first{
    a,.trigger{
      background: lighten($mainBlue, 5%);
    }
  }
  //第2階層
  .nav-second{
    a,.trigger{
      padding-left: 2em;
      background: lighten($mainBlue, 10%);
    }
  }
  //第3階層
  .nav-third{
    a,.trigger{
      padding-left: 3em;
      background: lighten($mainBlue, 15%);
    }
  }
}//.s-nav-local-list

////////////////////////////////////////////////////////////
// 人体
////////////////////////////////////////////////////////////

.jintai-wrap{
  padding: 24px;
  margin:0;
  position :relative;

  .jintai-items{
    font-size: 16px;
    position: absolute;
  }

  .jintai-elem{
    a{
      font-size: 16px;
      background: rgba($chocoBrown, 0.9);
      color: #fff;
      padding: 6px 12px;
      border-radius: 6px;
      display: inline-block;
      white-space: nowrap;
      cursor: pointer;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
      &:hover{
        color: #fff;
        opacity: 1;
      }
    }
  }

  .jintai-inner{
    width: 200px;
    margin: 0 auto;
    position: relative;
  }

  .circle-right{
    position: relative;
    //padding-right: 32px;
    &::before{
      color: $chocoBrown;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); 
      content: '\f111';
      font-family: $icon-font-family;
      font-weight: 900;
    }
    a{
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%); 
    }
  }

  .circle-left{
    position: relative;
    //padding-left: 32px;
    &::before{
      color: $chocoBrown;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); 
      content: '\f111';
      font-family: $icon-font-family;
      font-weight: 900;
    }
    a{
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%); 
    }
  }

  .toubu{
    top: 20px;
    left: 108px;
  }

  .face{
    top: 60px;
    right: 108px;
  }

  .keibu{
    top: 100px;
    left: 108px;
  }

  .kyobu{
    top: 160px;
    right: 108px;
  }

  .fukubu{
    top: 250px;
    left: 108px;
  }

  .kafukubu{
    top: 310px;
    left: 108px;
  }

  .koshi{
    top: 280px;
    right: 76px;
  }

  .kashi{
    top: 380px;
    right: 76px;
  }

  .all{
    bottom: 45px;
    left: 0;
  }

  .other{
    bottom: 0;
    left: 0;
  }

}

.jintai-content{
  .jintai-desc{
    @include mq-pc {
      font-size: 100%;
    }
  }
  & + .jintai-content{
    margin-top: 1em;
  }
}

#neighbor > ul{
  margin-top: 0 !important;
}

