/* ==========================================================
  text-cmn
========================================================== */

/* ==========================================================
  text-color
========================================================== */
.c-text-color-01 {
  color: #8C563A;
  font-size: 1.4rem;
}
.c-text-white {
  color: $white;
}
.c-text-black {
  color: $black;
}
.c-text-blue {
  color: $mainBlue;
}

