@charset 'UTF-8';

// section-inner
// -------------
.section-inner {
  @include mq-sp {
    padding-top: map-unit( $section-gutter, sm );
    padding-bottom: map-unit( $section-gutter, sm );
  }
  @include mq-pc {
    padding-top: map-unit( $section-gutter, lg );
    padding-bottom: map-unit( $section-gutter, lg );
  }
}

.section-inner-upper {
  @include mq-sp {
    padding-bottom: map-unit( $section-gutter, sm );
  }
  @include mq-pc {
    padding-bottom: map-unit( $section-gutter, lg );
  }
}

.section-inner-lower {
  @include mq-sp {
    padding-top: map-unit( $section-gutter, sm );
  }
  @include mq-pc {
    padding-top: map-unit( $section-gutter, lg );
  }
}


// Element
// -------
.element {
  @include mq-sp {
    padding-top: map-unit( $element-gutter, sm );
  }
  @include mq-pc {
    padding-top: map-unit( $element-gutter, lg );
  }
}
