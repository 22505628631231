/* ==========================================================
  heading-01
========================================================== */
.c-heading-01 {
  position: relative;
  padding-bottom: 1em;
  @include mq-sp {
    margin: 0 0 0 5px;
  }
  i {
    font-size: 2.4rem;
    color: #877166;
    @include mq-sp {
      font-size: 2.5rem;
    }
  }
  span {
    padding-left: 17px;
    font-size: 2.6rem;
    font-weight: normal;
    //letter-spacing: -0.06em;
    letter-spacing: 0em;
    @include mq-sp {
      font-size: 2.4rem;
    }
  }

  &.sm {
    display: flex;
    align-items: normal;
    i {
      font-size: 2.7rem;
     @include mq-sp {
        font-size: 2.3rem;
      }
    }
    span {
      padding-left: 10px;
      font-size: 1.6rem;
    @include mq-sp {
        font-size: 1.8rem;
      }
    }
  }

  &.black {
    i {
      color: $mainBlue;
    }
    span {
      color: $black;
    }
  }
}
