@charset 'UTF-8';

// お問い合わせフォーム
.contact-form-grid {
  display: flex;
  flex-flow: column wrap;
  & + & {
    margin-top: 2em;
  }
  @include mq-pc {
    flex-flow: row wrap;
  }
}

.title-col {
  background: $mainBrown1;
  font-size: .875em;
  margin-bottom: 1em;
  padding: .6em 1em;
  @include mq-pc {
    margin-bottom: 0;
    flex: 0 0 380px;
    width: 380px;
    max-width: 380px;
  }
}

.input-col {
  flex: 1;
  @include mq-pc {
    margin-left: 1em;
  }
}

.contact-form-confirm-wrapper {
  margin: 0 auto;
  max-width: 640px;
}

form.confirm {
  input:not([type='checkbox']):not([type='radio']):not([type='submit']):not([type='reset']):not([type='button']),
  textarea {
    border: none;
    &:focus {
      background: $white-color;
      //border-color: $base-color;
      box-shadow: none;
      outline: 0;
      &::placeholder {
        color: transparent;
      }
    }
  }
}

dl.confirm-list {
  word-break: break-word;
  dt {
    border-left: 4px solid $lightBlue;
    font-size: .875em;
    margin-bottom: .4em;
    padding-left: .4em;
    &:not(:first-child) {
      margin-top: 2em;
    }
  }
  dd {
    padding-left: 1em;
  }
}
