/* ==========================================================
  heading-02
========================================================== */
.ttl-madical {
  font-weight: normal;
  font-size: 1.8rem;
  @include mq-sp {
    font-size: 2rem
  }
  span {
    font-size: 1.6rem;
  }
}
