/* ==========================================================
  footer
========================================================== */
.footer {
  position: relative;
  min-width: $minWidth;
  padding: 0 30px 20px;
  background-color: $mainBlue;

  @include mq-sp {
    min-width: 0;
    padding: 0 0 20px;
  }

  &-in {
    width: $contentWidth;
    margin: 0 auto;

    @include mq-sp {
      width: auto;
    }
  }

  &-container {
    display: flex;

    @include mq-sp {
      flex-wrap: wrap;
    }
  }

  &-company-info {
    flex: 0 0 auto;
    width: 410px;
    padding-top: 35px;
    text-align: left;

    @include mq-sp {
      order: 2;
      width: 100%;
      padding: 35px 15px 0;
      text-align: center;
    }
  }

  &-logo {
    display: block;
    opacity: 1 !important;

    > img {
      display: block;
      width: 320px;

      @include mq-sp {
        width: 240px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  &-address {
    margin-top: 16px; 
    font-size: 1.2rem;
    color: #fff;

    @include mq-sp {
      margin-top: 18px;
      font-size: 1.2rem;
    }
	  
    .box-contact {
		margin: 1.6em auto 0;
		padding-top: 5px;
		position: relative;
		
		ul {
			display: inline-block;
			position: absolute;
			top: 0;
			left: 160px;
			
			li {
				display: inline-block;
				
				&:first-child {
					margin-right: 12px;
				}
			}
		}
		
		@include mq-sp {
			width: 300px;
			margin-bottom: 2em;
			padding-top: 12px;
			
			&-txt {
				width: 130px;
			}
			
			ul {
				width: 180px;
				left: 120px;
			}
		}
		
	  }

    .txt-contact {
      display: block;
      margin-top: 0.2em;
      color: #fff;

      .icon {
        margin-right: 6px;
      }

      a {
        color: #fff;
      }
		
		@include mq-sp {
			font-size: 1.2rem;
		}
    }
  }

  &-utilities {
    flex: 1;

    @include mq-pc {
      text-align: right;
    }

    @include mq-sp {
      order: 1;
      width: 100%;
    }
  }

  &-link {
    display: flex;
    justify-content: flex-end;

    @include mq-sp {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    > li {
      @include mq-pc {
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }
		  
      }

      @include mq-sp {
        width: 50%;
		border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
		font-size: 1rem;

        &:nth-child(2n - 1) {
          border-right: 1px solid #fff;
        }
		  
		&:last-child {
			  width: 100%;
			border-top: none;
			border-right: none;
			text-align: center;
		  }
      }
    }

    .btn-link {
      display: block;
      color: #fff;
      font-size: 1.2rem;

      @include mq-sp {
        padding: 10px 20px;
        font-size: 1.2rem;
      }

      .txt-link {
        position: relative;
        display: inline-block;
        padding-left: 18px;

        @include mq-sp {
          padding-left: 15px;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5.5px 0 5.5px 9px;
          border-color: transparent transparent transparent #fff;
          transform: translateY(-50%);
          content: '';

          @include mq-sp {
            border-width: 4.5px 0 4.5px 8px;
          }
        }
      }
    }
  }

  &-banner {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    @include mq-sp {
      flex-wrap: wrap;
      justify-content: center;
      margin: 20px 9px 0;
    }

    > li {
      @include mq-pc {
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }
      }

      @include mq-sp {
        width: 33.33%;
        padding: 0 6px;
      }

      .btn-link {
        display: block;
      }

      img {
        display: block;
        width: 240px;

        @include mq-sp {
          width: 100%;
        }
      }
    }
  }

  &-copyright {
    margin-top: 64px;
    font-size: 1.2rem;
    color: #fff;
    line-height: 1.2;
    text-align: center;

    @include mq-sp {
      margin-top: 30px;
      font-size: 0.8rem;
    }
  }
}
