/* ==========================================================
  heading-02
========================================================== */
.c-heading-02 {
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: normal;
  @include mq-sp {
    margin-bottom: 8px;
  }
}
