@charset 'UTF-8';

// 薬剤科ページ がん化学療法レジメン集
//

$regimen-list-border: 1px solid #ced4da;



.regimen-list-wrapper {
  width: 100%;
}



dl.regimen-list {
  border-bottom: $regimen-list-border;
  border-left: $regimen-list-border;
  border-right: $regimen-list-border;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  line-height: 1.4;
  dt {
    display: flex;
    flex: 0 0 30%;
    align-items: center;
    justify-content: center;
    &.dt-bg_1 { background: #D6F5C6; }
    &.dt-bg_2 { background: #FFE17F; }
    &.dt-bg_3 { background: #C3DAFF; }
    &.dt-bg_4 { background: #FFE17F; }
    &.dt-bg_5 { background: #FFFEA8; }
    &.dt-bg_6 { background: #FFD4D5; }
    &.dt-bg_7 { background: #D9D1F9; }
    &.dt-bg_8 { background: #D6F5C6; }
  }
  dd {
    border-left: $regimen-list-border;
    flex: 0 0 70%;
    div {
      padding: 1.2rem 3.6rem 1.2rem 1.2rem;
      & + div {
        border-top: $regimen-list-border;
      }
    }
    a {
      display: inline-block;
      position: relative;
      &:after {
        color: $lightBlue;
        content: '\f1c1';
        display: inline-block;
        font-family: $icon-font-family;
        font-size: 1.4em;
        font-weight: 400;
        margin-left: .4em;
        position: absolute;
        top: 50%;
        right: -2.4rem;
        transform: translateY(-50%);
      }
    }
  }
  &._header {
    border-top: $regimen-list-border;
    text-align: center;
    dt, dd {
      background: #ccedff;
      padding: .5em 0;
    }
  }

  @include mq-pc {
    dt {
      flex: 0 0 20%;
    }
    dd {
      flex: 0 0 80%;
    }
  }
}



// お問い合わせのテーブル内の受付時間
// @since 2020.04.22
.reception-time {
  li {
    display: flex;
    span {
      display: block;
      width: 50px;
      @include mq-pc{
        width: 80px;
      }
    }
  }
}

.tel-anchor {
  display: inline-block;
  position: relative;
  &:before {
    content: '\f2a0';
    display: inline-block;
    font-family: $icon-font-family;
    font-weight: 900;
    margin-right: .4em;
  }
}



// consultation department
// 外来担当医表リンクボタン minimum
.button-schedule-min {
  background: $base-color;
  border-radius: 3px;
  color: $white-color;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  font-weight: inherit;
  line-height: 1.42875 !important;
  margin-top: 1em;
  padding: 6px 10px;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: opacity $transition;
  vertical-align: middle;
  span {
    display: inline-block;
    padding-right: 20px;
    position: relative;
    &::after {
      @include icon-default;
      font-weight: multi-array( $icon-type, 'arrows', 'weight' );
      //margin-left: .6em;
      position: absolute;//added
      right: 0;//added
      top: 50%;//added
      transform: translateY(-50%);//added
    }
  }
}



// telephone-re-examination
// 電話再診療（オンライン診療）のお知らせ

// 電話番号
.tel-re-exam-number {
  font-size: 24px;
  margin-bottom: 12px;
  @include mq-pc {
    font-size: 32px;
  }
}

$approve-negative-gutter: 16px;

.approve-negative-wrapper {
  display: flex;
  margin: 32px ( - $approve-negative-gutter / 2 ) 0;
}

.approve-container, .negative-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  width: 50%;
  padding: 0 $approve-negative-gutter / 2;
}

// 可否のアイコン
.an-icon-header {
  margin-bottom: 16px;
  text-align: center;
  img {
    height: 36px;
    width: auto;
  }
  @include mq-pc {
    text-align: left;
    img {
      height: 52px;
    }
  }
}

// 矢印アイコン
.an-arrow-icon {
  color: #ceb0a3;
  font-size: 28px;
  padding: 12px 0;
  text-align: center;
  &.second {
    color: #e1c8b9;
  }
  @include mq-pc {
    font-size: 36px;
    padding: 20px 0;
    &.second {
      padding: 48px 0 20px;
    }
  }
}

$an-block-color: #abc493;
$an-block-second-color: #ceb0a3;
$an-block-third-color: #c49893;

.an-block {
  border: 3px solid $an-block-color;
  border-radius: 4px;
  height: 100%;
  &.second {
    border-color: $an-block-second-color;
  }
  &.third {
    border-color: $an-block-third-color;
  }
}

.an-block-header {
  background: $an-block-color;
  color: #fff;
  padding: 4px 12px;
  .second & {
    background: $an-block-second-color;
  }
  .third & {
    background: $an-block-third-color;
  }
}

.an-block-body {
  padding: 16px 12px;
  @include mq-pc {
    padding: 24px 16px;
  }
}

// 否の見出し
.an-h-negative {
  background: #e1c8b9;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 4px;
  @include mq-pc {
    margin-bottom: 20px;
    padding: 6px 16px;
  }
}



// 外来担当医表
.schedule_content {
  .doctor {
    span {
      display: block;
      font-size: 60%;
      line-height: 1;
    }
  }
  .comment, .comment_sub {
    font-size: 60%;
  }

  & + & {
    margin-top: 1em;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.schedule-button-wrapper {
  margin-bottom: 2em;
  text-align: left;
}

.schedule-heading-container {
  display: block;
  margin-bottom: 1em;
  text-align: right;
  @include mq-pc {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
  h2 {
    padding-bottom: 1em;
    text-align: left;
    @include mq-pc {
      padding-bottom: 0;
    }
  }
}

.schedule-heading-department {
  border-bottom: #EDDAD0 2px solid;
  display: block;
  margin-bottom: 1em;
  padding-bottom: .5em;
  text-align: right;
  @include mq-pc {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
  h3 {
    border-bottom: none;
    font-size: 2rem;
    margin: 0;
    padding-bottom: .5em;
    text-align: left;
    @include mq-pc {
      padding-bottom: 0;
    }
  }
}

// 「外来担当医表PDFダウンロード」ボタン
.schedule-pdf-download-button {
  background: $base-color;
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  padding: .5em 1em;
  i {
    margin-right: .5em;
  }
}



// 医師紹介 2020.06.30
.dr-wrap {
  display: block;
  font-size: 1.5em;
  //@include mq-pc {
  //  display: flex;
  //  align-items: flex-end;
  //}
}

.dr-position {
  color: $gray-600;
  font-size: .8em;
  margin-bottom: .5em;
  margin-right: 1em;
}

.dr-name {
  display: flex;
  align-items: flex-end;
  .dr-kana {
    display: block;
    font-size: .625em;
    margin-bottom: .2em;
  }
  a, .no-anchor {
    line-height: 1.2;
    text-decoration: none;
  }
}

.dr-sub {
  color: $gray-600;
  font-size: .6em;
}
/*
.dr-wrap{
      display: flex;
      align-items: flex-end;
      font-size: 1.5em;
      .dr-position{
        margin-right: 1em;
        color: $gray-600;
        font-size: .8em;
      }
      .dr-name{
        .dr-kana{
          display: block;
          font-size: 0.625em;
        }
      }
      @include mq-sp {
        font-size: 1.5em;
      }
    }
    */
