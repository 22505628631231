@charset 'UTF-8';

// variables
// ========== ========== ========== ========== ========== ==========
// map-zip( $keys, $values ) キーのリスト、値のリストの 2 つから新しい map を生成して返す
//
$button-size-padding-vertical:   map-zip( $font-size-list, $button-size-padding-vertical-array );
$button-size-padding-horizontal: map-zip( $font-size-list, $button-size-padding-horizontal-array );

// button
// ========== ========== ========== ========== ========== ==========

.button {
  background: multi-array( $button-color, 'base', 'bg' );
  border: $button-border solid transparent;
  border-radius: $button-border-radius;
  color: multi-array( $button-color, 'base', 'color' );
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: inherit;
  line-height: 1.42875 !important;
  text-align: center;
  -webkit-appearance: none;
  text-decoration: none;
  transition: background $transition, color $transition;
  vertical-align: middle;
  padding: calc( #{map-unit( $button-padding-vertical, sm )} - #{$button-border} ) calc( #{map-unit( $button-padding-horizontal, sm )} - #{$button-border} );
  @include mq-pc {
    padding: calc( #{map-unit( $button-padding-vertical, lg )} - #{$button-border} ) calc( #{map-unit( $button-padding-horizontal, lg )} - #{$button-border} );
  }
  // Hover
  &:hover {
    background: multi-array( $button-hover-color, 'base', 'bg' );
    color: multi-array( $button-hover-color, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button-border solid transparent;
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button-hover-color, #{$name}, 'bg' );
        color: multi-array( $button-hover-color, #{$name}, 'color' );
      }
    }
  }
  // 文字サイズによる padding-top & padding-bottom の上書き
  @each $list in $font-size-list {
    &.#{$list} {
      padding: calc( #{multi-array( $button-size-padding-vertical, #{$list}, 'sm' )} - #{$button-border} ) calc( #{multi-array( $button-size-padding-horizontal, #{$list}, 'sm' )} - #{$button-border} );
      @include mq-pc {
        padding: calc( #{multi-array( $button-size-padding-vertical, #{$list}, 'lg' )} - #{$button-border} ) calc( #{multi-array( $button-size-padding-horizontal, #{$list}, 'lg' )} - #{$button-border} );
      }
    }
  }
  &.schedule {
    padding: .5em 1em;
  }
} // .button


// Outline
// -------

.button.outline {
  background: multi-array( $button-outline-color, 'base', 'bg' );
  border: $button-border solid multi-array( $button-outline-color, 'base', 'border' );
  color: multi-array( $button-outline-color, 'base', 'color' );
  text-shadow: none;
  // Hover
  &:hover {
    background: multi-array( $button-outline-hover-color, 'base', 'bg' );
    color: multi-array( $button-outline-hover-color, 'base', 'color' );
    text-decoration: none;
  }
  // Color Pattern
  @each $name, $value in $button-outline-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $button-border solid map-get( $value, 'border' );
      color: map-get( $value, 'color' );
      &:hover {
        background: multi-array( $button-outline-hover-color, #{$name}, 'bg' );
        color: multi-array( $button-outline-hover-color, #{$name}, 'color' );
      }
    }
  }
}


// Expanded
// --------

.button.expanded {
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}



// button icon
// -----------

.button,
.button.outline {
  & span {
    display: inline-block;//added
    position: relative;//added
    padding-right: 2em;//added
    &::after {
      @include icon-default;
      font-weight: multi-array( $icon-type, 'arrows', 'weight' );
      //margin-left: .6em;
      position: absolute;//added
      right: 0;//added
      top: 50%;//added
      transform: translateY(-50%);//added
    }
    &.left {
      &::after {
        content: none;
      }
      &::before {
        @include icon-default;
        font-weight: multi-array( $icon-type, 'arrows', 'weight' );
        //margin-right: .6em;
        padding-left: 2em;//added
        padding-right: 0;//added
        position: absolute;//added
        left: 0;//added
        top: 50%;//added
        transform: translateY(-50%);//added
      }
    }
    // icon-type
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: map-get( $icon, 'code' ) !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: map-get( $icon, 'code' ) !important;
          }
        }
      }
    }
    // icon color
    @each $name, $color in $color-pattern {
      &.ic-#{$name} {
        &::after, &::before {
          color: $color;
        }
      }
    }
  } // span
}
