@import '../_variables';
@import '../_mixin';

/* ==========================================================
  Top Page
========================================================== */
/* .slider-banner
---------------------------------------------------------- */
.slider-banner-wrap {
  position: relative;
  @include mq-sp {
    padding: 0 15px;
  }

  .arrow {
    display: block;
    position: absolute;
    font-size: 2.8rem;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s;
    cursor: pointer;
    z-index: 4;
    @include mq-sp {
      font-size: 1.4rem;
    }

    &:hover {
      opacity: 0.7;
    }
  }
  .arrow-left {
    left: -25px;
    @include mq-sp {
      left: 0;
    }
  }
  .arrow-right {
    right: -25px;
    @include mq-sp {
      right: 0;
    }
  }
}

.slider-banner {
  overflow: hidden;

  .item{
    padding: 0 10px;
    @include mq-sp {
      padding: 0 8px;
    }
  }
  a {
    display: block;
    outline: none;
  }
  img {
    width: 100%;
  }
  .slick-list {
    margin: 0 -10px;
    overflow: hidden !important;
    @include mq-sp {
      margin: 0 -8px;
    }
  }
}

.slider-main {
  @include mq-pc {
    margin-top: 0;
  }
}



// top hero slider & canvas
//
.top-slider-wrapper {
  position: relative;
}

.canvas-wrapper {
  background: $base-color;
  opacity: .6;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.top-slider-copy {
  display: block;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate( -50%, -50% );
  width: 70%;
  @include mq-pc {
	  top: 60%;
    width: 36%;
  }
  z-index: 2;
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
}



.background-hexagon {
  background: transparent;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
