/* ==========================================================
  table
========================================================== */
.c-table-cmn {
  width: 100%;
  //table-layout: fixed;
  .col1 {
    width: 10.52%;
  }
  .col2 {
    width: 15.8%;
  }
  .col3 {
    width: 24.5%;
  }
  .col4 {
    width: 29.82%;
  }
  th {
    border: 1px solid #707070;
    padding: 7px 18px;
    background-color: #BFAA99;
    color: #fff;
    vertical-align: top;
    @include mq-sp {
      padding: 10px 18px;
    }
  }
  td {
    border: 1px solid #707070;
    padding: 18px 18px;
    vertical-align: top;
    @include mq-sp {
      padding: 20px 10px;
    }
  }
  .small {
    td {
      padding: 7px 18px;
      @include mq-sp {
        padding: 9px 18px;
      }
    }
  }
}

.c-wrap-table {
  @include mq-sp {
    overflow: scroll;
  }
  >.c-table-cmn {
    @include mq-sp {
      width: 730px;
    }
  }
}

.c-table-time {
  width: 100%;
  font-size: 1.2rem;
  .col1 {
    width: 16.2%;
  }
  .col2 {
    width: 49.52%;
  }
  .col3 {
    width: 34.28%;
  }
  .trow {
    display: flex;
  }
  .thead {
    margin-bottom: 15px;
    @include mq-sp {
      margin-bottom: 5px;
    }
    .col1 {
      padding-left: 5px;
      padding-right: 10px;
    }
    .col2 {
      padding-left: 5px;
      padding-right: 10px;
      @include mq-sp {
        padding-right: 5px;
      }
    }
    .col3 {
      padding-left: 10px;
      padding-right: 5px;
      @include mq-sp {
        padding-left: 5px;
      }
    }
    .c-tag-01 {
      font-size: 1.2rem;
      @include mq-sp {
        font-size: 1.4rem;
      }
    }
  }
  .tbody {
    //font-size: 2rem;
    letter-spacing: 0.2rem;
    @include mq-sp {
      font-size: 1.6rem;
      letter-spacing: 0;
    }
    .c-tag-01 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1.2rem;
      @include mq-sp {
        font-size: 1.4rem;
        padding: 2px;
      }
    }
    .col1 {
      padding-right: 10px;
      padding-bottom: 4px;
      padding-top: 6px;
      @include mq-sp {
        padding-right: 7px;
      }
    }
    .col2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: right;
      padding: 9px 30px 9px 10px;
      border-right: 1px solid #e5e1d8;
      border-bottom: 1px solid #e5e1d8;
      @include mq-sp {
        padding: 9px 20px 4px 10px;
      }
    }
    .col3 {
      display: flex;
      align-items: center;
      padding: 9px 10px 9px 35px;
      border-bottom: 1px solid #e5e1d8;
      @include mq-sp {
        padding: 9px 10px 4px 20px;
      }
    }
    .trow:last-child {
      .col2,
      .col3 {
        border-bottom: 0;
      }
    }
  }
}



// 外来担当医表
// @since 2020.05.11

$table_border_color: #707070;

// 外来担当医表
table.schedule-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: 960px;
  @include mq-pc {
    width: 100%;
  }

  th, td {
    border: 1px solid $table_border_color;
  }

  thead {
    th {
      background: #bfaa99;
      color: #fff;
      font-size: 60%;
      font-weight: 700;
      padding: .4em 0;
      text-align: center;
      &.time {
        width: 60px;
        @include mq-pc {
          width: 72px;
        }
      }
    }
  }

  td {
    padding: .5em;
    text-align: center;
    vertical-align: top;
    white-space: nowrap;
    min-width: 100px;
    &.time, &.num {
      width: 50px;
      min-width: auto;
    }
    &.time {
      background: #F3EBE7;
      padding: .5em 0;
      text-align: center;
    }
    &.num {
      line-height: 1.2;
      padding: .5em 0;
      text-align: center;
      vertical-align: middle;
      .room_num {
        font-size: .7em;
      }
    }
  }
}

// 受付時間
table.reception {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 2em;
  padding: 0;

  th, td {
    border: 1px solid $table_border_color;
  }

  th {
    background: #bfaa99;
    color: #fff;
    padding: .5em;
    text-align: center;
    width: 60px;
    @include mq-pc {
      width: 72px;
    }
  }

  td {
    padding: .5em;
  }

  td.time {
    background: #F3EBE7;
    text-align: center;
    width: 60px;
    @include mq-pc {
      width: 72px;
    }
  }

}
