/* ==========================================================
  header
========================================================== */
.header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-width: $minWidth;
  background: url("/images/common/bg_head_asanoha.png") lighten($beige, 4%) fixed;


  @include mq-sp {
    min-width: 0;
  }

  &-in {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: $contentWidth;
    height: $pc_header_height;
    margin: 0 auto;
/*    transition: height .6s;*/

    .home & {
      @include mq-pc {
        height: $pc_header_height_home;
      }
    }

    @include mq-sp {
      width: auto;
      height: 60px;
    }
  }

  &-logo {
    opacity: 1 !important;

    > img {
      display: block;
      width: 280px;
      height: auto;

      @include mq-sp {
        width: 214px;
        height: auto;
      }
    }
  }

  &-contact {
    display: block;
    color: $mainBlue;
    font-size: 2.2rem;
    line-height: 1.2;
    text-decoration: none;

    @include mq-sp {
      margin-right: 7px;
    }

    .txt-number {
      padding-left: 14px;

      @include mq-sp {
        display: none;
      }
    }

    .icon {
      @include mq-sp {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 38px;
        border-radius: 100%;
        background-color: $mainBlue;
        line-height: 1;
      }

      &::before {
        content: '\f87b';

        @include mq-sp {
          color: $white;
          font-size: 2rem;
          content: '\f879';
        }
      }
    }
  }

  &-search {
    font-size: 16px;
    @include mq-sp {
      display: none;
    }

    &-in {
      position: relative;
      display: flex;
      width: 262px;
      padding-right: 46px;
      background-color: $mainBlue;
    }

    .input-search {
      width: 100%;
      height: 40px;
      padding: 5px 10px;
      border: none;
      background: none;
      font-size: 1.6rem;
      color: $white;
    }

    .btn-search {
      position: absolute;
      top: 0;
      right: 0;
      width: 46px;
      height: 40px;
      color: $white;
      font-size: 2rem;
      text-align: center;
    }
  }

  &-btn-menu {
    position: relative;
    display: block;
    width: 26px;
    height: 20px;
    margin-left: 15px;

    @include mq-pc {
      display: none;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $mainBlue;
      transition: 0.3s ease-in-out;

      &:nth-child(2) {
        top: 9px;
        opacity: 0;
      }

      &:nth-child(3) {
        top: 9px;
      }

      &:nth-child(4) {
        top: 18px;
      }
    }

    &.is-open > span {
      &:nth-child(1) {
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        opacity: 1;
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        left: 50%;
        width: 0;
      }
    }
  }
}
