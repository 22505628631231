/* ==========================================================
  wrapper
========================================================== */
.wrapper {
  @include mq-pc {
    min-width: $minWidth;
  }
}
.contents {
  background: url("/images/common/bg_asanoha.png") $beige fixed;
  padding-top: $pc_header_height + $pc_nav-local_height;
  position: relative;

  .home & {
    padding-top: $pc_header_height_home;
    @include mq-sp {
      padding-top: 60px;
    }
  }

  @include mq-sp {
    padding-top: $sp_header_height + $sp_nav-local_height;
    padding-bottom: 50px;
  }
  .content-in {
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px 100px;
	  background: $beige;
	  position: relative;

	  .home &:before {
      content: '';
      background: rgba(255,255,255,0.5);
	  width: 100vw;
      margin-left: calc(-50vw + 50%);
      height: 160px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
		  
		  @include mq-sp {
			  background: none;
		  }
	  }

    @include mq-sp {
      padding: 0 15px 50px;
    }
  }
}
.sec-cmn {
  padding: 55px 0;
  @include mq-sp {
    padding: 40px 0;
  }
}
