/* ==========================================================
  tag
========================================================== */
/* c-tag-01
---------------------------------------------------------- */
.c-tag-01 {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  color: $white;
  text-align: center;
  background-color: $mainBlue;
  border-radius: 4px;
  padding: 2px 15px;
  @include mq-sp {
    font-size: 1.1rem;
    border-radius: 4px;
    padding: 2px 9px;
  }

  /* background color */
  &.orange {
    background-color: $lightOrange;
  }
  &.green {
    background-color: $lightGreen;
  }
  &.brown {
    color: $mainBlue;
    background-color: #f7f3e2;
  }

  /* size */
  &.md {
    padding-top: 4px;
    padding-bottom: 4px;
    @include mq-sp {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
  &.sm {
    font-size: 1.2rem;
    @include mq-sp {
      font-size: 8px;
    }
  }
  &.full-width {
    width: 100%;
  }
}
