@charset 'UTF-8';

// 健診・人間ドック予約フォーム
// @since 2020.06.09


.reservation-wrapper {
  overflow: hidden;
}

.reservation-container-wrapper {
  display: flex;
  flex-flow: column nowrap;
  transition: transform .5s;
}

.reservation-container {
  display: none;
  width: 100%;
  max-width: 100%;
  &.is_visible {
    display: block;
    animation: show .4s linear 0s;
  }
}

.form-container {
  background: #fff;
  border-radius: 8px;
  margin: 0 auto;
  padding: 2em;
  max-width: 330px;
  @include mq-pc {
    max-width: 812px;
  }
}

// すすむ & もどる ボタン
.reservation-button-wrapper {
  display: flex;
  align-items: center;
  margin: 2em -.5em 0;
  div {
    flex: 0 0 50%;
    width: 50%;
    max-width: 50%;
    padding: 0 .5em;
    &._left {
      text-align: left;
    }
    &._right {
      text-align: right;
    }
  }
  @include mq-sp {
    &.last_item {
      display: block;
      div {
        flex: none;
        width: 100%;
        max-width: none;
      }
      div + div {
        margin-top: 1em;
      }
    }
  }
}

.next-button,
.prev-button {
  background: $lightBlue;
  border: 4px solid transparent;
  border-radius: 8px;
  color: #fff;
  display: inline-block;
  font-weight: 700;
  padding: .5em .875em;
  text-align: center;
  span {
    display: inline-block;
    position: relative;
    &::after {
      color: inherit;
      content: '\f061';
      display: inline-block;
      font-family: $icon-font-family;
      font-weight: 900;
      margin-left: .5em;
      width: 1.28571429em;
      text-align: center;
    }
  }
  &.disabled {
    background: $gray-200;
    color: $gray-400;
    pointer-events: none;
  }
}
.prev-button {
  background: #fff;
  border: 4px solid $lightBlue;
  color: $lightBlue;
  span {
    &::before {
      color: inherit;
      content: '\f060';
      display: inline-block;
      font-family: $icon-font-family;
      font-weight: 900;
      margin-right: .5em;
      width: 1.28571429em;
      text-align: center;
    }
    &::after {
      content: none;
    }
  }
}

.sp-block-btn {
  @include mq-sp {
    width: 100%;
  }
}

.course-heading {
  font-weight: 700;
}

// 不可視
.invisible {
  display: none !important;
}


// FORM

label.res {
  color: $gray-600;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .5em;
}

label.rsv-label-radio {
  [type='radio'] {
    display: none;

    & + span {
      background: $beige;
      border: 3px solid $mainBrown2;
      border-radius: 8px;
      transition: background $transition;
      cursor: pointer;
      display: block;
      padding: 1em 1em 1em 2.5em;
      position: relative;
      &:before {
        color: inherit;
        content: '\f111';
        display: inline-block;
        font-family: $icon-font-family;
        font-weight: 400;
        margin-right: .5em;
        width: 1.28571429em;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
      }
    }

    &:checked + span {
      background: $lightBlue;
      border: 3px solid $mainBlue;
      color: #fff;
      &:before {
        content: none;
      }
      &:after {
        color: inherit;
        content: '\f058';
        display: inline-block;
        font-family: $icon-font-family;
        font-weight: 900;
        margin-right: .5em;
        width: 1.28571429em;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
      }
    }
  }
}


label.rsv-label-check {
  input {
    margin-right: .5em;
  }
  & + & {
    margin-top: .5em;
  }
}



// 入力確認画面
ul.confirm-list {
  > li {
    &:not(:last-child){
      margin-bottom: 2em;
    }
  }
}

.confirm-list-heading {
  color: $gray-600;
  font-size: 90%;
  margin-bottom: 1em;
  padding-left: 1em;
  position: relative;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
  }
  &:before {
    background: $mainBrown2;
    top: 0;
    width: 6px;
    height: 100%;
  }
  &:after {
    border-bottom: 1px solid $gray-200;
    bottom: -6px;
    width: 100%;
  }
}

.confirm-list-content {
  padding-left: 1em;
}



// オプションのアコーディオン
// variable
$accordion_open_class_name: '.active';


.accordion-wrapper {
  width: 100%;
}

.accordion {
  .accordion-heading {
    background: $beige;
    border-bottom: 1px solid darken( $beige, 5% );
    color: $chocoBrown;
    cursor: pointer;
    font-size: .875em;
    outline: none;
    margin: 0;
    padding: .875em 1em;
    position: relative;
    transition: background $transition;
    width: 100%;
    &:hover {
      background: darken( $beige, 5% );
    }
    // arrow
    &:after {
      border: 2px solid $chocoBrown;
      border-right: 0;
      border-top: 0;
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      top: 48%;
      right: 1em;
      transform: rotate(-45deg) translateY(-50%);
      width: 6px;
      height: 6px;
      @include mq-pc {
        width: 10px;
        height: 10px;
      }
    }
    &#{$accordion_open_class_name} {
      &:after {
        border: 2px solid $chocoBrown;
        border-left: 0;
        border-bottom: 0;
      }
    }
  }
  .accordion-contents {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition;
  }
  .accordion-contents-inner {
    background: $white-color;
    padding: 1em;
  }
}
