/* ==========================================================
  burron
========================================================== */
/* c-btn-pagetop
---------------------------------------------------------- */
.c-btn-pagetop {
  display: none;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 50%;
  bottom: 50px;
  margin-right: -600px;
  z-index: 99;
  @include mq-sp {
    width: 32px;
    height: 32px;
  }

  &.stop {
    top: -90px;
		right: 50%;
    margin-right: -600px;
		position: absolute;
  }

  @include mq-sp {
    right: 15px;
    margin-right: 0;

    &.stop {
      top: -90px;
      right: 15px;
      margin-right: 0;
      position: absolute;
      @include mq-sp {
        top: -50px;
      }
    }
  }

  .link {
    display: inline-flex;
    text-decoration: none;
  }

  .fas {
    font-size: 6rem;
    color: $mainBlue;
    @include mq-sp {
      font-size: 3.2rem;
    }
  }
}
