/* ==========================================================
  list
========================================================== */
/* c-list-flow-01
---------------------------------------------------------- */
.c-list-cmn-01 {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0 -20px;

  @include mq-sp {
	flex-wrap: wrap;
    margin: 0 -6px -20px;
    width: inherit;
  }

  li {
    position: relative;
    padding: 0;
    margin-bottom: 10px;
    width: 14.28%;
    @include mq-sp {
      margin-bottom: 15px;
      padding: 0 6px;
      width: 50%;
    }
    a {
      display: block;
      text-decoration: none;
    }
  }
}
.wrap-list-cmn {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: #F6F3E2;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  >.c-list-cmn-01 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
}
.is-open {
  .wrap-list-cmn {
    display: block;
    transition: all 0.3s ease;
    opacity: 1;
    @include mq-sp {
      display: none;
    }
  }
}

.c-list-cmn-arrow {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
  padding-left: 32px;
  @include mq-sp {
    padding-left: 20px;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    width: 33.33%;
    margin-bottom: 16px;
    &:nth-child(3n+1) {
      width: 34.2%;
      @include mq-sp {
        width: 100%;
      }
    }
    &:nth-child(3n+2) {
      width: 34.2%;
      @include mq-sp {
        width: 100%;
      }
    }
    &:nth-child(3n+3) {
      width: 30%;
      @include mq-sp {
        width: 100%;
      }
    }
    @include mq-sp {
      margin-bottom: 14px;
      width: 100%;
    }
    a {
      i {
        display: inline-block;
      }
      span {
        padding-left: 15px;
      }
    }
  }
}
.c-list-cmn-btn {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -20px;
  @include mq-sp {
    padding: 0 5px;
    margin-left: -5px;
    margin-right: -5px;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 15px;
    margin-bottom: 20px;
    @include mq-sp {
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
    }
  }
}

.c-list-cmn-note {
  li {
    margin-left: 10px;
    font-size: 1.4rem;
  }
}

/* c-list-news
---------------------------------------------------------- */
.c-list-news {
  border-radius: 12px;
  overflow: hidden;
  background-color: $white;

  .archives {
    padding: 12px 20px;
    text-align: right;
    a {
      background: $base-color;
      border-radius: 3px;
      color: $white-color;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-weight: inherit;
      line-height: 1.42875 !important;
      padding: 6px 10px;
      text-align: center;
      -webkit-appearance: none;
      text-decoration: none;
      transition: opacity $transition;
      vertical-align: middle;
      span {
        display: inline-block;
        padding-right: 8px;
      }
    }
  }

  .item {
    border-top: 1px dashed #f2f0eb;

    &:first-child {
      border-top: none;
      .c-card-news {
        padding-top: 32px;
        @include mq-sp {
          padding-top: 18px;
        }
      }
    }
    &:last-child {
      .c-card-news {
        padding-bottom: 32px;
        @include mq-sp {
          padding-bottom: 18px;
        }
      }
    }
  }
}

/* c-list-time
---------------------------------------------------------- */
.c-list-time {
  & * {
    font-size: 1.2rem;
  }
  .item {
    display: flex;
    align-items: center;
    margin-top: 7px;
    &:first-child {
      margin-top: 0;
    }
  }
  .tag {
    width: 114px;
    flex: 1 0 auto;
    font-size: 1.2rem;
    @include mq-sp {
      width: 70px;
      font-size: 1.2rem;
    }
  }
  .text {
    width: 100%;
    //font-size: 1.8rem;
    letter-spacing: 0.1rem;
    padding-left: 15px;
    @include mq-sp {
      font-size: 1.6rem;
      padding-left: 8px;
    }
  }
}
