/* ==========================================================
  breadcrumb
========================================================== */
.breadcrumb {
  max-width: 1240px;
  padding: 20px 20px 0;
  margin: 0 auto 0;
  background: $beige;
  @include mq-sp {
    padding: 20px 20px 0;
	margin: 0 auto;
  }
  a {
    text-decoration: none;
  }
  >ul {
    display: flex;
    flex-wrap: wrap;
    >li {
      position: relative;
      font-size: 1.1rem;
      padding-right: 20px;
      &:before {
        content: '>';
        position: absolute;
        right: 5px;
      }
      &:last-child {
        &:before {
          content: none;
        }
      }
    }
  }
}
