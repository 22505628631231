@charset 'UTF-8';



// 汎用 margin padding
// ------------------
$common-mp-lg-scale: map-get( $base-font-size, lg ) / map-get( $base-font-size, sm );

//

// margin & padding
// ----------------
@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      margin-top: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      margin-bottom: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      margin-right: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      margin-left: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      padding-top: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      padding-bottom: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      padding-right: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}rem !important;
    @include mq-pc {
      padding-left: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
}

.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

.anchor-adj{
  padding-top: 16px;
  margin-top: -16px;
  @include mq-pc {
    padding-top: 1em;
    margin-top: -1em;
  }
}

.no-decoration {
  text-decoration: none !important;
}

// Line height
// -----------
@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1} !important;
  }
}
