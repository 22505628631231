@charset 'UTF-8';

// icon
// ----
@mixin icon-default
{
  font-family: $icon-font-family;
  content: multi-array( $icon-type, 'arrows', 'code' );
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
}

.icon {
  span {
    &::after {
      @include icon-default;
      margin-left: .4em;
    }
    &.left {
      &::after { content: none; }
      &::before {
        @include icon-default;
        margin-right: .4em;
      }
    }
    @each $name, $icon in $icon-type {
      &.#{$name} {
        &::after {
          content: map-get( $icon, 'code' ) !important;
        }
        &.left  {
          &::after {
            content: none !important;
          }
          &::before {
            content: map-get( $icon, 'code' ) !important;
          }
        }
      }
    }
  }
  // color pattern
  @each $name, $color in $color-pattern {
    & span.ic-#{$name} {
      &::after, &::before {
        color: $color;
      }
    }
  }
}
