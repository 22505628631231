@import '_variables';

// Media Query
@mixin mq-pc {
  @media (min-width: #{$pcBreakpoint}) {
    @content;
  }
}

@mixin mq-sp {
  @media (max-width: #{$pcBreakpoint - 1px}) {
    @content;
  }
}

@mixin mq-custom-pc($maxWidth) {
  @media (min-width: #{$pcBreakpoint}) and (max-width: #{$maxWidth}) {
    @content;
  }
}

@mixin mq-custom-sp($maxWidth) {
  @media (max-width: #{$maxWidth}) {
    @content;
  }
}

// Hover
@mixin hoverOpacity($opacity: 0.7) {
  transition: opacity 0.3s;

  &:hover {
    opacity: $opacity;
    text-decoration: none;
  }
}

@mixin fontawesome() {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
