@charset 'UTF-8';

//
// potaslim
//

// ベースカラーパターン
$base-color:    $mainBlue !default;
$first-color:   $lightBlue !default;
$second-color:  $mainBrown1 !default;
$third-color:   $mainBrown2 !default;
$fourth-color:  $mainBrown3 !default;
$five-color:    $lightOrange !default;
$sixth-color:   $lightGreen !default;
$seven-color:   $chocoBrown !default;
// グレーパターン
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
// 汎用カラー
$white-color:  #fff    !default; // 白
$black-color:  #000    !default; // 黒
$red-color:    #b0120a !default; // 赤
$yellow-color: #fff000 !default; // 黄
$orange-color: #f18f2c !default; // 橙
$green-color:  #63a764 !default; // 緑
$blue-color:   #005fcc !default; // 青
$pink-color:   #d43776 !default; // ピンク
//
// 上記でカラーを追加した場合、下記の配列にも追加する（ '使用する名前': 変数;
//
$theme-colors: (
  'base':          $base-color,
  'first':         $first-color,
  'second':        $second-color,
  'third':         $third-color,
  'fourth':        $fourth-color,
  'five':          $five-color,
  'six':           $sixth-color,
  'seven':         $seven-color
) !default;
$gray-colors: (
  'gray-100':     $gray-100,
  'gray-200':     $gray-200,
  'gray-300':     $gray-300,
  'gray-400':     $gray-400,
  'gray-500':     $gray-500,
  'gray-600':     $gray-600,
  'gray-700':     $gray-700,
  'gray-800':     $gray-800,
  'gray-900':     $gray-900
) !default;

$common-colors: (
  'white':         $white-color,
  'black':         $black-color,
  'red':           $red-color,
  'yellow':        $yellow-color,
  'orange':        $orange-color,
  'green':         $green-color,
  'blue':          $blue-color,
  'pink':          $pink-color
) !default;
//
$basic-colors: map-merge( $gray-colors, $common-colors ) !default;
$color-pattern: map-merge( $theme-colors, $basic-colors ) !default;

// .section-inner-upper .section-inner .section-inner-lower 上下の余白（単位：em）
$section-gutter: (
  'sm': 1em,
  'lg': 1em
) !default;

// .element 上下の余白（単位：em）
$element-gutter: (
  'sm': 1em,
  'lg': 1em
) !default;

// body の文字サイズ（指定：px）
//
$base-font-size: (
  sm: 14,
  lg: 13
) !default;

// p タグ内の line-height
$paragraph-line-height: (
  sm: 1.8,
  md: 1.8,
  lg: 2
) !default;

// Common Font size
// ----------------
$font-size: (
  'exlarge' : 200%,
  'xxlarge' : 150%,
  'xlarge'  : 125%,
  'large'   : 112.5%,
  'medium'  : 87.5%,
  'small'   : 75%,
  'xsmall'  : 62.5%
  ) !default;

// grid 内のカラム数
$grid-columns: 12 !default;

// カラム同士の間隔（指定：px）
$grid-gutter: (
  sm: 16,
  lg: 24
) !default;

// form
$form-field-margin: (
  'sm': 1em,
  'lg': 1em
) !default;

// input grid gutter
$input_grid_gutter_array: (
  sm : 4px,
  md : 4px,
  lg : 4px
) !default;

// transition（タイミングを統一したい場合に使用
$transition: .2s ease-out;

@keyframes show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}


// button
// ========== ========== ========== ========== ========== ==========

// $font-size 配列から key を取り出してリスト化
//
$font-size-list: map-keys( $font-size );

// 各サイズの天地左右のパディング
//
// 天地
$button-size-padding-vertical-array: (
  ( sm: .4em,  lg: .4em ),  // 'exlarge'
  ( sm: .45em, lg: .45em ), // 'xxlarge'
  ( sm: .7em,  lg: .5em ), // 'xlarge'
  ( sm: .8em,  lg: .45em ),  // 'large'
  ( sm: .9em,  lg: .7em ),  // 'medium'
  ( sm: 1em,   lg: .6em ),   // 'small'
  ( sm: 1.2em, lg: .7em )    // 'xsmall'
);
// 左右
$button-size-padding-horizontal-array: (
  ( sm: .4em,  lg: .4em ),  // 'exlarge'
  ( sm: .45em, lg: .6em ), // 'xxlarge'
  ( sm: .8em,  lg: .8em ), // 'xlarge'
  ( sm: .8em,  lg: .8em ),  // 'large'
  ( sm: 1em,   lg: .8em ),  // 'medium'
  ( sm: 1em,   lg: 1em ),   // 'small'
  ( sm: 1.2em, lg: 1em )    // 'xsmall'
);

// ベースサイズの天地のパディング
$button-padding-vertical: (
  sm: .8em,
  lg: 1em
) !default;

// ベースサイズの左右のパディング
$button-padding-horizontal: (
  sm: 1em,
  lg: 1em
) !default;

$button-border-radius: 5px !default; // 角丸

$button-border: 3px !default;        // アウトライン時のボーダーの太さ

// button Color
// -----
// background-color, color
$button-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : $base-color ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff ),
  'seven'  : ( 'bg' : $seven-color,  'color' : #fff )
) !default;

$button-hover-color: (
  'base'   : ( 'bg' : darken($base-color, 10%),   'color' : #fff ),
  'first'  : ( 'bg' : darken($first-color, 10%),  'color' : #fff ),
  'second' : ( 'bg' : darken($second-color, 10%), 'color' : $base-color ),
  'third'  : ( 'bg' : darken($third-color, 10%),  'color' : #fff ),
  'fourth' : ( 'bg' : darken($fourth-color, 10%), 'color' : #fff ),
  'five'   : ( 'bg' : darken($five-color, 10%),   'color' : #fff ),
  'seven'  : ( 'bg' : darken($seven-color, 10%),  'color' : #fff ),
) !default;

// outline button color
// background-color, color, border-color
$button-outline-color: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color ),
  'seven'  : ( 'bg' : #fff, 'color' : $seven-color,  'border' : $seven-color ),
) !default;

$button-outline-hover-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff, 'border' : $base-color ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff, 'border' : $first-color ),
  'second' : ( 'bg' : $second-color, 'color' : #fff, 'border' : $second-color ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff, 'border' : $third-color ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff, 'border' : $fourth-color ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff, 'border' : $five-color ),
  'seven'  : ( 'bg' : $seven-color,  'color' : #fff, 'border' : $seven-color ),
) !default;



// label
// ========== ========== ========== ========== ========== ==========

// フォントサイズ
$label-font-size: .875em;

// ラベルの中 上下の padding
$label-padding-tb: (
  sm: .425em,
  lg: .425em
  ) !default;

// ラベルの中 左右の padding
$label-padding-lr: (
  sm: .6em,
  lg: .6em
  ) !default;

$label-border-radius: 0px !default; // 角丸
$label-border: 1px !default;        // アウトライン時のボーダーの太さ

// Color
// -----
$label-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff ),
  'pink'   : ( 'bg' : $pink-color,   'color' : #fff )
) !default;

// outline
$label-outline-color: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color ),
  'seven'  : ( 'bg' : #fff, 'color' : $seven-color,  'border' : $seven-color ),
) !default;



// table
// ========== ========== ========== ========== ========== ==========

// tableのボーダーの色
$table-border-color : $gray-600 !default;
// thead th のバックグラウンドカラー
$table-th-bg-color  : #BFAA99 !default;
// tbody th のバックグラウンドカラー
$table-tbody-th-bg-color  : $mainBrown1 !default;
// td のバックグラウンドカラー
$table-td-bg-color  : $white-color !default;



// Icon
// ========== ========== ========== ========== ========== ==========
$icon-font-family: 'Font Awesome 5 Pro';

// 種類を追加したい場合：'使用する名前': Fontawesomeのコード;
// Fontawesome のアイコンが Solid の場合 font-weight: 900; 、Regular の場合 font-weight: 400;
$icon-type: (
  'arrows':  ( 'code' : '\f138', 'weight' : 900 ),
  'pdf':     ( 'code' : '\f1c1', 'weight' : 900 ),
  'window':  ( 'code' : '\f2d2', 'weight' : 900 ),
  'tel':     ( 'code' : '\f098', 'weight' : 900 ),
  'mail':    ( 'code' : '\f0e0', 'weight' : 900 ),
  'fax':     ( 'code' : '\f1ac', 'weight' : 900 ),
  'privacy': ( 'code' : '\f13e', 'weight' : 900 ),
  'link':    ( 'code' : '\f0c1', 'weight' : 900 )
) !default;



// Block
// -----

// block の border の太さ
$block-border: 4px;

// block の border-radius
$block-border-radius: 8px;

// block の box-shadow
$block-box-shadow: 0 2px 4px 0 rgba( 0,0,0, .12 );

// color
// -----
// background-color, color
$block-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff ),
  'seven'  : ( 'bg' : $seven-color,  'color' : #fff ),
) !default;
