/* ==========================================================
  card
========================================================== */
/* c-card-01
---------------------------------------------------------- */
.c-card-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: $white;
  height: 160px;
  transition: background-color 0.3s, color 0.3s;

	  &:before {
	  content: '';
	  position: absolute;
	  left: 100%;
	  top: 30px;/*線の上下位置*/
	  display: inline-block;
	  width: 1px;/*線の長さ*/
	  height: 100px;/*線の太さ*/
	  -webkit-transform: translateX(-50%);
	  transform: translateX(-50%);/*位置調整*/
	  background-color: $gray-300;/*線の色*/
	  border-radius: 1px;/*線の丸み*/
		}

  @include mq-sp {
    height: 167px;
	border-radius: 12px;

	  &:before {
		width: 0;
		height: 0;
	  }
  }

  &.white {
    background-color:$white;
  }

  .card-in {
    text-align: center;
    @include mq-sp {
      margin-top: 8px;
    }
    .icon {
      display: inline-block;
      margin-bottom: 1rem;
      font-size: 3rem;
      color: #877166;
      @include mq-sp {
        //margin-bottom: 20px;
        //font-size: 5rem;
      }
    }
    .text-01 {
      width: 100%;
      font-size: 1rem;
      color: $seven-color;
      margin-bottom: 1.4em;
      @include mq-sp {
        font-size: 1rem;
      }
    }
    .text-02 {
      font-size: 1.4rem;
      @include mq-sp {
        font-size: 1.6rem;
      }
    }
  }
  &.small {
    @include mq-sp {
      height: 110px;
    }
    .card-in {
      .icon {
        @include mq-sp {
          font-size: 3rem;
        }
      }
    }
  }
}

/*TOP コンテンツリンク 外来のご案内 左側の線*/
.c-list-cmn-01 li:first-child .c-card-01:after {
		content: '';
	  position: absolute;
	  left: 0;
	  top: 30px;/*線の上下位置*/
	  display: inline-block;
	  width: 1px;/*線の長さ*/
	  height: 100px;/*線の太さ*/
	  -webkit-transform: translateX(-50%);
	  transform: translateX(-50%);/*位置調整*/
	  background-color: $gray-300;/*線の色*/
	  border-radius: 1px;/*線の丸み*/

	@include mq-sp {
		width: 0;
		height: 0;
		}
	}

a {
  &:hover {
    .c-card-01 {
      background-color: #2C598C;
      .card-in {
        .icon,
        .text-01,
        .text-02 {
          color: #fff;
        }
      }
    }
  }
}

/* c-card-02
---------------------------------------------------------- */
.c-card-02 {
  background-color: #E2DCC0;
  border-radius: 12px;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  transition: background-color 0.3s, color 0.3s;
  @include mq-sp {
    justify-content: center;
    height: 100px;
  }
  .icon {
    font-size: 5rem;
    color: #877166;
    margin-left: 29px;
    @include mq-sp {
      font-size: 4rem;
      margin-left: 0;
    }
  }
  .card-in {
    text-align: left;
    margin-left: 30px;
    @include mq-sp {
      text-align: center;
      margin-left: 0;
      width: 100%;
    }
    .text-01 {
      width: 100%;
      font-size: 1.2rem;
      color: #877166;
      @include mq-sp {
        font-size: 1rem;
      }
    }
    .text-02 {
      font-size: 2.2rem;
      @include mq-sp {
        font-size: 1.2rem;
      }
    }
  }
}
.wrap-card-link {
  &:hover {
    opacity: 1;
    .c-card-02 {
      background-color: #2C598C;
      .icon {
        color: #fff;
      }
      .card-in {
        .text-01 {
          color: #fff;
        }
        .text-02 {
          color: #fff;
        }
      }
    }
  }
}

.card-cmn-content {
  display: flex;
  width: 100%;
  justify-content: start;
  @include mq-sp {
    display: block;
  }
  .title {
    width: 42%;
    @include mq-sp {
      width: 100%;
    }
    .ttl {
      margin: 11px 0 0 19px;
      font-size: 2.4rem;
      font-weight: normal;
      @include mq-sp {
        margin: 0;
        margin-bottom: 10px;
        font-size: 2.8rem;
      }
      .text-color-01 {
        color: #8C563A;
        font-size: 2rem;
        margin-right: 10px;
      }
    }
    .dr-wrap{
      display: flex;
      align-items: flex-end;
      font-size: 1.5em;
      .dr-position{
        margin-right: 1em;
        color: $gray-600;
        font-size: .8em;
      }
      .dr-name{
        .dr-kana{
          display: block;
          font-size: 0.625em;
        }
      }
      @include mq-sp {
        font-size: 1.5em;
      }
    }
  }
  .detail {
    width: 58%;
    font-size: 1.3rem;
    overflow: hidden;
    margin-top: 1.5em;
    line-height: 1.4;
    @include mq-sp {
      width: 100%;
      margin-top: 2em;
    }
    dt {
      width: 180px;
      float: left;
      clear: left;
      font-weight: normal;
      margin-top: 0;
      @include mq-sp {
        width: 100%;
        float: none;
        font-size: 1.4rem;
      }
    }
    dd {
      margin-bottom: 30px;
      float: left;
      width: calc(100% - 180px);
      @include mq-sp {
        padding-left: 20px;
        float: none;
        font-size: 1.4rem;
        width: 100%;
      }
    }
  }
}

/* c-card-news
---------------------------------------------------------- */
.c-card-news {
  position: relative;
  display: block ;
  padding: 19px 45px 19px 40px;
  text-decoration: none;
  @include mq-sp {
    padding: 10px 40px 9px 25px;
  }

  &:before {
    @include fontawesome();
    content: '\f054';
    font-size: 1.9rem;
    color: $mainBlue;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    @include mq-sp {
      font-size: 1.1rem;
      right: 17px;
    }
  }

  &.unlink {
    pointer-events: none;
    &:before {
      content: none;
    }
  }

  .box-date {
    display: flex;
    align-items: center;
  }
  .date {
    color: $mainBlue;
  }
  .title {
    margin-top: 7px;
    letter-spacing: -0.05rem;
    @include mq-sp {
      margin-top: 0;
      letter-spacing: 0;
    }

    i {
      font-size: 2.4rem;
      margin-left: 8px;
      @include mq-sp {
        font-size: 1.4rem;
      }
    }
    .fa-file-pdf {
      color: #C62B2B;
      font-weight: normal;
    }
  }
  * + .c-tag-01 {
    margin-left: 12px;
    @include mq-sp {
      margin-left: 5px;
    }
  }
}
