/* ==========================================================
  nav-local
========================================================== */
.nav-local {
  background-color: $mainBlue;
  position: absolute;
  top: $pc_header_height;
  left: 0;
  width: 100%;
  z-index: 3;
  @include mq-sp {
    top: $sp_header_height;
  }

  &-in {
    margin: 0 auto;
    max-width: 1240px;
    padding: 0 20px;
    @include mq-sp {
      padding: 0 15px;
    }
  }

  &-list {
    display: flex;
    position: relative;

  }

  &-list-item {
    width: 33.33%;

    &.is-current {
      background-color: #f7f3e2;
      opacity: 1;
      visibility: visible;
      .text {
        color: $baseColor;
        &:before {
          color: $mainBlue;
        }
      }
    }
    @include mq-pc {
      /*border-left: 2px solid #8c9ba5;*/
      &:hover {
        background-color: darken($beige, 5%);
        opacity: 1;

        .text {
          color: $baseColor;
          &:before {
            color: $mainBlue;
          }
        }
        .wrap-list-cmn {
          display: block;
          opacity: 1;
          visibility: visible;
        }
      }
      /*&:last-child() {
        border-right: 2px solid #8c9ba5;
      }*/
    }
  }

  &-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: 14px 10px 10px;
    transition: background-color 0.3s, color 0.3s;
    transform: translateZ(0);
    @include mq-sp {
      padding: 13px 9px 10px;
    }

    .text {
      position: relative;
      font-size: 1.6rem;
      font-weight: 400;
      text-align: left;
      color: $white;
      line-height: 1.1;
      padding-left: 33px;
      @include mq-sp {
        width: 100%;
        font-size: 1rem;
        padding-left: 18px;
      }

      &:before {
        content: '\f138';
        position: absolute;
        top: 3px;
        left: 0;
        font-size: 1.8rem;
        font-weight: 900;
        color: $white;
        @include mq-sp {
          top: 1px;
          font-size: 1rem;
        }
      }
    }

    .en {
      font-size: 1rem;
      @include mq-sp {
        font-size: 0.7rem;
      }
    }
  }
}
