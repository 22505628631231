@charset 'UTF-8';

h3 {
	font-size: 1.4em;
	border-bottom: $fourth-color 2px solid;
	color: darken( $third-color, 40% );
	padding-bottom: 0.2em;
	margin-bottom:1em;
}

h4 {
	font-size: 1.2em;
	background: darken( $third-color, 5% );
	color: $white-color;
	padding: 6px 15px;
	margin-bottom:0.6em;
	border-radius: 5px;
	-webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

/* 電話番号リンク消す_PC*/
a[href^="tel:"] {
	@media (min-width: 813px){
      pointer-events: none;
    }
}

/*マウスオーバーで画像拡大_院長画像_使いませんでした*/
.img_wrap{
  overflow: hidden;
  position: relative;
  width: 100%;
	
	img {
  	-webkit-transition: -webkit-transform .3s linear;
    transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear, -webkit-transform .3s linear;
	}
	
	&:hover img {
  	-webkit-transform: scale(1.1);
    transform: scale(1.1); 
	transition-duration: 0.3s;
	}
}

/*テーブル*/
.table-fixed {
	table-layout: fixed;
}

.c-table-cmn tr td:first-child {
	white-space: nowrap;
}

/*外来担当医表一覧 テーブル直後のh3の上指定*/
.c-wrap-table + h3 {
	margin-top: 2em;
}

/*ヘッダーお問い合わせのテキスト追加_PCのみ*/
.head_toiawase_txt {
	display: none;
}

.home .head_toiawase_txt {
	display: none;
	@media (min-width: 813px){
		display: block;
    font-size: 1.8rem;
		//position: absolute;
		//top:24px;
		//right: 0;
		color: $white-color;
		opacity: 0.8;
    text-align: right;
    }
}

/*トップメン画像に重ねて臨時アナウンス表示*/
.top_extra_info {
	z-index: 1;
	position: absolute;
	left: 10%;
	bottom: 10px;
	background: none;
	width: 80%;
	text-align: center;
	a {
		display: block;
		padding: 10px 20px;
		border: 2px solid $white-color !important;
		background: darken($five-color, 10%) !important;
	}
	@media (min-width: 813px){
		left:25%;
		bottom: 40px;
		right: 40px;
		width: 50%;
		a {
		padding: 20px;
		}
    }
}
