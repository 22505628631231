// Breakpoint
$pcBreakpoint: 813px;

// Content Width
$minWidth: 1260px;  // plus margin left and right
$contentWidth: 1200px;

// Fonts
//$fontBase: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$fontBase: 'YakuHanJPs', -apple-system  BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, arial, sans-serif;

// Color
$baseColor  : #555;
$mainBlue   : #214268;
$lightBlue  : #2a5382;
$mainBrown1 : #F3EBE7;
$mainBrown2 : #CEB0A3;
$mainBrown3 : #EDDAD0;
$beige      : #f6f3e2;
$white      : #fff;
$black      : #000;
$lightOrange: #FF9D7B;
$lightGreen : #B0D186;
$chocoBrown : #877166;
$bgbeige    : #faf8ee;


// add 2020.01.24
// header の高さ
$pc_header_height: 90px;
$pc_header_height_home: 90px;

$sp_header_height: 60px;

// navi の高さ
$pc_nav-local_height: 71px;
$sp_nav-local_height: 47px;


// potaslim の変数を読み込む
@import 'potaslim/variables';
