@import '_variables';
@import '_mixin';

/* ==========================================================
  fonts
========================================================== */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

/* ==========================================================
  html5 elements
========================================================== */
article, aside, figure, figcaption, footer, header, nav, section, main {
  display: block;
}

/* ==========================================================
  reseting
========================================================== */
html {
  height: 100%;
  @include mq-sp {
    font-size: 62.5%;
  }
  @include mq-pc {
    font-size: 78.125%;
  }
  &.is-fixed {
    position: fixed;
    left: 0;
    width: 100%;
  }
}

body {
  color: $baseColor;
  font-family: $fontBase;
  //font-size: 1.6rem;
  font-size: #{(map-get( $base-font-size , lg ) / 10)}rem;
  line-height: 1.5;
  text-size-adjust: 100%;

  @include mq-sp {
    //font-size: 1.2rem;
    font-size: #{(map-get( $base-font-size , sm ) / 10)}rem;
  }
}

input:not([type='checkbox']):not([type='radio']), textarea, select, button {
  font-family: $fontBase;
  outline: none;
  appearance: none;
}

body, div, pre, p, blockquote, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, form, fieldset, th, td, button, figure, figcaption {
  margin: 0;
  padding: 0;
}

input, textarea {
  margin: 0;
  font-size: 100%;
}

textarea {
  //resize: none;
  resize: vertical;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

button {
  border: none;
  overflow: visible;
  background: none;
  vertical-align: top;
  font-size: 100%;
  color: inherit;
  cursor: pointer;
  appearance: none;
}

//label {
//  cursor: pointer;
//}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}

fieldset {
  border: 0;
}

//img {
//  border: 0;
//  height: auto;
//  vertical-align: top;
//}

// 2020.1.20
img {
  border-style: none;
  height: auto;
  max-width: 100%;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

address, caption, code, em, th {
  font-style: normal;
  font-weight: 500;
}

ol, ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6, strong, em {
  font-size: 100%;
  font-weight: 700;
}

sup, sub {
  font-size: 63%;
}

sup {
  vertical-align: top;
}

sub {
  vertical-align: baseline;
}

* {
  box-sizing: border-box;
}

/* ==========================================================
  base Link
========================================================== */
a, .is-opacity {
  color: $baseColor;
  text-decoration: underline;

  @include mq-pc {
    @include hoverOpacity;
  }
}
