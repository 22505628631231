/* ==========================================================
  link
========================================================== */
/* c-link-cmn
---------------------------------------------------------- */
.c-link-cmn {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #2C598C;
  color: #fff;
  border-radius: 12px;
  height: 100px;
  padding: 0 20px;
  font-size: 1em;
  text-decoration: none;
  @include mq-sp {
    padding: 0 15px;
    height: 80px;
    border-radius: 12px;
  }
  i {
    font-size: 3.6rem;
    @include mq-sp {
      font-size: 4rem;
    }
  }
  span {
    padding-left: 30px;
    @include mq-sp {
      padding-left: 20px;
    }
  }

  &.center {
    justify-content: center;
    i {
      padding-right: 20px;
      @include mq-sp {}
    }
    span {
      display: flex;
      align-items: center;
      padding-left: 0;
      //font-size: 1.8rem;
      @include mq-sp {
        padding-left: 0;
      }
    }
  }
}
