/* ==========================================================
  utilities & helper
========================================================== */
/* display
---------------------------------------------------------- */
.d-none        { display: none !important; }
.d-inline       { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block        { display: block !important; }
.d-flex         { display: flex !important; }
.d-table        { display: table !important; }
.d-table-cell   { display: table-cell !important; }

@include mq-pc {
  .d-pc-none         { display: none !important; }
  .d-pc-inline       { display: inline !important; }
  .d-pc-inline-block { display: inline-block !important; }
  .d-pc-block        { display: block !important; }
  .d-pc-flex         { display: flex !important; }
  .d-pc-table        { display: table !important; }
  .d-pc-table-cell   { display: table-cell !important; }
}

@include mq-sp {
  .d-sp-inline       { display: inline !important; }
  .d-sp-inline-block { display: inline-block !important; }
  .d-sp-block        { display: block !important; }
  .d-sp-flex         { display: flex !important; }
  .d-sp-table        { display: table !important; }
  .d-sp-table-cell   { display: table-cell !important; }
}


@include mq-pc {
  .only-sp { display: none !important; }
}

@include mq-sp {
  .only-pc { display: none !important; }
}

/* font style
---------------------------------------------------------- */
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}

/* text align
---------------------------------------------------------- */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

/* spacing
---------------------------------------------------------- */
$spaceValue: (0, 5, 10, 15, 20, 25, 30, 35, 40, 50, 55, 60);
$sides: (top, bottom, left, right);

@each $space in $spaceValue {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@include mq-pc {
  @each $space in $spaceValue {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-pc-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}-pc-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}

@include mq-sp {
  @each $space in $spaceValue {
    @each $side in $sides {
      .m#{str-slice($side, 0, 1)}-sp-#{$space} {
        margin-#{$side}: #{$space}px !important;
      }

      .p#{str-slice($side, 0, 1)}-sp-#{$space} {
        padding-#{$side}: #{$space}px !important;
      }
    }
  }
}
