/* ==========================================================
  card
========================================================== */
/* c-box-cmn
---------------------------------------------------------- */
.c-box-cmn {
  //padding: 20px 30px 30px;
  padding: 2em;
  background-color: #fff;
  border-radius: 12px;
  @include mq-sp {
    //padding: 10px 15px;
  }
}
