@charset 'UTF-8';

// mixin
// -----

// grid
//
@mixin make-grid( $width )
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}

// icon
//
@mixin icon-default
{
  font-family: $icon-font-family;
  content: multi-array( $icon-type, 'arrows', 'code' );
  color: inherit;
  display: inline-block;
  width: 1.28571429em;
  text-align: center;
}
